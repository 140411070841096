import * as ActionTypes from "./actiontypes"
export const LOAD_EDGEPCS = ( distributionId) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS,
        payload: {distributionId}
    }
}
export const LOAD_TANDEMS = (distributionId) =>{
    return {
        type: ActionTypes.LOAD_TANDEMS,
        payload: {distributionId}
    }
}
export const LOAD_EDGE_SERIALS = (deviceType) => {
    return {
    type: ActionTypes.LOAD_EDGE_SERIALS,
    payload: {deviceType:deviceType}
    }
}
export const LOAD_EDGE_SERIALS_WITH_SUCCESS = (edgeSerials) => {
    return {
    type: ActionTypes.LOAD_EDGE_SERIALS_WITH_SUCCESS,
    payload: {edgeSerials}
    }
}
export const LOAD_EDGE_SERIALS_WITH_ERROR = (error) => {
    return {
    type: ActionTypes.LOAD_EDGE_SERIALS_WITH_ERROR,
    payload: {error}
    }
}

export const LOAD_TANDEMS_WITH_SUCCESS = (tandems) =>{
    return {
        type: ActionTypes.LOAD_TANDEMS_WITH_SUCCESS,
        payload: {tandems}
    }
}
export const LOAD_TANDEMS_WITH_ERROR = (error) =>{
    return {
        type: ActionTypes.LOAD_TANDEMS_WITH_ERROR,
        payload: {error}
    }
}
export const LOAD_EDGEPCS_WITH_SUCCESS = ( edgePCs) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_WITH_SUCCESS,
        payload: {edgePCs}
    }
}
export const LOAD_EDGEPCS_WITH_ERROR = ( error) => {
    return {
        type: ActionTypes.LOAD_EDGEPCS_WITH_ERROR,
        payload: {error}
    }
}
export const LOAD_CUSTOMERS_WITH_DC = () => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_WITH_DC,
        payload: {}
    }
}
export const LOAD_CUSTOMERS_WITH_DC_SUCCESS = (customers) => {
    return {
        type: ActionTypes.LOAD_CUSTOMERS_WITH_DC_SUCCESS,
        payload: {customers:customers}
    }
}

export const ADD_EDGE_PC = (pcInfo) => {
    return {
        type: ActionTypes.ADD_EDGE_PC,
        payload: {edgePC:pcInfo}
    }
}
export const ADD_EDGE_PC_WITH_SUCCESS = (pcInfo) => {
    return {
        type: ActionTypes.ADD_EDGE_PC_WITH_SUCCESS,
        payload: {edgePC:pcInfo}
    }
}
export const ADD_EDGE_PC_WITH_ERROR = (errorString) => {
    return {
        type: ActionTypes.ADD_EDGE_PC_WITH_ERROR,
        payload: {error:errorString}
    }
}
export const EDIT_EDGE_PC = (pcInfo,edgePCId) => {
    return {
        type: ActionTypes.EDIT_EDGE_PC,
        payload: {edgePC:pcInfo,edgePCId:edgePCId}
    }
}
export const EDIT_EDGE_PC_WITH_SUCCESS = (pcInfo) => {
    return {
        type: ActionTypes.EDIT_EDGE_PC_WITH_SUCCESS,
        payload: {edgePC:pcInfo}
    }
}
export const EDIT_EDGE_PC_WITH_ERROR = (errorString) => {
    return {
        type: ActionTypes.EDIT_EDGE_PC_WITH_ERROR,
        payload: {error:errorString}
    }
}
export const SELECT_EDGEPC = (edgePC) => {
    return {
        type: ActionTypes.SELECT_EDGEPC,
        payload: {edgePC:edgePC}
    }
}
export const SELECT_CUSTOMER = (customerId) => {
    return {
        type: ActionTypes.SELECT_CUSTOMER,
        payload: {customer:customerId}
    }
}
export const SELECT_DISTRIBUTION_CENTER = (distributionCenterId) => {
    return {
        type: ActionTypes.SELECT_DISTRIBUTION_CENTER,
        payload: {distributionCenter:distributionCenterId}
    }
}
export const ADD_TANDEM = (tandemInfo) => {
    return {
        type: ActionTypes.ADD_TANDEM,
        payload: {tandem:tandemInfo}
    }
}
export const ADD_TANDEM_WITH_SUCCESS = (tandemInfo) => {
    return {
        type: ActionTypes.ADD_TANDEM_WITH_SUCCESS,
        payload: {tandem:tandemInfo}
    }
}
export const ADD_TANDEM_WITH_ERROR = (errorString) => {
    return {
        type: ActionTypes.ADD_TANDEM_WITH_ERROR,
        payload: {error:errorString}
    }
}
export const EDIT_TANDEM = (tandemInfo,tandemId) => {
    return {
        type: ActionTypes.EDIT_TANDEM,
        payload: {tandem:tandemInfo,tandemId:tandemId}
    }
}
export const DELETE_TANDEM = (tandemId) => {
    return {
        type: ActionTypes.DELETE_TANDEM,
        payload: {tandemId:tandemId}
    }
}

export const EDIT_TANDEM_WITH_SUCCESS = (tandemInfo) => {
    return {
        type: ActionTypes.EDIT_TANDEM_WITH_SUCCESS,
        payload: {tandem:tandemInfo}
    }
}
export const EDIT_TANDEM_WITH_ERROR = (errorString) => {
    return {
        type: ActionTypes.EDIT_TANDEM_WITH_ERROR,
        payload: {error:errorString}
    }
}
export const SELECT_TANDEM = (tandem) => {
    return {
        type: ActionTypes.SELECT_TANDEM,
        payload: {tandem:tandem}
    }
}