import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from 'aws-amplify';
import DashBoardGridPanel from './DashBoardGridPanel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const DashBoard = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [locationsList, setLocationsList] = useState();
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [halfPallets, setHalfPallets] = useState(false);
    const [fullPallets, setFullPallets] = useState(false);
    const [transactionDate, setTransactionDate] = useState(dayjs());
    const [dailyAnalysisData, setDailyAnalysisData] = useState([]);
    const [dataError, setDataError] = useState(null)
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [healthSiteURL, setSiteHealthURL] = useState(null)

    let selectedTranDate = transactionDate ? transactionDate.format("YYYY-MM-DD") : '';

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff";
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchEmbeddedURL();
            setIndicatorOn(true);
        })
    }, []);

    // useEffect(() => {
    //     if (!props.customers || !customersList) {
    //         setCustomersList()
    //         setIndicatorOn(true);
    //     } else {
    //         setCustomersList(props.customers);
    //         setIndicatorOn(false);
    //     }
    // }, [props.customers, customersList]);

    // useEffect(() => {
    //     if (props.siteHealthData) {
    //         setDailyAnalysisData(props.siteHealthData);
    //         setIndicatorOn(false);
    //     } else {
    //         setDailyAnalysisData([]);
    //         setIndicatorOn(false);
    //     }
    // }, [props.siteHealthData])

    useEffect(() => {
        if (props.healthMetricsURL) {
            setSiteHealthURL(props.healthMetricsURL);
            setIndicatorOn(false);
        } else {
            setSiteHealthURL(null);
            setIndicatorOn(false);
        }
    }, [props.healthMetricsURL])

    // useEffect(() => {
    //     if (!selectedCustomer || !selectedCustomerId) {
    //         setDailyAnalysisData([]);
    //         setIndicatorOn(false);
    //     }
    // }, [selectedCustomer, selectedCustomerId])

    useEffect(() => {
        if (props.siteHealthError === 'Resource not found.' || props.siteHealthError !== null) {
            setDataError(props.siteHealthError);
            setIndicatorOn(false);
        } else {
            setDataError(null)
        }
    }, [props.siteHealthError, indicatorOn])

    // useEffect(() => {
    //     setCustomersList(props.customers);
    //     if (selectedCustomer === 'All') {
    //         setSelectedCustomerId('All');
    //         setLocationsList([]);
    //         setSelectedLocationId('All');
    //         setSelectedLocation('All');
    //     }
    //     if (selectedLocation === 'All') {
    //         setSelectedLocationId('All');
    //     }
    // }, [props.customers, selectedCustomer, selectedLocation, selectedCustomerId]);

    // const handleCustomerChange = (event) => {
    //     setSelectedCustomer(event);
    //     setSelectedCustomerId(event.customer_id);
    //     if (event.dcs && event.dcs[0].dc_id) {
    //         setLocationsList(event.dcs);
    //         setSelectedLocationId(event.dcs.dc_id);
    //         setSelectedLocation('All');
    //     } else {
    //         setLocationsList();
    //         setSelectedLocationId();
    //     }
    // }
    // const handleLocationChange = (event) => {
    //     setSelectedLocation(event);
    //     setSelectedLocationId(event.dc_id);
    // }

    // useEffect(() => {
    //     console.log(props);
    //     if (props.siteHealthData !== null) {
    //         if (selectedTranDate && !selectedCustomer) {
    //             setSelectedCustomer('All');
    //             setSelectedCustomerId('All');
    //         }
    //     }

    // }, [selectedTranDate, selectedCustomer, props.siteHealthData]);

    // const FetchDatails = () => {
    //     if (selectedTranDate !== null && selectedCustomerId !== null && selectedLocationId !== null && halfPallets == true || fullPallets == true) {
    //         setIndicatorOn(true);
    //         props.fetchSiteHealthDetails(selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets);
    //     } else {
    //         setDailyAnalysisData([]);
    //     }
    // }

    // const setValueHalfPallets = (event) => {
    //     if (event.target.checked) {
    //       setHalfPallets(true);
    //     } else {
    //       setHalfPallets(false);
    //     }
    //   }
    //   const setValueFullPallets = (event) => {
    //     if (event.target.checked) {
    //         setFullPallets(true);
    //     } else {
    //         setFullPallets(false);
    //     }
    //   }

    return (
        <>
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                    <div>
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={indicatorOn}
                        >
                            <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> : ''
                }
            {healthSiteURL ?
                <div style={{ width: '100%', height: '100vh' }}>
                <iframe
                width="100%"
                height="100%"
                src={healthSiteURL}
                ></iframe>
             </div> : <p>No data available</p>}
        </div>
            {/* <div style={{ width: '100%' }}>
                {indicatorOn ?
                    <div>
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={indicatorOn}
                        >
                            <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> : ''
                }
                <h2>Site Health Metrics</h2>
                {/* {edgedeployData ? <Alert severity="success" sx={{ width: '50%', marginTop: '20px' }}>Success</Alert> : ''} */}
                {/* <div style={{ width: '100%', display: 'flex' }}>
                    {customersList ?
                        <FormControl sx={{ float: 'left', width: '270px', marginRight: '10px' }}>
                            <InputLabel id="customer-label">Customer</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={selectedCustomer || ''}
                                onChange={(event) => handleCustomerChange(event.target.value)}
                                label="Customer"
                                sx={{ textAlign: 'left' }}>
                                <MenuItem key='Select All' value='All'>
                                    Select All
                                </MenuItem>
                                {customersList.map((item) => (
                                    <MenuItem key={item.customer_id} value={item}>
                                        {item.customer_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> : ""
                    }
                    {locationsList ? <FormControl sx={{ float: 'left', width: '270px', marginRight: '10px' }}>
                        <InputLabel id="dc-label">DC</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectedLocation || ''}
                            onChange={(event) => handleLocationChange(event.target.value)}
                            label="DC"
                            sx={{ textAlign: 'left' }}>
                            <MenuItem key='Select All' value='All'>
                                Select All
                            </MenuItem>
                            {locationsList.map((item) => (
                                <MenuItem key={item.dc_id} value={item}>
                                    {item.dc_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> : ''}

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']} sx={{ marginTop: '-8px' }}>
                            <DatePicker label="Transaction Date"
                                value={transactionDate}
                                onChange={(newValue) => setTransactionDate(newValue)}
                                format="MM-DD-YYYY"
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                    {locationsList ? <FormControlLabel
                        value="HalfPallets"
                        control={<Checkbox style={{ marginLeft: "4px" }} />}
                        label="Half pallets"
                        labelPlacement="start"
                        checked={halfPallets}
                        onChange={setValueHalfPallets}
                    /> : ''}
                    {locationsList ? <FormControlLabel
                        value="FullPallets"
                        control={<Checkbox style={{ marginLeft: "4px" }} />}
                        label="Full pallets"
                        labelPlacement="start"
                        checked={fullPallets}
                        onChange={setValueFullPallets}
                    /> : ''}
                    <Button variant="contained"
                        onClick={FetchDatails}
                        disable={indicatorOn ? true : false}
                        sx={{ width: '120px', marginLeft: '10px', height: '54px', marginTop: '0px' }}>SEARCH</Button>
                </div>
            </div>
            <div>
                {
                    (selectedCustomer && dailyAnalysisData) ? <DashBoardGridPanel dailyAnalysisData={dailyAnalysisData} /> : ''
                }
            </div> */} 


        </>
    )
}

const mapStateToProps = (state) => {
    return {
        siteHealthData: state.siteHelathMetrics.siteHealthData ? state.siteHelathMetrics.siteHealthData : null,
        customers: state.siteHelathMetrics.customers,
        siteHealthError: state.siteHelathMetrics.siteHealthError ? state.siteHelathMetrics.siteHealthError : null,
        user: state.application.user,
        healthMetricsURL: state.siteHelathMetrics.healthMetricsURL ? state.siteHelathMetrics.healthMetricsURL : null,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomersWithDC: () => dispatch(Actions.LOAD_CUSTOMERS_WITH_DC()),
        fetchSiteHealthDetails: (selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets) => dispatch(Actions.LOAD_SITE_HEALTH_METRICS(selectedCustomerId, selectedLocationId, selectedTranDate, halfPallets, fullPallets)),
        fetchEmbeddedURL: () =>  dispatch(Actions.LOAD_EMBEDDED_URL()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);