import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;
let devbaseURL = `${baseURL}/Central`;

//let devbaseURL = "https://vordevapi.rehrig.com/Central";
export const getCustomers = () => {
    return axios.get(`${devbaseURL}/customer?limit=1000&offset=0`,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const getRoles = () => {
    return axios.get(`${devbaseURL}/role?limit=1000&offset=0`,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const getApplications = () => {
    return axios.get(`${devbaseURL}/application?limit=1000&offset=0`,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const getLocations = (customerId) => {
    let url ="location?limit=1000&offset=0";
    if(customerId) url+=`${url}&central_customer_id=${customerId}`

    return axios.get(`${devbaseURL}/${url}`,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const getUsers = (customerId,locationId) => {
    let url ="user?limit=1000&offset=0";
    if(customerId && customerId!=-1) url+=`${url}&central_customer_id=${customerId}`
    if(customerId==-1) url+=`${url}&is_rehrig_user=1`
    if(locationId) url+=`${url}&central_location_id=${locationId}`
    return axios.get(`${devbaseURL}/${url}`,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const addUser = (user) => {
    const params = JSON.stringify(user);
    //if(user.central_customer_id==-1) 
    return axios.post(`${devbaseURL}/user`,params,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}
export const editUser = (user,userId) => {
    const params = JSON.stringify(user);
    //if(user.central_customer_id==-1) 
    return axios.put(`${devbaseURL}/user/${userId}`,params,
    {
        headers: {
            "Authorization": localStorage.getItem("authToken"),
        },
    }) 
}