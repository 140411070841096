import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    FormControl,
    Select,
    Checkbox,
    InputLabel,
    MenuItem
} from '@mui/material'
import * as Actions from '../../../actions/actions';
import * as EdgePCActions from "../../../actions/edgepcs"


const AddEditTandemModal = (props) => {
    const [numberOfWrapper, setNumberOfWrappers] = useState(2);
    const [wrapers, setWrappers] = useState([]);
    const [wrapper1, setWrapper1] = useState(null);
    const [wrapper2, setWrapper2] = useState(null);
    const [wrapper3, setWrapper3] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleClose = () => {
        props.handleClose();
    }

    const handleNumberOfWrapperChange = (event) => {
        setNumberOfWrappers(event.target.value)
    }


    const handleSubmit = () => {
        setIsSubmitted(true);
        if (numberOfWrapper < 2 || !wrapper1 || !wrapper2) return;
        let tandemInfo = {
            "dc_id": props.selectedDC.dc_id,
            "number_of_wrappers": numberOfWrapper,
            "wrapper_1": wrapper1 ? wrapper1.edge_pc_id : null,
        }
        if (numberOfWrapper > 1 && wrapper2)
            tandemInfo["wrapper_2"] = wrapper2.edge_pc_id;
        else
            return;
        if (numberOfWrapper > 2 && wrapper3)
            tandemInfo["wrapper_3"] = wrapper3.edge_pc_id;
        else if(numberOfWrapper > 2 && !wrapper3)
            return;
        if (props.selectedTandem && props.selectedTandem.tandem_details_id) {
            props.editTandem(tandemInfo, props.selectedTandem.tandem_details_id);
        } else {
            props.addTandem(tandemInfo);
        }
        props.handleClose(true);

    }
    const handleDelete = () => {
        props.deleteTandem(props.selectedTandem.tandem_details_id);
        props.handleClose(true);

    }
    useEffect(() => {
        if (props.selectedTandem && props.selectedTandem.tandem_details_id) { //Edit PC Edge Scenario
            setNumberOfWrappers(props.selectedTandem.number_of_wrappers);
            setWrapper1(props.edgePCs.find((option) => option.edge_pc_id === props.selectedTandem.wrapper_1));
            setWrapper2(props.edgePCs.find((option) => option.edge_pc_id === props.selectedTandem.wrapper_2));
            setWrapper3(props.edgePCs.find((option) => option.edge_pc_id === props.selectedTandem.wrapper_3));
        } else {
            setNumberOfWrappers(2);
            setWrapper1(null);
            setWrapper2(null);
            setWrapper3(null);
        }
        setIsSubmitted(false);
    }, [props.selectedTandem, props.edgePCs]);

    const getWrappersWithout = (withoutWrappers) => {
        let wrappers =  props.edgePCs.filter((edgePC) => (edgePC.application_type_id == 1 && !edgePC.is_tandem));
        wrappers = wrappers.filter(ar => !withoutWrappers.find(rm => (rm && rm.edge_pc_id === ar.edge_pc_id) ))
        return wrappers;
    }

    return (
        <>
            <Dialog open={true} onClose={handleClose}
                PaperProps={{ sx: { width: '700px', minHeight: "500px" } }}>
                <DialogTitle>{"Configure Tandem"}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} style={{ marginBottom: '32px' }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="number-of-wrappers-label">Number of wrappers</InputLabel>
                                <Select
                                    labelId="number-of-wrappers-label"
                                    id="number-of-wrappers"
                                    value={numberOfWrapper}
                                    label="Number of wrappers"
                                    onChange={handleNumberOfWrapperChange}
                                >
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                </Select>
                            </FormControl>

                        </Grid>
                        {numberOfWrapper > 0 && <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="wrapper1"
                                options={getWrappersWithout([wrapper2,wrapper3])}
                                value={wrapper1}
                                getOptionLabel={(option) => option.pc_name}
                                renderInput={(params) => <TextField error={isSubmitted && !wrapper1} {...params} label="Wrapper 1" />}
                                onChange={(event, option) => {
                                    setWrapper1(option);
                                }}
                            />
                        </Grid>
                        }
                        {numberOfWrapper > 1 && <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="wrapper2"
                                options={getWrappersWithout([wrapper1,wrapper3])}
                                value={wrapper2}
                                getOptionLabel={(option) => option.pc_name}
                                renderInput={(params) => <TextField error={isSubmitted && !wrapper2} {...params} label="Wrapper 2" />}
                                onChange={(event, option) => {
                                    setWrapper2(option);
                                }}
                            />
                        </Grid>
                        }
                        {numberOfWrapper > 2 && <Grid item xs={6}>
                            <Autocomplete
                                disablePortal
                                id="wrapper3"
                                options={getWrappersWithout([wrapper1,wrapper2])}
                                value={wrapper3}
                                getOptionLabel={(option) => option.pc_name}
                                renderInput={(params) => <TextField error={isSubmitted && !wrapper3} {...params} label="Wrapper 3" />}
                                onChange={(event, option) => {
                                    setWrapper3(option);
                                }}
                            />
                        </Grid>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    {props.selectedTandem && <Button autoFocus onClick={handleDelete} variant="outlined" color="error">
                        Delete
                    </Button>}
                    <Button autoFocus onClick={handleSubmit} variant="contained" disableElevatio>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        edgePCs: state.edgePCs.edgePCs,
        selectedTandem: state.edgePCs.selectedTandem,
        selectedDC: state.edgePCs.selectedDistributionCenter,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addTandem: (tandemInfo) => dispatch(EdgePCActions.ADD_TANDEM(tandemInfo)),
        editTandem: (tandemInfo, tandemId) => dispatch(EdgePCActions.EDIT_TANDEM(tandemInfo, tandemId)),
        deleteTandem: (tandemId) => dispatch(EdgePCActions.DELETE_TANDEM(tandemId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditTandemModal);