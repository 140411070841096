import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import VORConfigurationLVDoorAddEditModal from '../VORConfigurationLVDoorAddEditModal/VORConfigurationLVDoorAddEditModal';

const VORConfigurationLVDoorGridPanel = (props) => {
    const [dockDoorList, setDockDoorList] = useState();
    const [dockDoorId, setDockDoorId] = useState();
    const [dockDoorName, setDockDoorName] = useState();
    const [slsPortalIp, setSlsPortalIp] = useState();
    const [topic, setTopic] = useState();
    const [rpDeviceIp, setRpDeviceIp] = useState();
    const [selectedEdgeSerials, setSelectedEdgeSerials] = useState();
    const [dockDoorLoadError, setDockDoorLoadError] = useState(props.dockDoorLoadError);
    const [showModal, setShowModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDockDoorList(props.dockDoorList);
    }, [props.dockDoorList]);
    
    useEffect(() => {
        props.fetchDockDoorList(props.dcId);
    }, [props.dcId]);

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add Dock Door Configuration');

        } else {
            setModalTitle('Edit Dock Door Configuration');
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType]);

    const callUpdatedDockDoorList = () => {
        props.fetchDockDoorList(props.dcId);
        setLoading(false);
    }
    const handleAddConfigurationTapped = () => {
        props.showAddEditConfigurationModal("Add", true);
        setShowModal(true);
    }
    const handleEditConfigurationTapped = (params) =>{
        props.showAddEditConfigurationModal('Edit', true, params);
    }

    const editConfigurationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={()=>handleEditConfigurationTapped(params.row)}>Edit Configuration</Button>
        } else {
            return null
        }
    }
    const getStatus = (params) => {
        if (params.row.active) {
            return "Active"
        } else {
            return "Inactive"
        }
    }

    const columns = [
        { field: 'dock_door_id', headerName: 'DOCK DOOR ID', width: 150 },
        { field: 'edge_dock_door_name', headerName: 'DOCK DOOR NAME', width: 150 },
        { field: 'dock_door_sls_ip_address', headerName: 'SLS IP ADDRESS', width: 150},
        { field: 'topic', headerName: 'TOPIC', width: 100},
        { field: 'active', headerName: 'STATUS', width: 100, renderCell: getStatus},
        { field: 'edge_device_generated_serial_number', headerName: 'RASPBERRY PI DEVICE SERIAL NUMBER', width: 250 },
        { field: 'raspberry_pi_device_ip_address', headerName: 'RASPBERRY PI DEVICE IP ADDRESS', width: 250 },
        { field: 'editConfiguration', headerName: 'EDIT CONFIGURATION', width: 250, renderCell: editConfigurationButton }

    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{paddingTop:'10px'}}>LV Dock Door Configuration</h3>
                    </div>
                    <Toolbar sx={{ justifyContent: "flex-end" }}>
                            <Button variant="outlined" onClick={handleAddConfigurationTapped} >Add Configuration</Button>
                        </Toolbar>

                    {dockDoorList ? <div className="duolift-device-detail-card-body">
                        <div style={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={dockDoorList}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.dock_door_id}
                            />
                        </div>
                    </div> :   <Alert severity="error" sx={{ width: '50%' }}>{props.dockDoorLoadError || ''}</Alert>}

                    <VORConfigurationLVDoorAddEditModal showModal={showModal} modalTitle={modalTitle} 
                    editModal={handleEditConfigurationTapped} customerId={props.customerId} 
                    dcId={props.dcId} dockDoorId={dockDoorId} dockDoorName={dockDoorName} loading={loading} setLoading={setLoading}
                    slsPortalIp={slsPortalIp} topic={topic} rpDeviceIp={rpDeviceIp} selectedEdgeSerials={selectedEdgeSerials} callUpdatedDockDoorList={callUpdatedDockDoorList}></VORConfigurationLVDoorAddEditModal>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showCustomerModal,
        showModalType : state.vorConfiguration.showModalType,
        dockDoorList: state.vorConfiguration.dockDoorList,
        dockDoorLoadError: state.vorConfiguration.dockDoorLoadError
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditConfigurationModal: (type, show, selectedData) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show, selectedData)),
        fetchDockDoorList: (dc_id) => dispatch(Actions.LOAD_DOCK_DOOR(dc_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationLVDoorGridPanel);