export class Constants {
    static selectedLog = "HEURISTIC"
    static  HEURISTICS = "HEURISTIC"
    static  STITCHING_VIEWER = "STITCHING_VIEW"
    static  VOR_LOG = "VOR_LOG"
    static  ALL_ERRORS = "ALL_ERRORS"
    static  CORRECTED_ERRORS = "CORRECTED_ERR"
    static  RESPONSE_JSON = "RESPONSE"
    static  STITCHING_PACKAGE = "ST_PACKAGE"
    static  STITCHING_BRAND = "ST_BRAND"
    static  STITCHING_CORRECTIONS = "ST_CORRECTIONS"
    static  DOWNLOAD_PICKLIST = "Download Picklist"
    static  FINAL_ERRORS = "FINAL_ERRORS"
    static  DOWNLOAD_CMD = "DOWNLOAD_CMD"
    static  CREATE_ISSUE = "CREATE_ISSUE"
    static CREATE = "create";
    static UPDATE = "update"

    static PICKLIST = "";
    static DC_ID = ""
    static CUST_ID = ""
    static INF_ID = ""
    static SELECT_ISSUE = ""

    static SIDE_MENU_OPEN = false


}
