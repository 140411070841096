import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux'
import EdgePCList from './edgepcs/List'
import TandemList from './Tandem/List'
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import * as EdgePCActions from '../../actions/edgepcs';
import { Auth } from 'aws-amplify';

import { Tabs, Tab, Box, Autocomplete, TextField, Grid, Paper, Button, Stack, Toolbar, Chip } from '@mui/material'

import { select } from 'redux-saga/effects';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`edgepcs-tabpanel-${index}`}
      aria-labelledby={`edgepcs-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const EdgePC = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [indicatorOn, setIndicatorOn] = useState(false);
  const [customers, setCustomers] = useState([])
  const [tendemError, setTendemError] = useState(null);
  const [edgepcError, setEdgepcError] = useState(null);
  const [edgePCS, setEdgePCS] = useState(null);
  const [tendems, setTendems] = useState(null);

  useEffect(() => {
    Auth.currentSession().then(res => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      localStorage.setItem('authToken', jwt);
      props.fetchCustomersWithDC();
    })
  }, []);


  useEffect(() => {
    if (props.selectedDC && props.selectedDC.dc_id) {
      props.fetchEdgePC(props.selectedDC.dc_id);
      props.fetchTandems(props.selectedDC.dc_id);
    }
  }, [props.selectedDC]);

  useEffect(() => {
    if (props.edgePCs) {
      setEdgePCS(props.edgePCS);
      setIndicatorOn(false);
      setEdgepcError(null);
    }
    if (props.tandems) {
      setTendems(props.tandems);
      setIndicatorOn(false);
      setTendemError(null);

    }
  }, [props.edgePCs, props.tandems, edgePCS, tendems, edgepcError, tendemError])

  useEffect(() => {
    if(props.tendemError){
      setTendems(null);
      setTendemError(props.tendemError)
    }
    if(props.errorResponse){
       setEdgepcError(props.errorResponse)
       setEdgePCS(null);
    }
  }, [props.tendemError, props.errorResponse])

  useEffect(() => {
    if (props.customers) {
      setCustomers(props.customers)
    }
    if (!customers) {
      setIndicatorOn(true);
    }
  }, [props.customers, customers])

  useLayoutEffect(() => {
    document.body.style.backgroundColor = "#fff"
  });

  const handleCustomerChange = (_, option) => {
    props.selectCustomer(option);
    props.selectDistributionCenter(null);
  }

  const handleDCChange = (_, option) => {
    setIndicatorOn(true);
    props.selectDistributionCenter(option);
  }

  return (
    <div style={{ width: '100%' }}>
      <div>
        {edgepcError !== null ? <Alert severity="error" sx={{ width: '50%' }}>Erro in edgePC:{props.tendemError}</Alert> : ''}
        {tendemError !== null ? <Alert severity="error" sx={{ width: '50%' }}>Erro in Tendom: {props.errorResponse}</Alert> : ''}
        <div>
          <h3 style={{ float: 'left' }}>Edge PC Details Management</h3>
        </div>
        <div>
          {indicatorOn ?
            <div>
              <Backdrop
                sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={indicatorOn}
              >
                <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
              </Backdrop>
            </div> : ''
          }
          <Grid container spacing={2} style={{ marginBottom: '32px' }}>
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                id="customer"
                options={props.customers}
                getOptionLabel={(option) => option.customer_name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Customer" />}
                onChange={handleCustomerChange}
                value={props.selectedCustomer}
              />
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                disablePortal
                id="distributionCenter"
                options={props.selectedCustomer ? props.selectedCustomer.dcs : []}
                getOptionLabel={(option) => option.dc_name}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="Distribution Center" />}
                onChange={handleDCChange}
                value={props.selectedDC}
              />
            </Grid>

          </Grid>
          {props.selectedDC && props.selectedDC.dc_id &&
            <Box>
              <Box container="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectedTab} onChange={(event, value) => setSelectedTab(value)} aria-label="EdgePC Tabs">
                  <Tab label="Edge PC" />
                  <Tab label="Configure Tandem" />
                </Tabs>
              </Box>
              <TabPanel value={selectedTab} index={0}>
                <EdgePCList setIndicatorOn={setIndicatorOn} />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <TandemList />
              </TabPanel>
            </Box>
          }
        </div>

      </div>

    </div>)
}
const mapStateToProps = (state) => {
  return {
    customers: state.edgePCs.customers ? state.edgePCs.customers : [],
    selectedDC: state.edgePCs.selectedDistributionCenter,
    selectedCustomer: state.edgePCs.selectedCustomer,
    errorResponse: state.edgePCs.error ? state.edgePCs.error : null,
    tendemError: state.edgePCs.tendemError ? state.edgePCs.tendemError : null,
    edgePCs: state.edgePCs.edgePCs,
    tandems: state.edgePCs.tandems,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomersWithDC: () => dispatch(EdgePCActions.LOAD_CUSTOMERS_WITH_DC()),
    fetchEdgePC: (distributionId) => dispatch(EdgePCActions.LOAD_EDGEPCS(distributionId)),
    fetchTandems: (distributionId) => dispatch(EdgePCActions.LOAD_TANDEMS(distributionId)),
    selectCustomer: (customer) => dispatch(EdgePCActions.SELECT_CUSTOMER(customer)),
    selectDistributionCenter: (distributionCenter) => dispatch(EdgePCActions.SELECT_DISTRIBUTION_CENTER(distributionCenter))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EdgePC);
