import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import VORConfigurationPalletAddEditModal from '../VORConfigurationPalletAddEditModal/VORConfigurationPalletAddEditModal';

const VORConfigurationPalletGridPanel = (props) => {
    const [palletConfiguration, setPalletConfiguration] = useState();
    const [dcId, setDcId] = useState(props.dcId);
    const [customerId, setCustomerId] = useState(props.customerId)
    const [palletType, setPalletType] = useState();
    const [palletTypeId, setPalletTypeId] = useState(props.pallet_type_id);
    const [palletSchema, setPalletSchema] = useState();
    const [palletWeight, setPalletWeight] = useState();
    const [isfullPallet, setIsfullPallet] = useState(props.is_full_pallet);
    const [showModal, setShowModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setPalletConfiguration(props.palletConfiguration);
    }, [props.palletConfiguration]);

    useEffect(() => {
        props.fetchpalletConfiguration(props.customerId, props.dcId);
    }, [props.customerId, props.dcId]);

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add Pallet Configuration');

        } else {
            setModalTitle('Edit Pallet Configuration');
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType]);

    const callUpdatedPalletConfig = () => {
        props.fetchpalletConfiguration(props.customerId, props.dcId);
        setLoading(false);
    }
    const handleAddConfigurationTapped = () => {
        props.showAddEditConfigurationModal("Add", true);
        setShowModal(true);
    }
    const handleEditConfigurationTapped = (params) => {
        props.showAddEditConfigurationModal('Edit', true, params);
    }

    const editConfigurationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={() => handleEditConfigurationTapped(params.row)}>Edit Configuration</Button>
        } else {
            return null
        }
    }


    const columns = [
        { field: 'pallet_schema_id', headerName: 'PALLET SCHEMA ID', width: 180 },
        { field: 'pallet_type', headerName: 'PALLET TYPE', width: 180 },
        { field: 'pallet_schema', headerName: 'PALLET SCHEMA', width: 180 },
        { field: 'pallet_weight_in_lbs', headerName: 'PALLET WEIGHT', width: 170 },
        { field: 'is_full_pallet', headerName: 'IS FULL PALLET', width: 170 },
        { field: 'editConfiguration', headerName: 'EDIT CONFIGURATION', width: 250, renderCell: editConfigurationButton }

    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{ paddingTop: '10px' }}>Pallet Configuration</h3>
                    </div>
                    <Toolbar sx={{ justifyContent: "flex-end" }}>
                        <Button variant="outlined" onClick={handleAddConfigurationTapped} >Add Configuration</Button>
                    </Toolbar>

                    {dcId ? <div className="duolift-device-detail-card-body">
                        <div style={{ height: '650px', width: '100%' }}>
                            <DataGrid
                                rows={palletConfiguration ? palletConfiguration : ''}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.pallet_schema_id}
                            />
                        </div>
                    </div> : <Alert severity="error" sx={{ width: '50%' }}>{props.palletConfigurationLoadError || ''}</Alert>}

                    <VORConfigurationPalletAddEditModal showModal={showModal} modalTitle={modalTitle} palletTypeId={palletTypeId}
                        editModal={handleEditConfigurationTapped} customerId={customerId}
                        dcId={dcId} palletType={palletType} palletSchema={palletSchema} palletWeight={palletWeight} isfullPallet={isfullPallet}
                        callUpdatedPalletConfig={callUpdatedPalletConfig} setLoading={setLoading} loading={loading}></VORConfigurationPalletAddEditModal>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showCustomerModal,
        showModalType: state.vorConfiguration.showModalType,
        palletConfiguration: state.vorConfiguration.palletConfiguration,
        palletConfigurationLoadError: state.vorConfiguration.palletConfigurationLoadError
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditConfigurationModal: (type, show, selectedData) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show, selectedData)),
        fetchpalletConfiguration: (customer_id, dc_id) => dispatch(Actions.LOAD_PALLET_CONFIGURATION(customer_id, dc_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationPalletGridPanel);