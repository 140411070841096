import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import '../VORConfiguration/style.css';
import Alert from '@mui/material/Alert';


const VORConfigurationLVDoorAddEditModal = (props) => {
    const [dockDoorId, setDockDoorId] = useState(props.dockDoorId);
    const [dockDoorName, setDockDoorName] = useState(props.dockDoorName);
    const [slsPortalIp, setSlsPortalIp] = useState(props.slsPortalIp);
    const [topic, setTopic] = useState(props.topic);
    const [dcId, setDcId] = useState(props.dcId);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [edgePcId, setEdgePcId] = useState(props.edgePcId);
    const [rpDeviceIp, setRpDeviceIp] = useState(props.rpDeviceIp);
    const [edgeSerials, setEdgeSerials] = useState(props.edgeSerials);
    const [selectedEdgeSerials, setSelectedEdgeSerials] = useState();
    const [currentEdgeSerial, setCurrentEdgeSerial] = useState();
    const [dispalyERR, setDispalyERR] = useState(props.displayErr);
    const [getErr, setGetErr] = useState(props.errorResponse);
    const [touched, setTouched] = useState(false);
    const deviceType = "RASPBERRY_PI_DEVICE";
    const [status, setStatus] = useState(true);


    useEffect(() => {
        if (!edgeSerials) {
            props.fetchEdgeSerials(deviceType);
        }
    }, [edgeSerials]);

    useEffect(() => {
        if (!dockDoorName || !topic) {
            setDispalyERR(true);
        }
        else {
            setDispalyERR(false);
        }
    }, [props, dockDoorName, topic]);

    useEffect(() => {
        if (props.selectedData) {
            setDockDoorName(props.selectedData.edge_dock_door_name);
            setSlsPortalIp(props.selectedData.dock_door_sls_ip_address);
            setTopic(props.selectedData.topic);
            setRpDeviceIp(props.selectedData.raspberry_pi_device_ip_address);
            setDockDoorId(props.selectedData.dock_door_id);
            setCurrentEdgeSerial(props.selectedData.edge_device_generated_serial_number);
            setSelectedEdgeSerials(props.selectedData.edge_device_generated_serial_number);
            setGetErr(props.selectedData.errorResponse);
            setDispalyERR(props.selectedData.displayErr);
            if (props.selectedData.active) {
                setStatus(true);
            }
            else {
                setStatus(false);
            }
            if (props.selectedData.edge_dock_door_name || props.selectedData.topic) {
                setGetErr(null);
            }
        }
    }, [props.selectedData])


    useEffect(() => {
        if (props.errorResponse) {
            props.setLoading(false);
        }
    }, [props.errorResponse]);

    const handleClose = () => {
        props.hideConfigurationModal(props.showModalType, false);
    }
    const handleSlsPortalIpChange = (event) => {
        setSlsPortalIp(event.target.value.replace(/[^0-9\.\,]/g, ''))
    }
    const handleEdgeSerialsChange = (event) => {
        setSelectedEdgeSerials(event);
    }
    const handleTopicChange = (event) => {
        setTopic(event.target.value);
        setGetErr(null);
        setDispalyERR(false);
    }
    const handleDockDoorNameChange = (event) => {
        setDockDoorName(event.target.value);
        setGetErr(null);
        setDispalyERR(false);
    }
    const handleRpDeviceIpChange = (event) => {
        setRpDeviceIp(event.target.value.replace(/[^0-9\.\,]/g, ''));
    }
    const handleCheckBoxChange = (event) => {
        setStatus(event.target.checked);
    }
    const callUpdatedDockDoorList = () => {
        handleClose();
        props.callUpdatedDockDoorList();
    }
    const submitDetails = () => {
        if (props.showModalType === 'Add' && dockDoorName === "" && topic === "") {
            setDispalyERR(true);
        }
        if (props.showModalType === 'Add' && dockDoorName !== "" && topic !== "") {
            setDispalyERR(false); props.setLoading(true);
            props.addConfiguration(dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList);
        }
        if (props.showModalType === 'Edit') {
            props.setLoading(true);
            props.editConfiguration(dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList);
        }
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {/* {props.errorResponse && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert>} */}
                    <div style={{ float: 'right', marginBottom: '20px' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={status} onChange={handleCheckBoxChange} />} label="Active Status" />
                        </FormGroup>
                    </div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '40ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <TextField
                            id="outlined-name"
                            label="Dock Door Name*"
                            value={dockDoorName || ''}
                            className={dispalyERR ? 'showERR' : ""}
                            onChange={handleDockDoorNameChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="SLS Portal IP Address"
                            value={slsPortalIp || ''}
                            onChange={handleSlsPortalIpChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Topic name*"
                            value={topic || ''}
                            className={dispalyERR ? 'showERR' : ""}
                            onChange={handleTopicChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Raspberry Pi Device IP Address"
                            value={rpDeviceIp || ''}
                            onChange={handleRpDeviceIpChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <FormControl sx={{ width: '40ch', marginTop: '20px' }}>
                            <InputLabel id="dc-label">Raspberry Pi device Serial Number</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={selectedEdgeSerials || ''}
                                onChange={(event) => handleEdgeSerialsChange(event.target.value)}
                                label="Raspberry Pi Device Serial Number"
                            >
                                <MenuItem key={0} value={'No Selection'}>
                                    No Selection
                                </MenuItem>
                                <MenuItem key={1} value={currentEdgeSerial}>
                                    {currentEdgeSerial}
                                </MenuItem>
                                {props.edgeSerials && props.edgeSerials.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitDetails}
                                disabled={touched}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showConfigurationModal,
        showModalType: state.vorConfiguration.showModalType,
        selectedData: state.vorConfiguration.selectedData,
        errorResponse: state.vorConfiguration.errorModal,
        edgeSerials: state.vorConfiguration.edgeSerials,
        displayErr: state.vorConfiguration.displayErroText

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchEdgeSerials: (deviceType) => dispatch(Actions.LOAD_EDGE_SERIALS(deviceType)),
        hideConfigurationModal: (type, show) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        addConfiguration: (dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList) => dispatch(Actions.CREATE_DOCK_DOOR(dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList)),
        editConfiguration: (dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList) => dispatch(Actions.EDIT_DOCK_DOOR(dockDoorId, dockDoorName, slsPortalIp, topic, rpDeviceIp, selectedEdgeSerials, status, customerId, dcId, callUpdatedDockDoorList))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationLVDoorAddEditModal);