import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import '../VORConfiguration/style.css';
import Alert from '@mui/material/Alert';


const VORConfigurationCodeDeployAddEditModal = (props) => {
    const [codeDeployWindowId, setCodeDeployWindowId] = useState(0);
    const [dayOfTheWeek, setDayOfTheWeek] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [customerId, setCustomerId] = useState(props.customerId);
    const [dcId, setDcId] = useState(props.dcId);
    const [dispalyERR, setDispalyERR] = useState(props.displayErr);



    useEffect(() => {
        if (props.selectedData) {
            setDayOfTheWeek(props.selectedData.day_of_the_week);
            setStartTime(props.selectedData.start_time);
            setEndTime(props.selectedData.end_time);
            setCustomerId(props.selectedData.customer_id);
            setDcId(props.selectedData.dc_id);
            setCodeDeployWindowId(props.selectedData.software_deployment_window_id ? props.selectedData.software_deployment_window_id : 0);
            setDispalyERR(props.selectedData.displayErr);
            if (props.selectedData.start_time === 0) {
                setStartTime('0');
            }
            if (props.selectedData.end_time === 0) {
                setEndTime('0');
            }
        }
    }, [props.selectedData])

    useEffect(() => {
        if (props.codeDeployError) {
            props.setLoading(false);
        }
    }, [props.codeDeployError])

    const handleClose = () => {
        props.hideConfigurationModal(props.showModalType, false);
    }

    const callUpdatedCodeDeployList = () => {
        handleClose();
        props.callUpdatedCodeDeployList();
    }
    const handleDayChange = (event) => {

        setDayOfTheWeek(event.target.value);
    }
    const handlestartTimeChange = (event) => {
        setDispalyERR(false);
        setStartTime(event.target.value);

    }
    const handleendTimeChange = (event) => {
        setEndTime(event.target.value);
    }

    const submitDetails = () => {
        if (props.showModalType === 'Add' && dayOfTheWeek === "" && startTime === "") {
            setDispalyERR(true);
        }
        if (props.showModalType === 'Add' && dayOfTheWeek !== "" && startTime !== "") {
            setDispalyERR(false); props.setLoading(true);
            props.addConfiguration(dayOfTheWeek, startTime, endTime, customerId, dcId, callUpdatedCodeDeployList);
        }
        if (props.showModalType === 'Edit') {
            setDispalyERR(false); props.setLoading(true);
            props.editConfiguration(dayOfTheWeek, startTime, endTime, customerId, dcId, codeDeployWindowId, callUpdatedCodeDeployList);
        }
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {props.codeDeployError && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.codeDeployError}</Alert>}
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '40ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <FormControl sx={{ width: '100%', marginTop: '20px' }}>
                            <InputLabel id="demo-simple-select-label">Day of the week</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className={dispalyERR ? 'showERR' : ''}
                                value={dayOfTheWeek ? dayOfTheWeek : ''}
                                label="Day of the week"
                                onChange={handleDayChange}
                            >
                                <MenuItem value='Monday'>Monday</MenuItem>
                                <MenuItem value='Tuesday'>Tuesday</MenuItem>
                                <MenuItem value='Wednesday'>Wednesday</MenuItem>
                                <MenuItem value='Thursday'>Thursday</MenuItem>
                                <MenuItem value='Friday'>Friday</MenuItem>
                                <MenuItem value='Saturday'>Saturday</MenuItem>
                                <MenuItem value='Sunday'>Sunday</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                            <InputLabel id="demo-simple-select-label">Start Time</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className={dispalyERR ? 'showERR' : ''}
                                value={startTime || ''}
                                label="Start Time"
                                onChange={handlestartTimeChange}
                            >
                                <MenuItem value='0'>12 AM</MenuItem>
                                <MenuItem value='1'>01 AM</MenuItem>
                                <MenuItem value='2'>02 AM</MenuItem>
                                <MenuItem value='3'>03 AM</MenuItem>
                                <MenuItem value='4'>04 AM</MenuItem>
                                <MenuItem value='5'>05 AM</MenuItem>
                                <MenuItem value='6'>06 AM</MenuItem>
                                <MenuItem value='7'>07 AM</MenuItem>
                                <MenuItem value='8'>08 AM</MenuItem>
                                <MenuItem value='9'>09 AM</MenuItem>
                                <MenuItem value='10'>10 AM</MenuItem>
                                <MenuItem value='11'>11 AM</MenuItem>
                                <MenuItem value='12'>12 PM</MenuItem>
                                <MenuItem value='13'>01 PM</MenuItem>
                                <MenuItem value='14'>02 PM</MenuItem>
                                <MenuItem value='15'>03 PM</MenuItem>
                                <MenuItem value='16'>04 PM</MenuItem>
                                <MenuItem value='17'>05 PM</MenuItem>
                                <MenuItem value='18'>06 PM</MenuItem>
                                <MenuItem value='19'>07 PM</MenuItem>
                                <MenuItem value='20'>08 PM</MenuItem>
                                <MenuItem value='21'>09 PM</MenuItem>
                                <MenuItem value='22'>10 PM</MenuItem>
                                <MenuItem value='23'>11 PM</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '100%', marginTop: '10px' }}>
                            <InputLabel id="demo-simple-select-label">End Time</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="endTime"
                                value={endTime ? endTime : ''}
                                label="End Time"
                                onChange={handleendTimeChange}
                            >
                                <MenuItem value='0'>12 AM</MenuItem>
                                <MenuItem value='1'>01 AM</MenuItem>
                                <MenuItem value='2'>02 AM</MenuItem>
                                <MenuItem value='3'>03 AM</MenuItem>
                                <MenuItem value='4'>04 AM</MenuItem>
                                <MenuItem value='5'>05 AM</MenuItem>
                                <MenuItem value='6'>06 AM</MenuItem>
                                <MenuItem value='7'>07 AM</MenuItem>
                                <MenuItem value='8'>08 AM</MenuItem>
                                <MenuItem value='9'>09 AM</MenuItem>
                                <MenuItem value='10'>10 AM</MenuItem>
                                <MenuItem value='11'>11 AM</MenuItem>
                                <MenuItem value='12'>12 PM</MenuItem>
                                <MenuItem value='13'>01 PM</MenuItem>
                                <MenuItem value='14'>02 PM</MenuItem>
                                <MenuItem value='15'>03 PM</MenuItem>
                                <MenuItem value='16'>04 PM</MenuItem>
                                <MenuItem value='17'>05 PM</MenuItem>
                                <MenuItem value='18'>06 PM</MenuItem>
                                <MenuItem value='19'>07 PM</MenuItem>
                                <MenuItem value='20'>08 PM</MenuItem>
                                <MenuItem value='21'>09 PM</MenuItem>
                                <MenuItem value='22'>10 PM</MenuItem>
                                <MenuItem value='23'>11 PM</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitDetails}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showConfigurationModal,
        showModalType: state.vorConfiguration.showModalType,
        selectedData: state.vorConfiguration.selectedData,
        codeDeployError: state.vorConfiguration.codeDeployError,
        codeDeployment: state.vorConfiguration.codeDeployment,
        displayErr: state.vorConfiguration.displayErroText

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideConfigurationModal: (type, show) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        addConfiguration: (dayOfTheWeek, startTime, endTime, customerId, dcId, callUpdatedCodeDeployList) => dispatch(Actions.CREATE_CODE_DEPLOYMENT(dayOfTheWeek, startTime, endTime, customerId, dcId, callUpdatedCodeDeployList)),
        editConfiguration: (dayOfTheWeek, startTime, endTime, customerId, dcId, codeDeployWindowId, callUpdatedCodeDeployList) => dispatch(Actions.EDIT_CODE_DEPLOYMENT(dayOfTheWeek, startTime, endTime, customerId, dcId, codeDeployWindowId, callUpdatedCodeDeployList))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationCodeDeployAddEditModal);