import axios from 'axios';
import {Auth} from "aws-amplify";
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,//process.env.REACT_APP_API_ENDPOINT, // our API base URL
});


export const getToken = async () => {
    try {
        const session = await Auth.currentSession();
        if(session && session.accessToken && session.accessToken.jwtToken){
            return session.accessToken.jwtToken;//localStorage.getItem("authToken");
        }else{
            localStorage.getItem("authToken");
        }
    } catch {
        return null;
    }
};


//user.attributes['custom:central_user_id']
//'https://deliveryiotdevapi.rehrig.com
// Request interceptor for adding the bearer token
api.interceptors.request.use(
    async(config) => {
        const token = localStorage.getItem("authToken"); // Assuming you store the token in localStorage
        // const session = await Auth.currentSession();
        //const loggedInUserIDToken = session.accessToken.jwtToken;
       // if (loggedInUserIDToken) {
            config.headers.Authorization = await getToken();//`Bearer ${loggedInUserIDToken}`;
            //console.log("config.headers.Authorization....",config.headers.Authorization)
            //config.headers['x-api-key'] = 'jPJoEqjzqC9NjMpWroPPH8G0vWQTV4Kcazb5LXMp';
            //config.headers.Authorization = `Bearer ${loggedInUserIDToken}`;
       // }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



// API endpoints
export const getQuicksightData = (qUrl,fType,cId,dId) => {
    try {
        return api.get(`/VorAdminSupportPortal/quick_sight_data?s3_url=${qUrl}&data_type=${fType}&customer_id=${cId}&dc_id=${dId}`);
    }catch (ex){
        console.log(ex.message);
    }

};

export const getQuicksightDataDetails = (picklist,fType,cId,dId) => {
    try {
        return api.get(`/VorAdminSupportPortal/quick_sight_data?picklist=${picklist}&data_type=${fType}&customer_id=${cId}&dc_id=${dId}`);
    }catch (ex){
        console.log(ex.message);
    }

};

export const getCategoryList = async () => {
    try {
        let results =  await api.get(`/SupportSphere/category`);
        if(results && results.data){
            //console.log(results);
            return results.data;
        }
        return null;

    }catch (ex){
        console.log(ex.message);
    }

};
export const getIssuTypes = async () => {
    try {
        let results =  await api.get(`/SupportSphere/issue_type`);
        if(results && results.data){
            //console.log(results);
            return results.data;
        }
        return null;

    }catch (ex){
        console.log(ex.message);
    }

};

export const createIssue = async (req) => {
    try {
        let reqUrl =  `/SupportSphere/issue`;
        let result = await api.post(reqUrl,req);
        if(result.status == 200){
            return "success";
        }
        return null;
    }catch (ex){
        return  ex.message;
    }
};
export const updateIssue = async (req,issue_id) => {
    try {
        let reqUrl =  `/SupportSphere/issue/${issue_id}`;
        let result = await api.put(reqUrl,req);
        if(result.status == 200){
            return "success";
        }
        return null;
    }catch (ex){
        return  ex.message;
    }
};
export const issueAssociation = async (req) => {
    try {
        let reqUrl =  `/SupportSphere/picklist_issue`;
        let result = await api.post(reqUrl,req);
        if(result.status == 200){
            return "success";
        }
        return null;
    }catch (ex){

    }
};
export const getIssueList = async () => {
    try {
        let reqUrl =  `/SupportSphere/issue`;
        let results = await api.get(reqUrl);
        if(results && results.data){
            //console.log(results);
            return results.data;
        }
        return null;

    }catch (ex){

    }

};





// Export the api instance
export default api;
