import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    locations: null,
    error: null,
    errorCustomers: null,
    showModalType: null,
    selectedData: null,
    showLocationModal: null
}

const LocationManagementReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.LOAD_LOCATIONS_SUCCESS_TYPE:
            newState.locations = action.payload.data.Data;
            break;
        case ActionTypes.LOCATION_ADD_EDIT_MODAL:
            newState.showModalType = action.payload.type;
            newState.showLocationModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            break;
        case ActionTypes.CREATE_LOCATION_SUCCESS:
            newState.showLocationModal = false;
            newState.error = null;
            break;
        case ActionTypes.CREATE_LOCATION_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_LOCATION_SUCCESS:
            newState.showLocationModal = false;
            newState.error = null;
            break;
        case ActionTypes.EDIT_LOCATION_ERROR:
            newState.error = errorDescription(action.error);
            break;
        default:
            break;
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default LocationManagementReducer;