import React, { useState, useEffect, useRef } from 'react';
import { Grid, Button, LinearProgress, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import FileIcon from "../../assets/FileIcon.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./styles.css";
import { addSKU } from "../../services/services";

export default function ImportDialog({ selectedCustomerName, setShowModal, fileTypes, setBodyResponse, setResponseData }) {
    const drop = useRef(null);
    let btnRef = useRef();
    const [open, setOpen] = useState(true);
    const [currentFile, setCurrentFile] = useState(null);
    const [content, setContent] = useState("");
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (drop && drop.current) {
            drop.current.addEventListener('dragover', handleDragOver, false);
            drop.current.addEventListener('drop', handleDrop, false);
            drop.current.addEventListener('dragleave', handleDragLeave, false);
        }
        // eslint-disable-next-line
    }, [drop, drop.current]);


    useEffect(() => {
        if (currentFile !== null) {
            var reader = new FileReader();
            reader.readAsDataURL(currentFile);
            reader.onload = function (evt) {
                let fileContent = reader.result;
                let base64 = window.btoa(fileContent);
                setContent(base64);
            }
            reader.onerror = function (evt) {
                console.log("error in reading file");
            }
        }
    }, [currentFile]);


    const handleClose = () => {
        setOpen(false);
        setShowModal(false);
        setCurrentFile("");
    };
    function hideError() {
        setTimeout(() => {
            setError(false);
            setErrorData("");
        }, 2000);
    }

    const HideDataPop = () => {
        setTimeout(() => {
            setSuccess(false);
            setOpen(false);
            setShowModal(false);
        }, 2000);
    }

    const handleupload = async () => {
        btnRef.current.setAttribute("disabled", "disabled");
        setShowConfirm(false);
        setLoading(true);
        if (currentFile !== null && btnRef.current) {
            const file_Name = currentFile ? currentFile.name : "";
            let data = await addSKU(file_Name, content, selectedCustomerName);
            setLoading(false);
            if (data) {
                if (data?.response?.data?.Error) {
                    setError(true);
                    setSuccess(false);
                    setBodyResponse(false);
                    setErrorData(data?.response?.data?.Error);
                    hideError();
                }
                if (data?.body) {
                    setResponseData(data.body);
                    setSuccess(true);
                    setBodyResponse(true);
                    setError(false);
                    HideDataPop();
                    setErrorData("");
                }

            }
        }
        else {
            setSuccess(false);
            setError(false);
            setErrorData("");
            setBodyResponse(false);
            setResponseData([]);
        }
    }

    const browseFileUpload = (e) => {
        const file = e.target.files[0];
        if (checkFileType(file)) {
            setCurrentFile(file);
        } else {
            setError("Incorrect File Type.")
        }
    };

    const handleChangeFile = () => {
        setCurrentFile(null);
    }
    const handleDragOver = (e) => {
        console.log("dragover")
        e.preventDefault();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
        const file = e.dataTransfer.files[0];
        if (checkFileType(file)) {
            setCurrentFile(file);
        }
    };

    const checkFileType = (file) => {
        const extension = file.name.split(".").slice(-1)[0];
        return fileTypes?.includes(`.${extension}`);
    };

    const handleClickConfirm = () => {
        handleupload();
    }
    const handleClickCancel = () => {
        setShowConfirm(false);
    }
    const ConfirmUpload = () => {
        setShowConfirm(true);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ maxWidth: "100%" }}
            >
                <DialogTitle id="alert-dialog-title">
                    {loading ? <LinearProgress /> : ""}
                    {error ? <p style={{ color: "red" }}>Error due to: {errorData}</p> : ""}
                    {success ? <p style={{ color: "green" }}>Success: Sku imported successfully</p> : ""}
                    {showConfirm ?
                        <div className='confirm-container'>
                            <Typography variant="subtitle1" component="div">
                                Would you like to upload skus for <span sx={{ fontWeight: "600" }}>{selectedCustomerName}</span>
                                <br />
                                <Button variant="outlined" sx={{ marginRight: "5px" }} onClick={handleClickConfirm}>
                                    Yes
                                </Button>
                                <Button variant="outlined" onClick={handleClickCancel}>
                                    No
                                </Button>
                            </Typography>
                        </div> : ""}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {currentFile === "" || currentFile === null ?
                            <Grid item xs={12} className='uploadContainer' ref={drop}>
                                <img src={FileIcon} alt="fileIcon" />
                                <div>
                                    Drag your file here, or
                                    <input type="file" className="custom-file-input" onChange={browseFileUpload} accept={fileTypes.join(",")} />
                                </div>
                            </Grid> :
                            <Grid item xs={12} className='uploadContainer' style={{ border: "2px dashed green" }} ref={drop}>
                                <div>
                                    {currentFile && <div style={{ color: "green", display: "flex", alignItems: "center" }}> <CheckCircleIcon /> {currentFile.name}
                                        <Button onClick={handleChangeFile} autoFocus>
                                            Change File
                                        </Button>
                                    </div>
                                    }
                                </div>
                            </Grid>
                        }


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {
                        currentFile ?
                            <Button onClick={ConfirmUpload} autoFocus ref={btnRef}>
                                Upload
                            </Button> : ""
                    }
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}