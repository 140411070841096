import {combineReducers} from "redux";
import Application from "./application";
import CustomerManagementReducer from "./customerManagement";
import LocationManagementReducer from "./locationManagement";
import VORConfigurationReducer from "./vorConfiguration";
import cicdUIDeployReducer from "./cicdUIDeploy";
import SkuUpdateReducer from "./skuUpdate";
import EdgePCS from "./edgepcs";
import siteHelathMetricsReducer from "./SiteHealthMetrics";

const reducers = combineReducers({
    customerManagement:CustomerManagementReducer,
    edgePCs:EdgePCS,
    application:Application,
    locationManagement: LocationManagementReducer,
    vorConfiguration: VORConfigurationReducer,
    cicdUIDeploy: cicdUIDeployReducer,
    skuUpdate: SkuUpdateReducer,
    siteHelathMetrics: siteHelathMetricsReducer
});

export default reducers;