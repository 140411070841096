export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const LOAD_CUSTOMERS = 'Load Customers';
export const LOAD_CUSTOMERS_SUCCESS_TYPE = '[CUSTOMER SUCCESS] Load Customer Success';
export const LOAD_CUSTOMERS_ERROR ='Load Customer Error';
export const LOAD_ROLES = 'Load Roles';
export const LOAD_ROLES_SUCCESS_TYPE = '[CUSTOMER ROLES] Load Roles Success';
export const LOAD_ROLES_ERROR ='Load Roles Error';
export const LOAD_APPLICATIONS = 'Load Applications';
export const LOAD_APPLICATIONS_SUCCESS_TYPE = '[APPLICATIONS SUCCESS] Load Applications Success';
export const LOAD_APPLICATIONS_ERROR ='Load Applications Error';
export const LOAD_LOCATIONS = 'Load Locations';
export const LOAD_LOCATIONS_SUCCESS_TYPE = '[LOCATION SUCCESS] Load Location Success';
export const LOAD_LOCATIONS_ERROR ='Load Location Error';
export const LOAD_USERS = 'Load Users';
export const LOAD_USERS_SUCCESS_TYPE = '[LOAD USERS] Load Users Success';
export const LOAD_USERS_ERROR ='[LOAD USERS] Load Users Error';
export const SEARCH_INPUT_UPDATE = '[SEARCH INPUT UPDATE] Search input update'
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER';
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS_TYPE = '[ADD_USER_SCUCESSS] Add User Success';
export const ADD_USER_ERROR ='ADD_USER_ERROR';
export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS_TYPE = '[EDIT_USER_SCUCESSS] EDIT User Success';
export const EDIT_USER_ERROR ='EDIT_USER_ERROR';