import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
//import { NULL } from 'node-sass';

let filteredArrry = []

const VORConfigurationCopyPropertiesModal = (props) => {
    const [customersList, setCustomersList] = useState(props.customers);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [locationsList, setLocationsList] = useState();
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [edgePCList, setEdgePCList] = useState(null);
    const [filteredEdgePCS, setfilteredEdgePCS] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState(props.selectedAppId);
    const [selectedEdgePC, setSelectedEdgePC] = useState('');
    const [selectedEdgePcId, setSelectedEdgePcId] = useState('');
    const [edgePCsConfiguration, setEdgePCsConfiguration] = useState(null);
    const [configurationError, setConfigurationError] = useState(null);
    const [selectedEdgePcConfig, setSelectedEdgePcConfig] = useState('');
    const [propertyName, setPropertyName] = useState();
    const [propertyValue, setPropertyValue] = useState();
    const [activeStatus, setActiveStatus] = useState(true);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [dcId, setDcId] = useState(props.dcId);
    const [edgePcId, setEdgePcId] = useState(props.selectedEdgePcId);
    const [configurationId, setConfigurationId] = useState(true);
    const [touched, setTouched] = useState(false);
    const tableStyle = {
        border: '1px solid black',
        padding: '8px'
    };

    let error1 = (propertyName === "");
    let error5 = (propertyValue === "");


    let isFieldInvalid = (!propertyName || !propertyValue);

    useEffect(() => {
        if ((error1 || error5) || isFieldInvalid) {
            setTouched(true);
        } else {
            setTouched(false);
        }
    }, [propertyName, propertyValue]);

    useEffect(() => {
        if(props.edgePCsList){
            setEdgePCList(props.edgePCsList);
            setConfigurationError(null);
        }
        if(!selectedLocationId){
            setEdgePCList();
        }
    }, [props.edgePCsList, selectedAppId, selectedLocationId]);

    useEffect(() => {
        setCustomersList(props.customersList);
    }, [props.customersList]);

    useEffect(() => {
        setEdgePCsConfiguration(props.edgePCsConfiguration);
        setConfigurationError(null);
    }, [props.edgePCsConfiguration]);

    useEffect(() => {
        if (props.selectedData) {
            setPropertyName(props.selectedData.property_name);
            setPropertyValue(props.selectedData.property_value);
            setConfigurationId(props.selectedData.configuration_id);
            setCustomerId(props.selectedData.customer_id);
            setDcId(props.selectedData.dc_id);
            setEdgePcId(props.selectedData.edge_pc_id);

            if (props.selectedData.status === 'ACTIVE') {
                setActiveStatus(true);
            } else {
                setActiveStatus(false);
            }
        }
    }, [props.selectedData])

    useEffect(() => {
        if (props.edgePCsConfigurationError) {
            setConfigurationError(props.edgePCsConfigurationError ? props.edgePCsConfigurationError : null);
            setEdgePCsConfiguration(null);
            if(configurationError){
                setTimeout(() => {
                    setConfigurationError(null);
                }, 1000);
            }
            props.setLoading(false);
        }
        if(props.errorResponse) {
            setConfigurationError(props.errorResponse)
            setEdgePCsConfiguration(null);
            setEdgePCList(null);
        }
    }, [configurationError])


    useEffect(() => {
        if (configurationError || configurationError === 'Resource not found.') {
           setEdgePCList(null);
           setEdgePCsConfiguration(null);
           filteredArrry = [];

        }
    }, [configurationError])

    const handleClose = () => {
        props.hideConfigurationModal('Copy', false);
    }

   filteredArrry = edgePCList && edgePCList.filter((item) => {
        if (item.application_type_id === selectedAppId) {
            return item;
        }
    });
    const callUpdatedConfigurationList = () => {
        handleClose();
        props.callUpdatedConfigurationList();
    }
    const submitDetails = () => {
        props.setLoading(true);
        props.addConfiguration(customerId, dcId, edgePcId, edgePCsConfiguration, callUpdatedConfigurationList);
    }
    const handleCustomerChange = (event) => {
        setSelectedLocationId();
        setSelectedLocation();
        setEdgePCList([]);
        setConfigurationError(null);
        filteredArrry = [];
        setSelectedCustomer(event);
        setSelectedCustomerId(event.customer_id);
        if (event.dcs && event.dcs[0].dc_id) {
            setLocationsList(event.dcs);
            setSelectedLocationId(event.dcs.dc_id);
        } else {
            setLocationsList();
            setSelectedLocationId();
        }
    }
    const handleLocationChange = (event) => {
        filteredArrry = [];
        setEdgePCList([]);
        setSelectedEdgePC();
        setEdgePCsConfiguration();
        setSelectedLocation(event);
        setSelectedLocationId(event.dc_id);
        props.fetchEdgePC2(event.dc_id);
    }
    const handleEdgePcChange = (event) => {
        setSelectedEdgePC(event);
        setSelectedEdgePcId(event.edge_pc_id);
        props.fetchEdgePcConfiguration2(event.edge_pc_id);
        setSelectedAppId(event.application_type_id);
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '1050px', maxWidth: 'none' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {/* {props.errorResponse ? <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert> :''} */}
                    {configurationError ? <Alert severity="error" sx={{ marginBottom: '20px' }}>{configurationError}</Alert> :''}
                    {customersList && <FormControl sx={{ float: 'left', width: 250, marginTop: '20px' }}>
                        <InputLabel id="customer-label">Customer</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectedCustomer || ''}
                            onChange={(event) => handleCustomerChange(event.target.value)}
                            label="Customer"
                            sx={{ textAlign: 'left' }}>
                            {customersList.map((item) => (
                                <MenuItem key={item.customer_id} value={item}>
                                    {item.customer_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    {locationsList && <FormControl sx={{ float: 'left', width: 250, marginTop: '20px', marginLeft: '10px' }}>
                        <InputLabel id="dc-label">DC</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectedLocation || ''}
                            onChange={(event) => handleLocationChange(event.target.value)}
                            label="DC"
                            sx={{ textAlign: 'left' }}>
                            {locationsList.map((item) => (
                                <MenuItem key={item.dc_id} value={item}>
                                    {item.dc_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>}
                    {edgePCList ? <FormControl sx={{ float: 'left', width: 250, marginTop: '20px', marginLeft: '10px' }}>
                        <InputLabel id="dc-label">Edge PC</InputLabel>
                        <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            value={selectedEdgePC || ''}
                            onChange={(event) => handleEdgePcChange(event.target.value)}
                            label="Edge pc"
                            sx={{ textAlign: 'left' }}>
                            {filteredArrry.map((item) => (
                                <MenuItem key={item.edge_pc_id} value={item}>
                                    {item.pc_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>:''}

                    <div style={{ marginTop: '120px' }}>
                        {edgePCsConfiguration && <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr>
                                    <th style={tableStyle}>Property Name</th>
                                    <th style={tableStyle}>Property Value</th>
                                    <th style={tableStyle}>Status</th>
                                </tr>
                                {edgePCsConfiguration.map((item) => (

                                    <tr key={item.configuration_id}>
                                        <td style={tableStyle}> {item.property_name}</td>
                                        <td style={tableStyle}>{item.property_value}</td>
                                        <td style={tableStyle}>{item.status}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>}
                    </div>
                    <div style={{ float: 'right', padding: '10px' }}>
                        <Button variant="contained"
                            onClick={submitDetails}
                        >Copy and Add Configuration</Button>
                    </div>
                    {/* </Box> */}
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showCopyConfigurationModal,
        selectedData: state.vorConfiguration.selectedData,
        errorResponse: state.vorConfiguration.edgePCserror2,
        edgePCsList: state.vorConfiguration.edgePCsList ? state.vorConfiguration.edgePCsList : [],
        edgePCsConfiguration: state.vorConfiguration.edgePCs2Configuration,
        edgePCsConfigurationError: state.vorConfiguration.edgePCs2ConfigurationError
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideConfigurationModal: (type, show) => dispatch(Actions.COPY_CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        fetchEdgePC2: (distributionId) => dispatch(Actions.LOAD_EDGEPCS_TWO(distributionId)),
        fetchEdgePcConfiguration2: (edgePcId) => dispatch(Actions.LOAD_EDGEPCS_CONFIGURATION_TWO(edgePcId)),
        addConfiguration: (customerId, dcId, edgePcId, properties, callUpdatedConfigurationList) => dispatch(Actions.COPY_CREATE_VOR_CONFIGURATION(customerId, dcId, edgePcId, properties, callUpdatedConfigurationList)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationCopyPropertiesModal);