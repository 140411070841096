import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import Toolbar from "@mui/material/Toolbar";
import { Paper, Grid, styled, FormControl, InputLabel } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Auth } from 'aws-amplify';
import ImportDialog from './ImportModal';
import "./styles.css";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    boxShadow: 'none !important'
}));



const ImportSKUS = (props) => {
    const [customersList, setCustomersList] = useState(props.customersList);
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [bodyResponse, setBodyResponse] = useState(false);
    const [responseData, setResponseData] = useState([]);
    const [indicatorOn, setIndicatorOn] = useState(false);
    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomers(jwt);
        })
    }, []);

    useEffect(() => {
        setCustomersList(props.customersList);
        if (!selectedCustomerName && props.customersList) {
            setSelectedCustomerName(props.customersList[0].customer_name);
        }
    }, [props.customersList]);

    useEffect(() => {
        if(!customersList){
            setIndicatorOn(true);
        }else{
            setIndicatorOn(false);
        }
    }, [customersList])

    const handleImportSKU = () => {
        setShowModal(true);
    }
    const handleCustomerChange = (event) => {
        setSelectedCustomerName(event);
        setBodyResponse(false);
        setResponseData([]);
    }
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });
    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                         sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }
            {showModal ? <ImportDialog setShowModal={setShowModal} selectedCustomerName={selectedCustomerName} setBodyResponse={setBodyResponse} setResponseData={setResponseData} fileTypes={[".csv", ".xlsx"]} /> : ""}
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ boxShadow: "none !important" }}>
                    <Item style={{ boxShadow: "none !important" }}><h3>Import SKU's</h3></Item>
                </Grid>
                <Grid item xs={3} style={{ boxShadow: "none !important" }}>
                    <Item style={{ boxShadow: "none !important" }}>
                        <div>
                            {customersList && <FormControl sx={{ float: 'left', width: 300 }}>
                                <InputLabel id="customer-label">Customer</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    label="Customer"
                                    value={selectedCustomerName || ''}
                                    onChange={(event) => handleCustomerChange(event.target.value)}
                                    sx={{ textAlign: 'left' }}>
                                    {customersList.map((item) => (
                                        <MenuItem key={item.central_customer_id} value={item.customer_name}>
                                            {item.customer_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}</div>
                    </Item>
                </Grid>
                <Grid item xs={2} style={{ boxShadow: "none !important", paddingLeft: '0px' }}>
                    <Item style={{ boxShadow: "none !important", paddingLeft: '0px' }}>
                        <Toolbar sx={{ justifyContent: "flex-start", textTransform: "none", paddingLeft: "0px !important" }}>
                            <Button variant="outlined"
                                onClick={handleImportSKU}
                                className='importBtn'
                            >Import SKU</Button>
                        </Toolbar>
                    </Item>
                </Grid>
                {bodyResponse ?
                    <Grid container spacing={2} sx={{ margin: "30px 1px", padding: "0px" }}>
                        <Grid item xs={12}>
                            <Item className="itemStyle" style={{ color: (responseData.NumberOfSKUsInserted > 0) ? "green" : "red" }}>Number of SKUs inserted: {responseData ? responseData.NumberOfSKUsInserted : ""}</Item>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "1px" }}>
                            <Item className="itemStyle" style={{ color: (responseData.NumberofBrandsInserted > 0) ? "green" : "red" }}>Number of Brands inserted: {responseData ? responseData.NumberofBrandsInserted : ""}</Item>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "1px" }}>
                            <Item className="itemStyle" style={{ color: (responseData.NumberOfPackagesInserted > 0) ? "green" : "red" }}>Number of Packages inserted: {responseData ? responseData.NumberOfPackagesInserted : ""}</Item>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "1px" }}>
                            <Item className="itemStyle" style={{ color: (responseData.NumberOfSKUsIgnored > 0) ? "green" : "red" }}>Number of skus ignored: {responseData ? responseData.NumberOfSKUsIgnored : ""}</Item>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "1px" }}>
                            <Item className="itemStyle" style={{ color: (responseData.NumberOfErrors > 0) ? "red" : "green" }}>Number of errors: {responseData ? responseData.NumberOfErrors : ""}</Item>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: "20px", fontSize: "14px" }}>Note: Detailed report will be sent in email.</Grid>

                    </Grid> : ""
                }

            </Grid>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customersList: state.customerManagement.customersData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomers: (authToken) => dispatch(Actions.LOAD_CUSTOMERS(authToken)),
        showaddLocationModal: (type, show) => dispatch(Actions.LOCATION_ADD_EDIT_MODAL(type, show))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportSKUS);
