import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import {LinearProgress, TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';


const VORNetworkConfigurationAddEditModal = (props) => {
    const [customerId, setCustomerId] = useState(props.customerId);
    const [dcId, setDcId] = useState(props.dcId);
    const [dnsAddress, setDnsAddress] = useState(null);
    const [gatewayAddress, setGatewayAddress] = useState(null);
    const [netmaskAddress, setNetmaskAddress] = useState(null);
    const [networkdata, setNetworkdata] = useState(props.networkConfiguration);
    const [networkConfigID, setNetworkConfigID] = useState(null);
    const [fillError, setFillError] = useState(props.displayError);

    useEffect(() => {
        if (props) {
            setCustomerId(props.customerId);
            setDcId(props.dcId);
            setDnsAddress(networkdata? networkdata[0].dns_address: null);
            setGatewayAddress(networkdata? networkdata[0].gateway_address: null);
            setNetmaskAddress(networkdata? networkdata[0].netmask_address: null);
            setNetworkConfigID(networkdata? networkdata[0].network_configuration_id: "");
            setFillError(props.displayError);
        }
    }, [props, networkdata])

    useEffect(() => {
        if (props.errorResponse) {
            props.setLoading(false);
        }
    }, [props.errorResponse]);

 useEffect(()=> {
    if(dnsAddress === ""){
        setDnsAddress(null);
    }
    if(gatewayAddress === ""){
        setGatewayAddress(null);
    }
    if(netmaskAddress === ""){
        setNetmaskAddress(null);
    }
   
 }, [dnsAddress, gatewayAddress, netmaskAddress])

 useEffect(() => {
    console.log(props);
    if (props.showModalType === 'Add Network Config') {
        console.log('Add Network Config')
    }
    if (props.showModalType === 'Update Network Config') {
        console.log('Update Network Config');
    }

}, [props.showModalType]);

    const handleClose = () => {
        props.hideConfigurationModal(props.shownetworkModalType, false);
    }

    const handleDNSaddress = (event) => {
        setDnsAddress(event.target.value.replace(/[^0-9\.\,]/g, ''));
    }
    const handleGatewayaddress = (event) => {
        setGatewayAddress(event.target.value.replace(/[^0-9\.\,]/g, ''));
    }
    const handleNetmaskaddress = (event) => {
        setNetmaskAddress(event.target.value.replace(/[^0-9\.\,]/g, ''));
    }
    
    const callUpdatedNetworkConfigurationList = () => {
        handleClose();
        props.callUpdatedNetworkConfigurationList();
    }
    const submitNeworkDetails = () => {
        if(props.showModalType === 'Add Network Config' && (dnsAddress === null && gatewayAddress === null)){
             setFillError(true);
        }
        else if(props.showModalType === 'Update Network Config' && (dnsAddress === null && gatewayAddress === null)){
            setFillError(true);
       }
        else if (props.showModalType === 'Add Network Config' && (dnsAddress || gatewayAddress || netmaskAddress)) { 
            setFillError(false); props.setLoading(true);
            props.addNetworkConfiguration(customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList);
        } 
        else if(props.showModalType === 'Update Network Config' && (dnsAddress || gatewayAddress || netmaskAddress)) {
            setFillError(false); props.setLoading(true);
            props.editnetworkConfiguration(customerId, dcId, dnsAddress, gatewayAddress, networkConfigID, netmaskAddress, callUpdatedNetworkConfigurationList);
        }
        else{}
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                   {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.networkmodalTitle}</DialogTitle>
                <DialogContent>
                    {fillError ? <div  className='display-error-text'>Gateway addess is mandatory</div> : ""}
                    {props.errorResponse && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert>}
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '10px', width: '46.5ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <TextField
                            id="outlined-name"
                            label="DNS Address"
                            value={dnsAddress || null}
                            style={{ backgroundColor: '#fff' }}
                            onChange={handleDNSaddress}
                        />
                        <TextField
                            id="outlined-name"
                            label="Gateway Address*"
                            value={gatewayAddress || null}
                            style={{ backgroundColor: '#fff' }}
                            onChange={handleGatewayaddress}
                        />
                        <TextField
                            id="outlined-name"
                            label="Netmask Address"
                            value={netmaskAddress || null}
                            style={{ backgroundColor: '#fff' }}
                            helperText={`Hint: '255.255.255.0' for DNS Address add "," in each Address`}
                            onChange={handleNetmaskaddress}
                        />

                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitNeworkDetails}
                            >Save</Button>
                             <Button variant="contained"
                               sx={{marginLeft:"5px"}}
                                onClick={handleClose}
                            >No</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showNetworkConfigurationModal,
        showModalType: state.vorConfiguration.shownetworkModalType,
        showProgress:state.vorConfiguration.showProgress,
        selectedData: state.vorConfiguration.selectedData,
        errorResponse: state.vorConfiguration.error,
        displayError: state.vorConfiguration.displayErroText
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideConfigurationModal: (type, show) => dispatch(Actions.NETWORK_CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        addNetworkConfiguration: (customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList) => dispatch(Actions.CREATE_NETWORK_CONFIGURATION(customerId, dcId, dnsAddress, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList)),
        editnetworkConfiguration: (customerId, dcId, dnsAddress, networkConfigID, gatewayAddress, netmaskAddress, callUpdatedNetworkConfigurationList) => dispatch(Actions.EDIT_NETWORK_CONFIGURATION(customerId, dcId, dnsAddress, gatewayAddress, networkConfigID, netmaskAddress, callUpdatedNetworkConfigurationList))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORNetworkConfigurationAddEditModal);