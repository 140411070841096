import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    error: null,
    errorModal: null,
    showModalType: null,
    shownetworkModalType: null,
    selectedData: null,
    showConfigurationModal: null,
    showCopyConfigurationModal: null,
    showNetworkConfigurationModal: null,
    customers: null,
    edgePCs: null,
    edgePCserror: null,
    edgePCserror2: null,
    edgePCsConfiguration: null,
    edgePCsConfigurationError: null,
    propertyNames: null,
    propertyNamesEdit: null,
    dockDoorList: null,
    dockDoorLoadError: null,
    networkConfiguration: null,
    callUpdatedFunction: null,
    showProgress: true,
    displayErroText: false,
    edgePCs2Configuration: null,
    edgePCs2ConfigurationError: null,
    codeDeployment: null,
    codeDeployError: null
}

const VORConfigurationReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.LOAD_CUSTOMERS_WITH_DC_SUCCESS:
            newState.customers = action.payload.customers.data.Data;
            break;
        case ActionTypes.LOAD_EDGEPCS_WITH_SUCCESS:
            newState.edgePCs = action.payload.edgePCs.data.Data
            break;
        case ActionTypes.LOAD_EDGEPCS_WITH_ERROR:
            newState.edgePCserror = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_EDGEPCS_TWO_WITH_SUCCESS:
            newState.edgePCserror2 = null;
            newState.edgePCsList = action.payload.edgePCs.data.Data
            break;
        case ActionTypes.LOAD_EDGEPCS_TWO_WITH_ERROR:
            newState.edgePCserror2 = errorDescription(action.payload.error);
            newState.edgePCsList = null;
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS:
            newState.edgePCsConfigurationError = null;
            newState.edgePCsConfiguration = action.payload.edgePCsConfiguration.data.Data;
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_SUCCESS:
            newState.edgePCs2ConfigurationError = null;
            newState.edgePCs2Configuration = action.payload.edgePCsConfiguration.data.Data;
            break;
        case ActionTypes.COPY_CREATE_VOR_CONFIGURATION_SUCCESS:
            newState.edgePCsConfigurationError = null;
            newState.edgePCsConfiguration = action.payload.edgePCsConfiguration.data.Data;
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR:
            newState.edgePCsConfigurationError = errorDescription(action.payload.error);
            newState.edgePCsConfiguration = null;
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_TWO_WITH_ERROR:
            newState.edgePCs2ConfigurationError = errorDescription(action.payload.error);
            newState.edgePCs2Configuration = null;
            break;
        case ActionTypes.LOAD_NETWORK_CONFIGURATION_WITH_SUCCESS:
            newState.networkConfiguration = action.payload.networkConfiguration.data.Data;
            break;
        case ActionTypes.LOAD_NETWORK_CONFIGURATION_WITH_ERROR:
            newState.networkConfiguration = errorDescription(action.payload.error);
            break;
        case ActionTypes.NETWORK_CONFIGURATION_ADD_EDIT_MODAL:
            newState.shownetworkModalType = action.payload.type;
            newState.displayErroText = false;
            newState.showNetworkConfigurationModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            break;
        case ActionTypes.CREATE_NETWORK_CONFIGURATION_SUCCESS:
            newState.showNetworkConfigurationModal = false;
            newState.error = null;
            newState.displayErroText = false;
            break;
        case ActionTypes.CREATE_NETWORK_CONFIGURATION_ERROR:
            newState.showProgress = false;
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_NETWORK_CONFIGURATION_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_NETWORK_CONFIGURATION_SUCCESS:
            newState.showNetworkConfigurationModal = false;
            newState.displayErroText = false;
            newState.error = null;
            break;
        case ActionTypes.CONFIGURATION_ADD_EDIT_MODAL:
            newState.showModalType = action.payload.type;
            newState.showConfigurationModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            break;
        case ActionTypes.COPY_CONFIGURATION_ADD_EDIT_MODAL:
            newState.showCopyConfigurationModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            newState.edgePCs2Configuration = null;
            break;
        case ActionTypes.CREATE_VOR_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.error = null;
            break;
        case ActionTypes.EDIT_VOR_CONFIGURATION_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_VOR_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.error = null;
            break;
        case ActionTypes.CREATE_VOR_CONFIGURATION_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.LOAD_PROPERTY_NAMES_SUCCESS:
            newState.propertyNames = action.payload.propertyNames.data.Data;
            break;
        case ActionTypes.LOAD_PROPERTY_NAMES_EDIT_SUCCESS:
            newState.propertyNamesEdit = action.payload.propertyNamesEdit.data.Data;
            break;
        case ActionTypes.LOAD_DOCK_DOOR_SUCCESS:
            newState.dockDoorLoadError = null;
            newState.dockDoorList = action.payload.data.Data;
            break;
        case ActionTypes.LOAD_DOCK_DOOR_ERROR:
            newState.dockDoorList = null;
            newState.dockDoorLoadError = errorDescription(action.error);
            break;
        case ActionTypes.LOAD_PALLET_CONFIGURATION_WITH_SUCCESS:
            newState.palletConfiguration = action.payload.data.data.Data;
            newState.palletConfigurationLoadError = null;
            newState.displayErroText = false;
            newState.showProgress = false
            break;
        case ActionTypes.LOAD_PALLET_CONFIGURATION_WITH_ERROR:
            newState.palletConfiguration = null;
            newState.palletConfigurationLoadError = errorDescription(action.error);
            break;
        case ActionTypes.CREATE_PALLET_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.displayErroText = false;
            newState.showProgress = false;
            newState.errorModal = null;
            break;
        case ActionTypes.CREATE_PALLET_CONFIGURATION_ERROR:
            newState.showConfigurationModal = false;
            newState.displayErroText = false;
            newState.showProgress = false;
            newState.errorModal = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_PALLET_CONFIGURATION_ERROR:
            newState.errorModal = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_PALLET_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.errorModal = null;
            break;
        case ActionTypes.CREATE_DOCK_DOOR_SUCCESS:
            newState.showConfigurationModal = false;
            newState.errorModal = null;
            break;
        case ActionTypes.LOAD_EDGE_SERIALS_WITH_SUCCESS:
            newState.edgeSerials = action.payload.edgeSerials.data.Data;
            break;
        case ActionTypes.EDIT_DOCK_DOOR_SUCCESS:
            newState.showConfigurationModal = false;
            newState.errorModal = null;
            break;
        case ActionTypes.CREATE_DOCK_DOOR_SUCCESS:
            newState.showConfigurationModal = false;
            newState.displayErroText = false;
            newState.errorModal = null;
            break;
        case ActionTypes.CREATE_DOCK_DOOR_ERROR:
            newState.errorModal = errorDescription(action.error);
            newState.displayErroText = false;
            break;
        case ActionTypes.EDIT_DOCK_DOOR_ERROR:
            newState.errorModal = errorDescription(action.error);
            break;
        case ActionTypes.LOAD_CODE_DEPLOYMENT_WITH_SUCCESS:
            newState.codeDeployment = action.payload.data.Data;
            newState.codeDeployError = null;
            break;
        case ActionTypes.LOAD_CODE_DEPLOYMENT_WITH_ERROR:
            newState.codeDeployError = errorDescription(action.error);
            break;
        case ActionTypes.CREATE_CODE_DEPLOYMENT_WITH_SUCCESS:
            newState.codeDeployment = action.payload.data.Data;
            newState.codeDeployError = null;
            newState.displayErroText = false;
            break;
        case ActionTypes.CREATE_CODE_DEPLOYMENT_WITH_ERROR:
            newState.codeDeployError = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_CODE_DEPLOYMENT_WITH_SUCCESS:
            newState.codeDeployment = action.payload.data.Data;
            newState.codeDeployError = null;
            newState.displayErroText = false;
            break;
        case ActionTypes.EDIT_CODE_DEPLOYMENT_WITH_ERROR:
            newState.codeDeployError = errorDescription(action.error);
            break;
        case ActionTypes.DELETE_CODE_DEPLOYMENT_WITH_SUCCESS:
            newState.codeDeployment = action.payload.data.Data;
            newState.codeDeployError = null;
            newState.displayErroText = false;
            break;
        case ActionTypes.DELETE_CODE_DEPLOYMENT_WITH_ERROR:
            newState.codeDeployError = errorDescription(action.error);
            break;
        default:
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default VORConfigurationReducer;