import React, { useState, useEffect, useDebugValue } from 'react';
import { connect } from 'react-redux'
import { Box, Autocomplete, TextField, Grid, Paper, Button, Stack, Toolbar, Chip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import * as EdgePCActions from '../../../actions/edgepcs';
import ConfigureTandemModal from './AddEditTandemModal'
import { useAsyncError } from 'react-router';
import { useFetcher } from 'react-router-dom';



const EdgePCList = ({ user, ...props }) => {

  const [edgePCs, setEdgePCs] = useState(props.edgePCs);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const showWrapperName = (params) => {
    let wrapperName = edgePCs.find(({ edge_pc_id }) => edge_pc_id === params.row[params.field])
    return (<>
      <label>{wrapperName && wrapperName.pc_name}</label>
    </>)
  }
  const editTandem = (params) => {
    return <Button variant="contained" onClick={() => {
      props.setSelectedTandem(params.row);
      setShowEditModal(true);
    }}>Edit</Button>
  }
  const handleModalClose = (refresh) => {
    setShowAddModal(false)
    setShowEditModal(false);
    props.setSelectedTandem(null);

  }
  const columns = [
    { field: 'tandem_details_id', headerName: 'ID', width: 90 },
    {
      field: 'number_of_wrappers',
      headerName: 'Number Of Wrappers',
      width: 150,
      editable: false,
    },
    {
      field: 'wrapper_1',
      headerName: 'Wrapper 1',
      width: 150,
      renderCell: showWrapperName,
    },
    {
      field: 'wrapper_2',
      headerName: 'Wrapper 2',
      width: 150,
      renderCell: showWrapperName,
    },
    {
      field: 'wrapper_3',
      headerName: 'Wrapper 3',
      width: 150,
      renderCell: showWrapperName,
    },
    user.canWrite && {
      field: 'editTandem',
      headerName: 'Edit',
      width: 170,
      renderCell: editTandem
    }

  ];

  useEffect(() => {
    setEdgePCs(props.edgePCs);
  }, [props.edgePCs]);

  useEffect(() => {
    if (props.addOrEditSuccess && props.selectedDC) {
      props.fetchEdgePC(props.selectedDC.dc_id);
      props.fetchTandems(props.selectedDC.dc_id);
    }
  }, [props.addOrEditSuccess]);

  const handleAddTandem = () => {
    setShowAddModal(true);
  }


  return (
    <Box >
      <div className="dashboardData-panel">

        {user.canWrite && <Toolbar sx={{ justifyContent: "flex-end" }}>
          <Stack spacing={2} direction="row">
            <Button variant="outlined" onClick={handleAddTandem} >Add Tandem</Button>
          </Stack>
        </Toolbar>}

        <div className="duolift-device-detail-card" style={{ width: "100%" }}>
          <div className="detail-card-header">
            <h3 style={{ paddingTop: '10px' }}>Tandems</h3>
          </div>
          <div className="duolift-device-detail-card-body">
            <div style={{ height: '650px', width: '100%' }}>

              <DataGrid
                rows={props.tandems ? props.tandems : []}
                columns={columns}
                disableVirtualization
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                getRowId={(row) => row.tandem_details_id}
                key={"tandems"}
              />
              {showAddModal && <ConfigureTandemModal handleClose={handleModalClose} modalTitle="Add Edge PC" />}
              {showEditModal && <ConfigureTandemModal handleClose={handleModalClose} modalTitle="Edit Edge PC" />}

            </div>
          </div>
        </div>
      </div>

    </Box>

  );
}
const mapStateToProps = (state) => {
  return {
    customers: state.edgePCs.customers,
    selectedData: state.edgePCs.selectedData,
    edgePCs: state.edgePCs.edgePCs,
    addOrEditSuccess: state.edgePCs.addOrEditSuccess,
    selectedDC: state.edgePCs.selectedDistributionCenter,
    selectedCustomer: state.edgePCs.selectedCustomer,
    tandems: state.edgePCs.tandems,
    showAddTandemModel: state.edgePCs.showAddTandemModel,
    user: state.application.user,
    tendemError:state.edgePCs.tendemError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchCustomersWithDC: () => dispatch(EdgePCActions.LOAD_CUSTOMERS_WITH_DC()),
    fetchEdgePC: (dc_id) => dispatch(EdgePCActions.LOAD_EDGEPCS(dc_id)),
    fetchTandems: (dc_id) => dispatch(EdgePCActions.LOAD_TANDEMS(dc_id)),
    setSelectedTandem: (tandem) => dispatch(EdgePCActions.SELECT_TANDEM(tandem)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EdgePCList);
