import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    userTokens: null,
    customersData: null,
    applicationsList: null,
    showModalType: null,
    showCustomerModal: null,
    selectedData: null,
    error: null,
    errorCustomers: null
}

const CustomerManagementReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.LOAD_APPLICATIONS_SUCCESS_TYPE:
            // let applicationsListTemp = action.payload.data.Data;
            newState.applicationsList = action.payload.data.Data;
            break;
        case ActionTypes.LOAD_CUSTOMERS_SUCCESS_TYPE:
            newState.customersData = action.payload.data.Data;
            newState.error = null;
            break;
        case ActionTypes.LOAD_CUSTOMERS_ERROR:
            newState.errorCustomers = errorDescription(action.error);
            break;
        case ActionTypes.CUSTOMER_ADD_EDIT_MODAL_UPDATE:
            newState.showModalType = action.payload.type;
            newState.showCustomerModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            break;
        case ActionTypes.CREATE_CUSTOMER_SUCCESS:
            newState.showCustomerModal = false;
            newState.error = null;
            break;
        case ActionTypes.CREATE_CUSTOMER_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.EDIT_CUSTOMER_SUCCESS:
            newState.showModalType = action.payload.type;
            newState.showCustomerModal = action.payload.show;
            newState.selectedData = action.payload.selectedData;
            newState.error = null;
            break;
        case ActionTypes.EDIT_CUSTOMER_ERROR:
            newState.error = errorDescription(action.error);
            break;
        default:
            break;
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default CustomerManagementReducer;