import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, LinearProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';


const VORConfigurationAddEditModal = (props) => {
    const [propertyName, setPropertyName] = useState();
    const [propertyValue, setPropertyValue] = useState();
    const [activeStatus, setActiveStatus] = useState(true);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [dcId, setDcId] = useState(props.dcId);
    const [edgePcId, setEdgePcId] = useState(props.edgePcId);
    const [configurationId, setConfigurationId] = useState(true);
    const [touched, setTouched] = useState(false);

    let error1 = (propertyName === "");
    let error5 = (propertyValue === "");


    let isFieldInvalid = (!propertyName || !propertyValue);

    useEffect(() => {
        if ((error1 || error5) || isFieldInvalid) {
            setTouched(true);
        } else {
            setTouched(false);
        }
    }, [propertyName, propertyValue]);

    useEffect(() => {
        if (props.selectedData) {
            setPropertyName(props.selectedData.property_name);
            setPropertyValue(props.selectedData.property_value);
            setConfigurationId(props.selectedData.configuration_id);
            setCustomerId(props.selectedData.customer_id);
            setDcId(props.selectedData.dc_id);
            setEdgePcId(props.selectedData.edge_pc_id);

            if (props.selectedData.status === 'ACTIVE') {
                setActiveStatus(true);
            } else {
                setActiveStatus(false);
            }
        }
    }, [props.selectedData])

    const handleClose = () => {
        props.hideConfigurationModal(props.showModalType, false);
        setPropertyName();
        setPropertyValue();
    }
    const handleCheckBoxChange = (event) => {
        setActiveStatus(event.target.checked)
    }
    const handlePropertyNameChange = (event) => {
        setPropertyName(event);
    }

    const handlePropertyValueChange = (event) => {
        setPropertyValue(event.target.value);
    }
    const callUpdatedConfigurationList = () => {
        handleClose();
        props.callUpdatedConfigurationList();
    }
    const submitDetails = () => {
        let statusTemp = activeStatus ? 'ACTIVE' : 'INACTIVE';
        let propertiesTemp = [{ property_name: propertyName, property_value: propertyValue, status: statusTemp }]
        if (props.showModalType === 'Add') {
            props.setLoading(true);
            props.addConfiguration(customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList);
        } else if (props.showModalType === 'Edit') {
            props.setLoading(true);
            props.editConfiguration(configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList);
        }
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>

                {(props.propertyNames === null || props.propertyNames === "") ? <Alert severity="error" sx={{ marginBottom: '20px' }}>No Property Names available to add configuration</Alert> : ""}
                <DialogContent>
                    {props.errorResponse && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert>}
                    <div style={{ float: 'right', marginBottom: '20px' }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={activeStatus} onChange={handleCheckBoxChange} />} label="Active Status" />
                        </FormGroup>
                    </div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '40ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >

                        {props.updatedpropertyNames && <FormControl sx={{ width: '40ch' }}>
                            <InputLabel id="dc-label">Property Name*</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={propertyName || ''}
                                onChange={(event) => handlePropertyNameChange(event.target.value)}
                                label="Property Name"
                                error={error1}>
                                {props.updatedpropertyNames.map((item) => (
                                    <MenuItem key={item.property_id} value={item.property_name}>
                                        {item.property_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        <TextField
                            id="outlined-name"
                            label="Property Value"
                            value={propertyValue || ''}
                            onChange={handlePropertyValueChange}
                            error={error5}
                            helperText={error5 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />

                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitDetails}
                                disabled={touched}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showConfigurationModal,
        showModalType: state.vorConfiguration.showModalType,
        selectedData: state.vorConfiguration.selectedData,
        errorResponse: state.vorConfiguration.error
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideConfigurationModal: (type, show) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        editConfiguration: (configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, status, callUpdatedConfigurationList) => dispatch(Actions.EDIT_VOR_CONFIGURATION(configurationId, customerId, dcId, edgePcId, propertyName, propertyValue, status, callUpdatedConfigurationList)),
        addConfiguration: (customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList) => dispatch(Actions.CREATE_VOR_CONFIGURATION(customerId, dcId, edgePcId, propertyName, propertyValue, statusTemp, callUpdatedConfigurationList)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationAddEditModal);