import * as ActionTypes from "./actiontypes"
export const SHOW_NOTIFICATION = (notification) => {
    return {
        type: ActionTypes.SHOW_NOTIFICATION,
        payload: {notification:notification}
    }
}
export const SET_USER_INFO = (userInfo) => {
    return {
        type: ActionTypes.SET_USER_INFO,
        payload: {userInfo:userInfo}
    }
}
