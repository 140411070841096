import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';

const VORConfigurationGridPanel = ({user,...props}) => {
    const [edgePcsList, setEdgePcsList] = useState(props.edgePCsConfiguration);

    useEffect(() => {
        setEdgePcsList(props.edgePCsConfiguration);
    }, [props.edgePCsConfiguration]);

    const handleEditConfigurationTapped = (params) =>{
        props.showAddEditConfigurationModal('Edit', true, params);
    }

    const editConfigurationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={()=>handleEditConfigurationTapped(params.row)}>Edit Configuration</Button>
        } else {
            return null
        }
    }
    const customerStatus = (params) => {
        if (params.row.status === 'ACTIVE') {
            return "Active"
        } else {
            return "Inactive"
        }
    }

    const columns = [
        { field: 'configuration_id', headerName: 'CONFIGURATION ID', width: 180 },
        { field: 'property_name', headerName: 'PROPERTY NAME', width: 250 },
        { field: 'property_value', headerName: 'PROPERTY VALUE', width: 400},
        { field: 'status', headerName: 'STATUS', width: 150, renderCell: customerStatus },
        user.canWrite && { field: 'editConfiguration', headerName: 'EDIT CONFIGURATION', width: 250, renderCell: editConfigurationButton }
    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{paddingTop:'10px'}}>Configurations</h3>
                    </div>
                    <div className="duolift-device-detail-card-body">
                        <div style={{ height: '650px', width: '100%' }}>
                            <DataGrid
                                rows={edgePcsList}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.configuration_id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showCustomerModal,
        showModalType : state.vorConfiguration.showModalType,
        user: state.application.user
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditConfigurationModal: (type, show, selectedData) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show, selectedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationGridPanel);