import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

const CustomerManagementGridPanel = ({user,...props}) => {
    const [customersList, setCustomersList] = useState(props.customersList);
    const [applicationsList, setApplicationsList] = useState(props.applicationsList);
    const handleEditCustomerTapped = (params) =>{
        props.showAddEditCustomerModal('Edit', true, params);
    }
    useEffect(() => {
        setCustomersList(props.customersList)
    }, [props.customersList]);

    const editCustomerButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={()=>handleEditCustomerTapped(params.row)}>Edit Customer</Button>
        } else {
            return null
        }
    }
    const customerStatus = (params) => {
        if (params.row.active) {
            return "Active"
        } else {
            return "Inactive"
        }
    }
    const applicationsAccess = (params) => {
        let applicationNames = [];
        if (params.row.applications.length > 0 && applicationsList && applicationsList.length > 0) {
            const applicationsFiltered = applicationsList.filter(el => {
                return params.row.applications.some(f => {
                    return f === el.central_application_id;
                });
            });
            if (applicationsFiltered.length > 0) {
                for (const item of applicationsFiltered) {
                    applicationNames.push(<Chip key={item.central_application_id} sx={{ marginRight: '5px' }} label={item.application_short_name} />)
                }
                return applicationNames;
            }
        }

    }
    const columns = [
        { field: 'central_customer_id', headerName: 'CENTRAL CUSTOMER ID', width: 190 },
        { field: 'customer_name', headerName: 'CUSTOMER NAME', width: 250 },
        { field: 'short_name', headerName: 'CUSTOMER SHORT NAME', width: 200 },
        { field: 'active', headerName: 'STATUS', width: 170, renderCell: customerStatus },
        { field: 'applications', headerName: 'APPLICATIONS', width: 350, renderCell: applicationsAccess },
        user.canWrite && { field: 'editCustomer', headerName: 'EDIT CUSTOMER', width: 200, renderCell: editCustomerButton }
    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{paddingTop:'10px'}}>CUSTOMERS</h3>
                    </div>
                    <div className="duolift-device-detail-card-body">
                        <div style={{ height: '440px', width: '100%' }}>
                            <DataGrid
                                rows={customersList}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.central_customer_id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.customerManagement.showCustomerModal,
        showModalType : state.customerManagement.showModalType,
        user: state.application.user
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditCustomerModal: (type, show, selectedData) => dispatch(Actions.CUSTOMER_ADD_EDIT_MODAL_UPDATE(type, show, selectedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagementGridPanel);