import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import LocationManagementGridPanel from '../LocationManagementGridPanel/LocationManagementGridPanel';
import LocationManagementAddEditModal from '../LocationManagementAddEditModal/LocationManagementAddEditModal'
import { Auth } from 'aws-amplify';


const LocationManagement = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState(props.customersList);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [locationsList, setLocationsList] = useState(props.locations);
    const [showModal, setShowModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchLocations(jwt);
            props.fetchCustomers(jwt);
        })
    }, []);

    useEffect(() => {
        setCustomersList(props.customersList);
        setLocationsList(props.locations);
        if (!selectedCustomerId && props.customersList) {
            setSelectedCustomerId(props.customersList[0].central_customer_id);
        }
    }, [props.locations, props.customersList]);

    useEffect(() => {
        if (!locationsList) {
            setIndicatorOn(true);
        } else { setIndicatorOn(false) }
    }, [locationsList])

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add Location');

        } else {
            setModalTitle('Edit Location');
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType]);

    const handleAddLocationTapped = () => {
        props.showaddLocationModal("Add", true);
        setShowModal(true);
    }
    const handleEditLocationTapped = () => {
        props.showaddLocationModal("Edit", true);
        setShowModal(true);
    }
    const handleCustomerChange = (event) => {
        setSelectedCustomerId(event);
    }
    const callUpdatedLocationList = () => {
        let authToken = localStorage.getItem('authToken');
        props.fetchLocations(authToken);
        setLocationsList(props.locations);
        setLoading(false);
    }
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });
    return (
        <div style={{ width: '100%' }}>
            <div>
                <h3>Location Management</h3>
                {customersList && <FormControl sx={{ float: 'left', width: 300 }}>
                    <h5 style={{ textAlign: 'left', marginBottom: '0px' }}>Customer</h5>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={selectedCustomerId || ''}
                        onChange={(event) => handleCustomerChange(event.target.value)}
                        sx={{ textAlign: 'left' }}>
                        {customersList.map((item) => (
                            <MenuItem key={item.central_customer_id} value={item.central_customer_id}>
                                {item.customer_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}</div>
            <div style={{ padding: '10px', paddingTop: '150px' }}>

                {user.canWrite && <Toolbar sx={{ justifyContent: "flex-end" }}>
                    <Button variant="outlined" onClick={handleAddLocationTapped} >Add Location</Button>
                </Toolbar>
                }
                {/* {!locationsList && <CircularProgress/>} */}
                {indicatorOn ?
                    <div>
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={indicatorOn}
                        >
                            <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> : ''
                }
                {locationsList && <LocationManagementGridPanel locationsList={locationsList} centralCustomerId={selectedCustomerId} disableRowSelectionOnClick={true}></LocationManagementGridPanel>}
                <LocationManagementAddEditModal showModal={showModal} modalTitle={modalTitle} editModal={handleEditLocationTapped} centralCustomerId={selectedCustomerId} callUpdatedLocationList={callUpdatedLocationList} loading={loading} setLoading={setLoading}></LocationManagementAddEditModal>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customersList: state.customerManagement.customersData,
        locations: state.locationManagement.locations,
        showModalType: state.locationManagement.showModalType,
        selectedData: state.locationManagement.selectedData,
        showModal: state.locationManagement.showLocationModal,
        user: state.application.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchLocations: (selectedCustomerId) => dispatch(Actions.LOAD_LOCATIONS(selectedCustomerId)),
        fetchCustomers: (authToken) => dispatch(Actions.LOAD_CUSTOMERS(authToken)),
        showaddLocationModal: (type, show) => dispatch(Actions.LOCATION_ADD_EDIT_MODAL(type, show))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationManagement);
