import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';


const SkuUpdateTabMergePackageType = ({ user, ...props }) => {
    const [packageType, setPackageType] = useState(props.packageType);
    const [selectedOldPackageType, setSelectedOldPackageType] = useState('');
    const [selectedOldPackageTypeId, setSelectedOldPackageTypeId] = useState('');
    const [selectedNewPackageType, setSelectedNewPackageType] = useState('');
    const [selectedNewPackageTypeId, setSelectedNewPackageTypeId] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [customersVorList, setCustomersVorList] = useState(props.customersVorList);
    const [updateStatus, setUpdateStatus] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [indicatorOn, setIndicatorOn] = useState(false);

    useEffect(() => {
        setCustomersVorList(props.customersVorList);
    }, [props.customersVorList]);

    useEffect(() => {
        setCustomersVorList(props.customersVorList);
        let jwt = localStorage.getItem('authToken');
        props.fetchPackageType(jwt, null);
    }, []);

    useEffect(() => {
        setPackageType(props.packageType);
    }, [props.packageType]);

    useEffect(() => {
        if(props.mergeweightstatus){
            setUpdateStatus(props.mergeweightstatus);
            if(updateStatus || updateStatus !== null || updateStatus === 'Successfully merged package type'){
                setIndicatorOn(false);
                callReUpdate();
            }
        }
        if(props.mergeweighterror){
            setUpdateError(props.mergeweighterror)
            if(updateError || updateError !== null){
                setIndicatorOn(false);
                callReUpdate();
            }
        }
    }, [props.mergeweightstatus, props.mergeweighterror, updateStatus, updateError])

    const callReUpdate = () => {
        setTimeout(() => {
            setUpdateError(null);
            setUpdateStatus(null);
        }, 1500);
    }

    const handleOldPackageTypeChange = (event) => {
        setSelectedOldPackageType(event);
        setSelectedOldPackageTypeId(event.package_type_id);
    }

    const handleNewPackageTypeChange = (event) => {
        setSelectedNewPackageType(event);
        setSelectedNewPackageTypeId(event.package_type_id);
    }
    const handleVorCustomerChange = (event) => {
        setSelectedCustomer(event);
        setSelectedCustomerId(event.customer_id);
        let jwt = localStorage.getItem('authToken');
        props.fetchPackageType(jwt, event.customer_id);
    }
    const submitDetails = () => {
        setIndicatorOn(true);
        props.mergeWeight("merge", selectedCustomerId, selectedOldPackageTypeId, selectedNewPackageTypeId);
    }
    const defaultOldPropsPackageType = {
        options: packageType,
        getOptionLabel: (option) => option.package_name,
    };
    const defaultNewPropsPackageType = {
        options: packageType,
        getOptionLabel: (option) => option.package_name,
    };
    return (
        <div style={{ paddingTop: '20px', width: '100%', display: 'block' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                        sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }
            <div style={{ paddingTop: '20px', width: '800px', display: 'block' }}>
                {customersVorList && <FormControl sx={{ float: 'left', width: 400, marginRight: '20px' }}>
                    <InputLabel id="demo-multiple-name-label">Customers</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="Customers"
                        value={selectedCustomer || ''}
                        onChange={(event) => handleVorCustomerChange(event.target.value)}
                        sx={{ textAlign: 'left' }}>
                        {customersVorList.map((item) => (
                            <MenuItem key={item.customer_id} value={item}>
                                {item.customer_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </div>
            <div style={{ paddingTop: '20px', width: '800px', display: 'block' }}>
                {packageType && <Autocomplete
                    {...defaultOldPropsPackageType}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    onChange={(e, v) => handleOldPackageTypeChange(v)}
                    sx={{ float: 'left', width: 400, marginRight: '20px', marginTop: '30px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Package Type To Be Deleted" variant="standard" />
                    )}
                />}
                {packageType && <Autocomplete
                    {...defaultNewPropsPackageType}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    onChange={(e, v) => handleNewPackageTypeChange(v)}
                    sx={{ float: 'left', width: 400, marginRight: '20px', marginTop: '30px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="New Package Type" variant="standard" />
                    )}
                />}

            </div>
            {user.canWrite ?
                <div style={{ position: 'absolute', marginTop: '120px' }}>
                    <Button variant="contained"
                        onClick={submitDetails}
                        sx={{ width: '200px', marginTop: '120px', float: 'left', display: 'block' }}>Submit</Button>
                </div> : ""
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customersVorList: state.skuUpdate.customersVorList,
        packageType: state.skuUpdate.packageType,
        user: state.application.user,
        mergeweightstatus: state.skuUpdate.mergeweightstatus ? state.skuUpdate.mergeweightstatus : null,
        mergeweighterror: state.skuUpdate.mergeweighterror
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        mergeWeight: (action, customerId, delPackageTypeId, mergePackageTypeId) => dispatch(Actions.MERGE_WEIGHT(action, customerId, delPackageTypeId, mergePackageTypeId)),
        fetchPackageType: (authToken, customerId) => dispatch(Actions.LOAD_PACKAGE_TYPE(authToken, customerId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuUpdateTabMergePackageType);