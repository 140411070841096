import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    Button,
    Autocomplete,
    Grid,
    TextField,
    Snackbar,
    Alert,
    Box
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Auth } from 'aws-amplify';
import UserList from './list';
import * as Actions from "../actions"

const UserManagement = (props) => {
    const [customers, setCustomers] = useState([]);
    const [locations, setLocations] = useState(props.locations);
    const [indicatorOn, setIndicatorOn] = useState(false);

    useEffect(() => {
        if (props.selectedCustomer) {
            if (props.selectedCustomer.central_customer_id !== -1) {
                setLocations([{ location_name: "Show All", central_location_id: props.selectedLocation.central_location_id ? props.selectedLocation.central_location_id : null }]);
                props.setSelectedLocation({ location_name: "Show All", central_location_id: props.selectedLocation.central_location_id ? props.selectedLocation.central_location_id : null });
                props.fetchLocations(props.selectedCustomer.central_customer_id);
            }
        }
    }, [props.selectedCustomer])
    
    useEffect(() => {
        if (props.selectedLocation && props.selectedCustomer) {
            props.fetchUsers(props.selectedCustomer.central_customer_id, props.selectedLocation.central_location_id);
        }
    }, [props.selectedCustomer, props.selectedLocation])

    useEffect(() => {
        if (props.customers.length > 0) {
            setCustomers([...props.customers, { customer_name: "Show All", central_customer_id: null }, { customer_name: "Show All Rehrig User", central_customer_id: "-1" }]);
            props.setSelectedLocation({ location_name: "Show All", central_location_id: null });
        }
    }, [props.customers])
    useEffect(() => {
        setLocations([...props.locations, { location_name: "Show All", central_location_id: null }]);
        props.setSelectedLocation({ location_name: "Show All", central_location_id: null });
        if (!locations) {
            setLocations([...props.locations, { location_name: "Show All", central_location_id: props.selectedLocation.central_location_id ? props.selectedLocation.central_location_id : null }]);
        }
    }, [props.locations])

    useEffect(() => {
        props.fetchCustomers();
        props.fetchRoles();
        props.fetchApplications();
    }, []);

    useEffect(() => {
        if(!customers){
            setIndicatorOn(true);
        }
    }, [customers])

    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                        sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }
            <div>
                <h3 style={{ float: 'left' }}>User Management</h3>
            </div>
            <div>
                <Grid container spacing={2} style={{ marginBottom: '16px' }}>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            id="customer"
                            options={customers}
                            getOptionLabel={(option) => option.customer_name}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Customer" />}
                            onChange={(event, option) => {
                                props.setSelectedCustomer(option)
                                if (option.central_customer_id !== -1) {
                                    props.setSelectedLocation({ location_name: "Show All", central_location_id: props.selectedLocation.central_location_id });
                                }
                            }}
                            value={props.selectedCustomer}
                        />
                    </Grid>
                    {/* Rehrig Users are considered as central customer id as -1 in UI */}
                    <Grid item xs={4}>
                        {props.selectedCustomer && <Autocomplete
                            disablePortal
                            id="location"
                            options={locations ? locations : []}
                            getOptionLabel={(option) => option.location_name}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Location" />}
                            onChange={(event, location) => { props.setSelectedLocation(location) }}
                            value={props.selectedLocation}
                        />
                        }
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                </Grid>
                <div>
                    {props.selectedLocation && props.selectedCustomer && <UserList />}
                </div>
            </div>
            <Snackbar open={props.alertNotification.show} autoHideDuration={5000} onClose={props.hideNotification}>
                <Alert severity={props.alertNotification.type} sx={{ width: '100%' }}>
                    {props.alertNotification.message}
                </Alert>
            </Snackbar>
        </div>

    );
}
const mapStateToProps = (state) => {
    return {
        customers: state.customers,
        selectedCustomer: state.selectedCustomer,
        locations: state.locations,
        selectedLocation: state.selectedLocation,
        alertNotification: state.alertNotification
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomers: () => dispatch(Actions.LOAD_CUSTOMERS()),
        fetchRoles: () => dispatch(Actions.LOAD_ROLES()),
        fetchApplications: () => dispatch(Actions.LOAD_APPLICATIONS()),
        fetchLocations: (customerId) => dispatch(Actions.LOAD_LOCATIONS(customerId)),
        setSelectedCustomer: (customer) => dispatch(Actions.SET_SELECTED_CUSTOMER(customer)),
        setSelectedLocation: (location) => dispatch(Actions.SET_SELECTED_LOCATION(location)),
        fetchUsers: (customerId, locationId) => dispatch(Actions.LOAD_USERS(customerId, locationId)),
        hideNotification: () => {
            dispatch(Actions.SHOW_NOTIFICATION({ type: "", message: "", show: false }))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
