import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { weight_verified_scale_values } from '../utils.js';
import {Checkbox} from "@mui/material";
import moment from "moment";


let checkOption = "Search SKU";
let customerName = "";
let customerId = "";
let upcNumber = "";
let vorRefNumber = "";
let skuDescption = "";
let skuDescriptionId = "";
let submitButtonFlag = false;
let ignoreStatus = false;
let dataLoading = false;
let searchClick = false;
const SkuUpdateTabAttribute = ({user, ...props}) => {
    const [brands, setBrands] = useState(props.brands);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [selectedBrandId, setSelectedBrandId] = useState('');
    const [packageType, setPackageType] = useState(props.packageType);
    const [allskus, setAllskus] = useState(props.skusbydesc);
    const [selectedPackageType, setSelectedPackageType] = useState('');
    const [selectedPackageTypeId, setSelectedPackageTypeId] = useState();
    const [referenceNumber, setReferenceNumber] = useState(vorRefNumber);
    const [searchSKU, setSearchSKU] = useState(skuDescption);
    const [selectedrefrence, setSelectedrefrence] = useState(skuDescriptionId);
    const [upc, setUpc] = useState(upcNumber);
    const [formUPC, setFormUPC] = useState(undefined);
    const [weightVerifiedValues, setWeightVerifiedValues] = useState(weight_verified_scale_values);
    const [weight, setWeight] = useState();
    const [height, setHeight] = useState();
    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [ignoreSKU,setIgnoreSKU] = useState(ignoreStatus);
    const [ignoreSKULUD,setIgnoreSKULPD] = useState("")
    const [unitsPerCase, setUnitsPerCase] = useState();
    const [skuPackagesPerCase, setSkuPackagesPerCase] = useState();
    const [skuAttributeType, setSkuAttributeType] = useState(checkOption);
    const [customersVorList, setCustomersVorList] = useState(props.customersVorList);
    const [skubyref, setSkubyref] = useState(props.skubyrefrence);

    const [selectedCustomer, setSelectedCustomer] = useState(customerName);
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState(customerId);
    const [weightVerified, setWeightVerified] = useState();
    const [selectedWeightVerified, setSelectedWeightVerified] = useState('');
    const [dateweightverifiedCopy, setDateweightverifiedCopy] = useState('');
    const [dateWeightVerified, setDateWeightVerified] = useState(dayjs());
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const [disable, setDisable] = useState(true);
    const [success, setSuccess] = useState(props.updateweightstatus);
    const [error, setError] = useState(props.error);


    let enable = false;
    let error1 = false;

    useEffect(() => {

       // clearAllVal();
        if(!searchClick){
           // clearFormData();
        }
        if(!submitButtonFlag){
            setCustomersVorList(props.customersVorList);
            props.onErrorUpdate();
            let jwt = localStorage.getItem('authToken');
            props.fetchBrands(jwt, null);
            props.fetchPackageType(jwt, null);
            props.fetchSkusByDesc(jwt, null);
        }

        return () => {
            dataLoading = false;
            if(!searchClick){
                //clearFormData();
            }
            //clearFormData();
        }

    }, []);

    useEffect(() => {
        setCustomersVorList(props.customersVorList);
    }, [props.customersVorList, selectedCustomer]);

    useEffect(() => {
        setTimeout(()=>{
            setIgnoreSKU(ignoreStatus);
        },1000);

        if(!submitButtonFlag) {
            setIgnoreSKU(ignoreStatus);
            setBrands(props.brands);
            setPackageType(props.packageType);
            setAllskus(props.skusbydesc);
            if (!selectedBrandId) {
                setSelectedBrandId();
            }
            if (!selectedPackageTypeId) {
                setSelectedPackageTypeId()
            }
        }

    }, [props.brands, props.packageType, props.skusbydesc, selectedBrandId, selectedPackageTypeId,]);

    useEffect(() => {
        setIgnoreSKU(ignoreStatus);
        if (!customersVorList || !props.skusbydesc) {
            setIndicatorOn(true);
        } else if (props.skubyrefrence) {
            setIndicatorOn(false);
        } else {
            setIndicatorOn(false);
        }
    }, [customersVorList, props.skusbydesc, props.skubyrefrence])

    useEffect(() => {
        if(searchClick){

            if (selectedrefrence && checkOption == "Search SKU") {
                console.log(error);
                if(submitButtonFlag && !error){
                    props.fetchSkuByRefrence(checkOption,selectedrefrence,'',selectedCustomerId);
                }else if(!submitButtonFlag){
                    props.fetchSkuByRefrence(checkOption,selectedrefrence,'',selectedCustomerId);
                }
            }else if(checkOption == "reference"){
                if(submitButtonFlag && !error){
                    props.fetchSkuByRefrence(checkOption,vorRefNumber,upcNumber,selectedCustomerId);
                }
            }else if(checkOption == "upc"){
                if(submitButtonFlag && !error){
                    props.fetchSkuByRefrence(checkOption,vorRefNumber,upcNumber,selectedCustomerId);
                }
            }
        }

    }, [selectedrefrence])

    useEffect(() => {
        try{

            if ((props.skubyrefrence) && ((checkOption == "Search SKU") || (checkOption == "reference" && props.skubyrefrence.vor_reference_number == vorRefNumber) || (checkOption == "upc" && props.skubyrefrence.upc == upcNumber))) {
                clearFormFields();
               // searchClick = false;
                setSkubyref(props.skubyrefrence);
                setSelectedBrandId(props.skubyrefrence.brand_id);
                if (props.skubyrefrence.brand_id) {
                    let brandname = props.brands.map(b => b).filter((brand) => {
                        if (brand.brand_id === props.skubyrefrence.brand_id) {
                            return brand;
                        }
                    });
                    if(brandname && brandname.length>0){
                        setSelectedBrand({ brand_id: brandname[0].brand_id, brand_name: brandname[0].brand_name });
                    }

                }
                ignoreStatus = (props.skubyrefrence.ignoreUpc?true:false);
                setIgnoreSKU(props.skubyrefrence.ignoreUpc?true:false);
                setIgnoreSKULPD(props.skubyrefrence.ignoreLUP);
                setSelectedPackageTypeId(props.skubyrefrence.package_type_id);
                setSelectedPackageType({ package_type_id: props.skubyrefrence.package_type_id, package_name: props.skubyrefrence.package_category_name });
                setWeight(props.skubyrefrence.weight);
                setLength(props.skubyrefrence.length);
                setHeight(props.skubyrefrence.height);
                setWidth(props.skubyrefrence.width);
                setUnitsPerCase(props.skubyrefrence.units_per_case);
                setSkuPackagesPerCase(props.skubyrefrence.sku_packages_per_case);
                if (props.skubyrefrence.is_weight_verified_on_scale === false) {
                    setWeightVerified({ name: props.skubyrefrence.is_weight_verified_on_scale })
                    setSelectedWeightVerified(0);
                } else if (props.skubyrefrence.is_weight_verified_on_scale === true) {
                    setWeightVerified({ name: props.skubyrefrence.is_weight_verified_on_scale })
                    setSelectedWeightVerified(1);
                } else { setWeightVerified() }
                setReferenceNumber(props.skubyrefrence.vor_reference_number);
                setUpc(props.skubyrefrence.upc);
                upcNumber = props.skubyrefrence.upc;
                vorRefNumber = props.skubyrefrence.vor_reference_number;
                setFormUPC(props.skubyrefrence.upc);
                if (props.skubyrefrence.date_weight_was_verified) {
                    let year = props.skubyrefrence.date_weight_was_verified.slice(0, 4);
                    let month = props.skubyrefrence.date_weight_was_verified.slice(5, 7);
                    let day = props.skubyrefrence.date_weight_was_verified.slice(8, 10);

                    let selectedDate = `${month}-${day}-${year}`;
                    setDateweightverifiedCopy(selectedDate);
                }

            }
        }catch (ex){
            console.log("Error:"+ex.message);
        }

    }, [props.skubyrefrence])

    useEffect(() => {
        if (!weightVerified) {
            setSelectedWeightVerified()
            setWeightVerified()
        }
        if (!dateweightverifiedCopy === "") {
            setDateweightverifiedCopy()
        }
        if (!dateWeightVerified) {
            setDateWeightVerified();
        }
        if (!length || length === "") {
            setLength();
        }
        if (!weight || weight === "") {
            setWeight();
        }
        if (!height || height === "") {
            setHeight();
        }
        if (!width || width === "") {
            setWidth();
        }
        if (!unitsPerCase || unitsPerCase === "") {
            setUnitsPerCase();
        }
        if (!skuPackagesPerCase || skuPackagesPerCase === "") {
            setSkuPackagesPerCase();
        }
    }, [weightVerified, dateWeightVerified, length, weight, height, width])

    const handleBrandChange = (event) => {
        setSelectedBrand(event);
        setSelectedBrandId(event.brand_id);
    }
    const handlePackageTypeChange = (event) => {
        setSelectedPackageType(event);
        setSelectedPackageTypeId(event.package_type_id);
    }
    const handlesearchSKUChange = (event) => {
        searchClick = true;
        setSearchSKU(event.target.value);
        setListOpen(true);
    }
    const handleReferenceChange = (event) => {
        searchClick = true;
        vorRefNumber = event.target.value;
        setReferenceNumber(vorRefNumber);
    }
    const handleUpcChange = (event) => {
        upcNumber = event.target.value;
        setUpc(upcNumber);
    }
    const handleFormUpcChange = (event) => {
        setFormUPC(event.target.value);
    }
    const handleWeightChange = (event) => {
        setWeight(event.target.value);
    }
    const handleHeightChange = (event) => {
        setHeight(event.target.value);
    }
    const handleLengthChange = (event) => {
        setLength(event.target.value);
    }
    const handleWidthChange = (event) => {
        setWidth(event.target.value);
    }
    const handleSkuAttributeTypeChange = (event) => {
        checkOption = event.target.value;
        setSkuAttributeType(checkOption);
        setSkubyref('');
        setListOpen(false);
        setSearchSKU();
        setDisable(true);
        ignoreStatus = false;
        setIgnoreSKU(ignoreStatus);
        setIgnoreSKULPD("");
        upcNumber = "";
        vorRefNumber = "";
        clearAllVal();
    };
    let clearFormData = ()=>{
        checkOption = "Search SKU";
        setSkuAttributeType(checkOption);
        setSelectedCustomer("");
        setSelectedCustomerName("");
        setSkubyref('');
        setListOpen(false);
        setSearchSKU("");
        setDisable(true);
        ignoreStatus = false;
        setIgnoreSKU(ignoreStatus);
        setIgnoreSKULPD("");
        upcNumber = "";
        vorRefNumber = "";
        setUpc("");
        setReferenceNumber("")
        clearAllVal();
    }
    const handleUnitsPerCaseChange = (event) => {
        setUnitsPerCase(event.target.value);
    };
    const handleSkuPackagesPerCaseChange = (event) => {
        setSkuPackagesPerCase(event.target.value);
    };
    const clearAllVal = () => {
        setSelectedBrandId();
        setSelectedBrand()
        setSelectedPackageTypeId();
        setSelectedPackageType();
        setWeight();
        setLength();
        setHeight();
        setWidth();
        setUnitsPerCase();
        setSkuPackagesPerCase();
        setWeightVerified();
        setDateWeightVerified();
        setReferenceNumber("");
        setUpc("");
        setFormUPC("")

    }

    let clearFormFields = ()=>{
        setSelectedBrandId();
        setSelectedBrand()
        setSelectedPackageTypeId();
        setSelectedPackageType();
        setWeight();
        setLength();
        setHeight();
        setWidth();
        setUnitsPerCase();
        setSkuPackagesPerCase();
        setWeightVerified();
        setDateWeightVerified();
        setFormUPC()
        ignoreStatus = false;
        setIgnoreSKU(false);
    }

    useEffect(() => {
        if (dateWeightVerified) {
            setDateweightverifiedCopy()
        } else {
            setDateWeightVerified();
        }
    }, [dateWeightVerified])

    const defaultPropsBrand = {
        options: brands,
        getOptionLabel: (option) => option.brand_name,
    };
    const defaultPropsPackageType = {
        options: packageType,
        getOptionLabel: (option) => option.package_name,
    };
    const defaultweightVerifiedScale = {
        options: weightVerifiedValues,
        getOptionLabel: (option) => option.name,
    };

    const handleVorCustomerChange = (event) => {
        customerName = event.target.value;
        customerId = event.target.value.customer_id;
        setSelectedCustomer(customerName);
        setSelectedCustomerId(customerId);
        skuDescption = "";
        skuDescriptionId = "";
        setSearchSKU("");
        ignoreStatus = false;
        setIgnoreSKU(false);
        setIgnoreSKULPD("");
        searchClick = true;
        let jwt = localStorage.getItem('authToken');
        props.fetchBrands(jwt, event.target.value.customer_id);
        props.fetchPackageType(jwt, event.target.value.customer_id);
        props.fetchSkusByDesc(jwt, event.target.value.customer_id);
    }
    const handleWeightVerifiedChange = (event) => {
        setWeightVerified(event);
        if (event.name === 'false') {
            setSelectedWeightVerified(0);
        } else if (event.name === 'true') {
            setSelectedWeightVerified(1);
        } else {
            setSelectedWeightVerified();
        }
    }

    const handleSKUvalue = (event) => {
        //clearAllVal();
        clearFormFields();
        submitButtonFlag = false;
        skuDescption = event.currentTarget.textContent;
        skuDescriptionId = event.currentTarget.id;
        setListOpen(false);
        setSearchSKU("");
        setSelectedrefrence("");

        setTimeout(()=>{
            setSearchSKU(skuDescption);
            setSelectedrefrence(skuDescriptionId);
        },1000);

    }

    const closeList = () => {
        setListOpen(false);
    }
    const SearchSkus = () => {
        setIndicatorOn(true)
       // console.log(checkOption);
        submitButtonFlag = false;
        clearFormFields();
        searchClick = true;
        props.fetchSkuByRefrence(checkOption,referenceNumber, upc,selectedCustomerId);
    }

    useEffect(() => {
        if (props.updateweightstatus) {
            setSuccess(props.updateweightstatus);
        }
        if (props.error) {
            //searchClick = false;
            setError(props.error)
        }
        if (error !== null) {
            setIndicatorOn(false)
            callReUpdate();
        }
        if (success === 'Successfully updated vor_sku table') {
            setIndicatorOn(false);
            callReUpdate();
        }
    }, [props.error, props.updateweightstatus, error, success])

    const callReUpdate = () => {
        setTimeout(() => {
            setError(null);
            setSuccess(null);
        }, 1500);
    }

    const editDetails = () => {
        try{
            if (disable) {
                setDisable(false);
            } else {
                submitButtonFlag = true;
                dataLoading = true;
                searchClick = true;
                console.log(selectedCustomer,selectedCustomerId);
                setIndicatorOn(true);
                let selectedDate = dateWeightVerified ? dateWeightVerified.format("MM-DD-YYYY") : undefined;
                props.updateWeight("update", formUPC, referenceNumber, weight, height, length, width, selectedPackageTypeId, unitsPerCase, selectedBrandId, skuPackagesPerCase, selectedWeightVerified, dateweightverifiedCopy, selectedDate,selectedCustomerId,ignoreSKU);
            }
        }catch (ex){
            console.log("Error:"+ex.message);
        }
    }

    const handleTextDateWeightverified = () => {
        setDateweightverifiedCopy();
    }

    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                         sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }

            <FormControl sx={{ marginTop: '20px' }}>
                <FormLabel id="demo-row-radio-buttons-group-label">Choose 1 type</FormLabel>
                <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={skuAttributeType}
                    onChange={handleSkuAttributeTypeChange}
                >
                    <FormControlLabel value="Search SKU" control={<Radio />} label="Search with the SKU Description" />
                    <FormControlLabel value="reference" control={<Radio />} label="VOR Reference Number" />
                    <FormControlLabel value="upc" control={<Radio />} label="UPC" />
                </RadioGroup>
            </FormControl>
            <Box
                sx={{
                    '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '20px', marginRight: '20px' },
                }}
                noValidate
            >
                {customersVorList && <FormControl sx={{ width: '300px', boxShadow: 'none', marginTop: '30px', marginRight: '20px' }}>
                    <InputLabel id="demo-multiple-name-label">Customers</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="Customers"
                        value={selectedCustomer || 'Select All'}
                        onChange={handleVorCustomerChange}
                        sx={{ textAlign: 'left' }}>
                        <MenuItem key={0} value={'Select All'}>
                            Select All
                        </MenuItem>
                        {customersVorList.map((item) => (
                            <MenuItem key={item.customer_id} value={item}>
                                {item.customer_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </Box>
            {
                customersVorList &&
                <Box
                    sx={{
                        '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '20px', marginRight: '20px' },
                    }}
                    noValidate
                >
                    {skuAttributeType === 'Search SKU' && <TextField
                        id="outlined-name"
                        label="Search with the SKU Description"
                        value={searchSKU}
                        onChange={handlesearchSKUChange}
                        error={error1}
                        autoComplete={"off"}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {skuAttributeType === 'reference' && <TextField
                        id="outlined-name"
                        label="VOR Reference Number*"
                        value={referenceNumber ? referenceNumber : ''}
                        onChange={handleReferenceChange}
                        error={error1}
                        autoComplete={"off"}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {skuAttributeType === 'upc' && <TextField
                        id="outlined-name"
                        label="UPC*"
                        value={upc || ''}
                        onChange={handleUpcChange}
                        error={error1}
                        autoComplete={"off"}
                        helperText={error1 && "Hint: Enter your text here"}
                        style={{ backgroundColor: '#fff' }}
                    />}
                    {(listOpen && skuAttributeType === 'Search SKU') ?
                        <>
                            <IconButton aria-label="cancel" color="default"
                                sx={{ position: 'absolute', margin: '65px 0px 0px -30px' }}
                                onClick={closeList}
                            >
                                <CancelPresentationIcon />
                            </IconButton>
                            <List
                                sx={{
                                    position: 'absolute', zIndex: '1030', background: '#fff',
                                    width: '300px', height: '350px', overflowY: 'scroll', border: '1px solid #ccc'
                                }}>

                                {allskus &&
                                    allskus
                                        .filter(item => item.long_description.toLowerCase().includes(searchSKU.toLowerCase()))
                                        .map((skuItem, i) => {
                                            return <ListItem key={i}
                                                id={skuItem.vor_reference_number}
                                                sx={{ fontSize: '13px', cursor: 'pointer' }}
                                                onClick={handleSKUvalue}

                                            >{skuItem.long_description}
                                            </ListItem>
                                        })
                                }
                            </List>
                        </> : ''}
                    {((referenceNumber && skuAttributeType === 'reference') || (upc && skuAttributeType === 'upc')) ?
                        <Button variant="contained"
                            sx={{ marginTop: '30px' }}
                            onClick={SearchSkus}
                        >
                            Search Sku
                        </Button> : ''
                    }
                </Box>
            }

            <div style={{ paddingTop: '40px', width: '100%', display: 'block' }}>

                {brands && <Autocomplete
                    {...defaultPropsBrand}
                    //getOptionSelected={(option, value) => option.id === value.id}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    value={selectedBrand || null}
                    disabled={(upc || disable)  ? disable : enable}
                    onChange={(e, v) => handleBrandChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Brand" variant="standard" />
                    )}
                />}

                {packageType && <Autocomplete
                    {...defaultPropsPackageType}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    disabled={(upc || disable) ? disable : enable}
                    value={selectedPackageType || null}
                    onChange={(e, v) => handlePackageTypeChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Package Type" variant="standard" />
                    )}
                />}
            </div>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { width: '300px', boxShadow: 'none', marginTop: '30px', marginRight: '20px' },
                }}
                noValidate
            >
                <TextField
                    id="outlined-name"
                    label="Weight"
                    value={weight || ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleWeightChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                />
                <TextField
                    id="outlined-name"
                    label="Length"
                    value={length || ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleLengthChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Width"
                    value={width || ''}
                    onChange={handleWidthChange}
                    disabled={(upc || disable) ? disable : enable}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Height"
                    value={height || ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleHeightChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="Units Per Case"
                    value={unitsPerCase ? unitsPerCase : ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleUnitsPerCaseChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                />
                <TextField
                    id="outlined-name"
                    label="SKU Packages Per Case"
                    value={skuPackagesPerCase ? skuPackagesPerCase : ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleSkuPackagesPerCaseChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                />
                {weightVerifiedValues && <Autocomplete
                    {...defaultweightVerifiedScale}
                    id="auto-complete"
                    autoComplete
                    includeInputInList
                    value={weightVerified ? weightVerified : null}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={(e, v) => handleWeightVerifiedChange(v)}
                    sx={{ float: 'left', width: 300, marginRight: '20px' }}
                    renderInput={(params) => (
                        <TextField {...params} label="Weight verified on scale" variant="standard" />
                    )}
                />}
                {dateweightverifiedCopy ?
                    <TextField
                        id="outlined-name"
                        label="Date weight verified"
                        value={dateweightverifiedCopy || ''}
                        disabled={(upc || disable) ? disable : enable}
                        onClick={handleTextDateWeightverified}
                        helperText={"Hint: MM-DD-YYYY"}
                        style={{ backgroundColor: '#fff' }}
                    /> : ''}
                {!dateweightverifiedCopy ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                            <DatePicker label="Date weight verified"
                                value={dateWeightVerified}
                                disabled={(upc || disable) ? disable : enable}
                                onChange={(newValue) => setDateWeightVerified(newValue)}
                                format="MM-DD-YYYY"
                            />
                        </DemoContainer>
                    </LocalizationProvider> : ''
                }

                {(skuAttributeType === 'reference' || skuAttributeType === 'Search SKU') ? <TextField
                    id="outlined-name"
                    label="UPC"
                    value={formUPC || ''}
                    disabled={(upc || disable) ? disable : enable}
                    onChange={handleFormUpcChange}
                    error={error1}
                    helperText={error1 && "Hint: Enter your text here"}
                    style={{ backgroundColor: '#fff' }}
                /> : ''}
                <div style={{display:(selectedCustomerId?"inline-table":"none"),paddingTop:"20px"}}>
                    <FormControlLabel control={<Checkbox checked={ignoreSKU} onChange={(event)=>{
                        console.log(event.target.checked);
                        ignoreStatus = event.target.checked;
                        setIgnoreSKU(event.target.checked);
                    }}   />}  disabled={(upc || disable) ? disable : enable} label="Ignore SKU"  /> <br/>
                    <label style={{color:'#a29b9b',fontSize:"13px"}}>Note: Ignore selection last updated {moment(ignoreSKULUD?ignoreSKULUD:new Date()).format("MM/DD/YYYY")}</label>
                </div>




            </Box>
            <Box sx={{ display: 'flex' }}>
                {(skubyref && user.canWrite) ?
                    <div style={{ paddingTop: '30px' }}>
                        <Button variant="contained"
                            onClick={editDetails}
                            disabled={false}
                            sx={{ width: '200px' }}>{disable ? 'Edit' : 'Submit'}</Button>
                    </div> : ''}
            </Box>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        brands: state.skuUpdate.brands,
        packageType: state.skuUpdate.packageType,
        skusbydesc: state.skuUpdate.skusbydesc,
        skubyrefrence: state.skuUpdate.skubyrefrence,
        error: state.skuUpdate.error,
        updateweightstatus: state.skuUpdate.updateweightstatus,
        user: state.application.user

    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateWeight: (action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate,customerId,ignoreSKU) => dispatch(Actions.UPDATE_WEIGHT(action, upc, reference, weight, height, length, width, packageTypeId, unitsPerCase, brandId, skuPackagesPerCase, isWeightVerified, dateweightverifiedCopy, selectedDate,customerId,ignoreSKU)),
        fetchBrands: (authToken, customerId) => dispatch(Actions.LOAD_BRANDS(authToken, customerId)),
        fetchPackageType: (authToken, customerId) => dispatch(Actions.LOAD_PACKAGE_TYPE(authToken, customerId)),
        fetchSkusByDesc: (authToken, customerId) => dispatch(Actions.LOAD_SKUSBY_DESC(authToken, customerId)),
        fetchSkuByRefrence: (searchOption,referenceNumber, upc,customerId) => dispatch(Actions.LOAD_SKUSBY_REFRENCE(searchOption,referenceNumber, upc,customerId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SkuUpdateTabAttribute);
