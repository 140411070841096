import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    error: null,
    errorModal: null,
    customers: null,
    showProgress: true,
    displayError: false,
    siteHealthData: null,
    siteHealthError: null,
    LoadTypeError: null,
    healthMetricsURL: null,
}

const siteHelathMetricsReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.LOAD_CUSTOMERS_WITH_DC_SUCCESS:
            newState.customers = action.payload.customers.data.Data;
            break;
        case ActionTypes.LOAD_VOR_APPLICATIONS_FAILURE_TYPE:
            newState.LoadTypeError = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_SITE_HEALTH_METRICS_WITH_SUCCESS:
            if (action.payload.data.Data != undefined) {
                newState.siteHealthData = action.payload.data.Data;
                newState.siteHealthError = null;
                newState.displayError = false;
            } else {
                newState.siteHealthData = undefined
                newState.siteHealthError = "No data available";
                newState.displayError = false;
            }
            break;
        case ActionTypes.LOAD_SITE_HEALTH_METRICS_WITH_ERROR:
            newState.siteHealthError = errorDescription(action.payload.error);
            newState.siteHealthData = null;
            newState.displayError = true;
            break;
        case ActionTypes.LOAD_EMBEDDED_URL_SUCCESS:
            newState.healthMetricsURL = action.payload['embed-url']
            break;
        case ActionTypes.LOAD_EMBEDDED_URL_FAILURE:
            newState.siteHealthError = errorDescription(action.payload.error);
            newState.siteHealthData = null;
            newState.displayError = true;
            break;
        default:
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default siteHelathMetricsReducer;