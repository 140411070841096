import UserManagementView from "./components"

import React from 'react'
import {Provider} from 'react-redux'
import reducer from './store';
import { EffectListener } from './effects';
import createSagaMiddleware from 'redux-saga'
import {createStore, applyMiddleware} from 'redux'

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(EffectListener);
const UserManagement = (props)=>{
    return (<Provider store={store}>
    <UserManagementView />
  </Provider>);
}

export default UserManagement;