import * as ActionTypes from '../actions/actiontypes';

export const initialState = {
    alertNotification:{
        show:false,
        type:"success",
        message:""
    },
    user:{
        centralRoleId:"",
        centralUserId:"",
        userName:"",
        canRead:false,
        canWrite:false
    }
}
const ApplicationReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case ActionTypes.SHOW_NOTIFICATION:
            newState.alertNotification = action.payload.notification;
            break;  
        case ActionTypes.SET_USER_INFO:
            let userInfo = action.payload.userInfo;
            if(userInfo!=null){
                newState.user = {
                    centralRoleId: userInfo.attributes["custom:central_role_id"],
                    centralUserId: userInfo.attributes["custom:central_user_id"],
                    userName: userInfo.username,
                    canRead:(userInfo.attributes["custom:central_role_id"] ==="1" || (userInfo.attributes["custom:central_role_id"] ==="2")),
                    canWrite: (userInfo.attributes["custom:central_role_id"] ==="1")
                }
            }else{
                newState.user = {
                    centralRoleId:"",
                    centralUserId:"",
                    userName:"",
                    canRead:false,
                    canWrite:false
                }
            }
            break;     
    }
    return newState;
}
export default ApplicationReducer;