import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions'
import Button from '@mui/material/Button';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustomerManagementGridPanel from '../CustomerManagementGridPanel/CustomerManagementGridPanel';
import CustomerManagementAddEditModal from '../CustomerManagementAddEditModal/CustomerManagementAddEditModal';
import { Auth } from 'aws-amplify';
import { Height } from '@mui/icons-material';

const CustomerManagement = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState(props.customersList);
    const [applicationsList, setApplicationsList] = useState(props.applicationsList);
    const [showModal, setShowModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [loading, setLoading] = useState(false);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchApplications(jwt);
            props.fetchCustomers(jwt);
        })
    }, []);

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add Customer');

        } else {
            setModalTitle('Edit Customer');
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType]);

    useEffect(() => {
        setCustomersList(props.customersList);
        setApplicationsList(props.applicationsList);
    }, [props.customersList, props.applicationsList]);

    useEffect(() => {
        if (!customersList) {
            setIndicatorOn(true);
        } else { setIndicatorOn(false); }
    }, [customersList])

    const handleAddCustomerTapped = () => {
        props.showaddCustomerModal("Add", true);
        setShowModal(true);
    }
    const handleEditCustomerTapped = () => {
        props.showaddCustomerModal("Edit", true);
    }
    const callUpdatedCustomerList = () => {
        setLoading(false);
        let authToken = localStorage.getItem('authToken');
        props.fetchCustomers(authToken);
        setCustomersList(props.customersList);
    }

    return (
        <div style={{ width: '100%' }}>
            <div>
                <h3>Customer Management</h3>
                {user.canWrite && <Toolbar sx={{ justifyContent: "flex-end", minHeight: "30px !important" }}>
                    <Button variant="outlined" onClick={handleAddCustomerTapped} >Add Customer</Button>
                </Toolbar>}
                {/* {!customersList && <Alert severity="error" sx={{margin: '20px'}}>Sorry! Unable to load the customers list!!</Alert>} */}
                {indicatorOn ?
                    <div>
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={indicatorOn}
                        >
                            <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                        </Backdrop>
                    </div> : ''
                }
                {customersList && applicationsList && <CustomerManagementGridPanel customersList={customersList} applicationsList={applicationsList}></CustomerManagementGridPanel>}
                <CustomerManagementAddEditModal showModal={showModal} modalTitle={modalTitle} editModal={handleEditCustomerTapped} applicationsList={applicationsList} callUpdatedCustomerList={callUpdatedCustomerList} loading={loading} setLoading={setLoading}></CustomerManagementAddEditModal>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customersList: state.customerManagement.customersData,
        applicationsList: state.customerManagement.applicationsList,
        showModal: state.customerManagement.showCustomerModal,
        showModalType: state.customerManagement.showModalType,
        selectedData: state.customerManagement.selectedData,
        user: state.application.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchApplications: (authToken) => dispatch(Actions.LOAD_APPLICATIONS(authToken)),
        fetchCustomers: (authToken) => dispatch(Actions.LOAD_CUSTOMERS(authToken)),
        showaddCustomerModal: (type, show) => dispatch(Actions.CUSTOMER_ADD_EDIT_MODAL_UPDATE(type, show))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagement);
