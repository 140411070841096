import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const DashBoardGridPanel = ({ user, ...props }) => {
    const [dailyData, setDailyData] = useState(props.dailyAnalysisData);
    const [customers, setCustomers] = useState();
    const [tranDate, setTranDate] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [modalData, setOpenModalData] = useState([]);


    useEffect(() => {
        if (props.dailyAnalysisData) {
            setDailyData(props.dailyAnalysisData);
            setCustomers(props.dailyAnalysisData.customers)
            setTranDate(props.dailyAnalysisData.date);
        }

    }, [props.dailyAnalysisData, customers]);

    let formatedCustomers = customers && customers.filter(item => {
        let customerName = item.customer_name !== null ? item.customer_name : '';
        item.dcs.forEach(el => {
            el.customer_name = customerName;
            return el;
        });

        return item;
    })
    let grouped = formatedCustomers && formatedCustomers.flatMap(obj => {
        return obj.dcs.map(dcs => dcs);
    });
    const columns = [
        { field: 'customer_name', headerName: 'Customer Name', width: 130 },
        { field: 'dc_name', headerName: 'DC Name', width: 130 },
        { field: 'total_pallets', headerName: 'Total Pallets', width: 80 },

        { field: 'un_trained_skus', headerName: 'Has Untrained SKU in Pallet', width: 100 },
        { field: 'un_trained_skus_not_covered', headerName: 'Has Untrained SKU Not Covered in Pallet', width: 130 },


        { field: 'vor_suc_percentage', headerName: 'VOR Success Rate %', width: 80 },
        { field: 'perr_percent', headerName: 'Picker error %', width: 80},
        { field: 'qc_bot_audit_rate_percentage', headerName: 'Pallets sent to QC Bot %', width: 80 },
        { field: 'sent_qc_bot_plts', headerName: 'QC Bot Pallet Count', width: 80 },

        
        { field: 'weight_heuristics_percentage', headerName: 'Weight heuristic(Erasor) %', width: 120 },
        { field: 'weight_heur_plts', headerName: 'Weight heuristic Pallets', width: 100 },

        
        { field: 'succ_weight_heur_plts', headerName: 'Success pallet out of weight tolerence', width: 100 },
        { field: 'vor_verified_percentage', headerName: 'VOR verified %', width: 80 },
        { field: 'picklist_not_processed', headerName: 'Picklist not processed on VOR %', width: 110 },

        { field: 'sys_err_plts', headerName: 'System error pallet count', width: 100, fontWeight: 600 },

        { field: 'bypass_count', headerName: 'BYPASS pallet count', width: 100 },
    ];


    const handleOnCellClick = (params) => {
        if(params.field == "sys_err_plts") {
            if (params.row.sys_err_plts_ids.length > 0 && params.row.sys_err_plts_ids[0] !== "") {
                var dict = []
                var id = 0
                params.row.sys_err_plts_ids.map((sscc) => 
                    dict.push({
                    id:  ++id,
                    sscc: sscc,
                    dc_id: params.row.dc_id
                    })
                )
                setOpenModalData(dict)
                setOpenModal(true)
            }
        } else if(params.field == "sent_qc_bot_plts") {
            if (params.row.sent_to_qb_pl_ids.length > 0 && params.row.sent_to_qb_pl_ids[0] !== "") {
                var dict = []
                var id = 0
                params.row.sent_to_qb_pl_ids.map((sscc) => 
                    dict.push({
                    id:  ++id,
                    sscc: sscc,
                    dc_id: params.row.dc_id
                    })
                )
                setOpenModalData(dict)
                setOpenModal(true)
            }
        } else if(params.field == "weight_heur_plts") {
            if (params.row.weight_heur_plts_ids.length > 0 && params.row.weight_heur_plts_ids[0] !== "") {
                var dict = []
                var id = 0
                params.row.weight_heur_plts_ids.map((sscc) => 
                    dict.push({
                    id:  ++id,
                    sscc: sscc,
                    dc_id: params.row.dc_id
                    })
                )
                setOpenModalData(dict)
                setOpenModal(true)
            }
        } else if(params.field == "un_trained_skus") {
            if (params.row.un_trained_skus_plts_ids.length > 0 && params.row.un_trained_skus_plts_ids[0] !== "") {
                var dict = []
                var id = 0
                params.row.un_trained_skus_plts_ids.map((sscc) => 
                    dict.push({
                    id:  ++id,
                    sscc: sscc,
                    dc_id: params.row.dc_id
                    })
                )
                setOpenModalData(dict)
                setOpenModal(true)
            }
        } else if(params.field == "un_trained_skus_not_covered") {
            if (params.row.un_trained_skus_not_covered_plts_ids.length > 0 && params.row.un_trained_skus_not_covered_plts_ids[0] !== "") {
                var dict = []
                var id = 0
                params.row.un_trained_skus_not_covered_plts_ids.map((sscc) => 
                    dict.push({
                    id:  ++id,
                    sscc: sscc,
                    dc_id: params.row.dc_id
                    })
                )
                setOpenModalData(dict)
                setOpenModal(true)
            }
        }
    };

    const handleModalCellClick = (params) => {
        const region = process.env.REACT_APP_REDIRECT_URI;
        let qcBotURL = ''
        if (region.includes("dev")) {
            qcBotURL= `https://qcbot-dev.rehrig.com/sscc/${params.row.sscc}/${params.row.dc_id}`
        } else if (region.includes("qa")) {
            qcBotURL= `https://qcbot-qa.rehrig.com/sscc/${params.row.sscc}/${params.row.dc_id}`
        } else if (region.includes("rehrigpacific")){
            qcBotURL= `https://qcbot.rehrigpacific.com/sscc/${params.row.sscc}/${params.row.dc_id}`
        }
        window.open(qcBotURL, '_blank', 'noreferrer');
    }

    const handleClose = () => {
        setOpenModalData([])
        setOpenModal(false)
     }
     const handleOpen = () => setOpenModal(true);

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{ paddingTop: '10px' }}> Daily analysis summary : {tranDate ? tranDate : ''}</h3>
                    </div>
                    {grouped ?
                    <div className="duolift-device-detail-card-body">
                        <div style={{ height: '440px', width: '100%' }}>
                        <Box
                            sx={{
                                '& .cold': {
                                fontwieght: 600,
                                textDecoration: 'underline', display:"inline"
                                },
                            }}
                            >
                            <DataGrid
                              sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                  whiteSpace: "normal",
                                  lineHeight: "normal",
                                  fontSize: '13px'
                                },
                                "& .MuiDataGrid-columnHeader": {
                                  height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  maxHeight: "168px !important"
                                }
                              }}
                                rows={grouped ? grouped: ''}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => Math.random()}
                                onCellClick={handleOnCellClick}
                                // getCellClassName={(params) => {
                                //     if (params.field === 'weight_heur_plts' || params.value == null) {
                                //       return 'cold';
                                //     }
                                //     return ''
                                //   }}
                            />
                            </Box>
                        </div>
                    </div>
                    : <p>No data available</p>}
                </div>
                <div>
                <Modal
                    open={openModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Pallet SSCC ID's
                    </Typography>
                    <div style={{ height: '300px' }}>
                            <DataGrid
                              sx={{
                                "& .MuiDataGrid-columnHeaderTitle": {
                                  whiteSpace: "normal",
                                  lineHeight: "normal",
                                  fontSize: '13px'
                                },
                                "& .MuiDataGrid-columnHeader": {
                                  height: "unset !important"
                                },
                                "& .MuiDataGrid-columnHeaders": {
                                  maxHeight: "168px !important"
                                }
                              }}
                              columns={[{ field: 'id', headerName: '', width: 130 },
                              { field: 'sscc', headerName: 'SSCC', width: 200 }]}
                                rows={modalData}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => Math.random()}
                                onCellClick={handleModalCellClick}
                            />
                        </div>
                    </Box>
                </Modal>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.application.user
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        // showAddEditCustomerModal: (type, show, selectedData) => dispatch(Actions.CUSTOMER_ADD_EDIT_MODAL_UPDATE(type, show, selectedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardGridPanel);
