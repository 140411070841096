import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {Button, LinearProgress} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

const CustomerManagementGridPanel = (props) => {
    const theme = useTheme();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [customerShortName, setCustomerShortName] = useState();
    const [activeStatus, setActiveStatus] = useState(true);
    const [applicationsSelected, setApplicationsSelected] = useState([]);
    const [applicationsSelectedId, setApplicationsSelectedId] = useState([]);
    const [applicationsTouched, setApplicationsTouched] = useState(false);
    const [touched, setTouched] = useState(false);

    let error1 = (customerName === "");
    let error2 = (customerShortName === "");
    let error3 = applicationsSelected.length < 1;

    let isFieldInvalid = (!customerName || !customerShortName);

    useEffect(() => {
        if ((error1 || error2 || error3) || isFieldInvalid) {
            setTouched(true);
        } else {
            setTouched(false);
        }
    }, [customerName, customerShortName, applicationsSelected]);

    useEffect(() => {
        let temp = [];
        for (let i in applicationsSelected) {
            let selectedApplication = applicationsSelected[i];
            for (let j in props.applicationsList) {
                if (selectedApplication === props.applicationsList[j].application_long_name) {
                    temp.push(props.applicationsList[j].central_application_id);
                }
            }
        }
        setApplicationsSelectedId(temp);
    }, [applicationsSelected]);

    useEffect(() => {
        let temp = [];
        if (applicationsSelected.length < 1 && (props.selectedData && props.selectedData.applications)) {
            for (let i in props.selectedData.applications) {
                for (let j in props.applicationsList) {
                    if (props.selectedData.applications[i] === props.applicationsList[j].central_application_id) {
                        temp.push(props.applicationsList[j].application_long_name);
                    }
                }
            }
            setApplicationsSelected(temp)
        }
        if (props.selectedData) {
            setCustomerId(props.selectedData.central_customer_id);
            setCustomerName(props.selectedData.customer_name);
            setCustomerShortName(props.selectedData.short_name);
            if (props.selectedData.active === 1) {
                setActiveStatus(true);
            } else {
                setActiveStatus(false);
            }
        }
    }, [props.selectedData])

    const handleClose = () => {
        props.hideCustomerModal(props.showModalType, false);
        setApplicationsSelected([]);
        setApplicationsTouched(false);
        setActiveStatus(true);
        setCustomerName();
        setCustomerShortName();
        setCustomerId();
    }
    const handleCheckBoxChange = (event) => {
        setActiveStatus(event.target.checked)
    }
    const handleCustomerNameChange = (event) => {
        setCustomerName(event.target.value);
    }
    const handleCustomerShortNameChange = (event) => {
        setCustomerShortName(event.target.value.replace(/^\s+|\s+$/g, ""));
    }
    const handleChangeApplications = (event) => {
        setApplicationsTouched(true);
        const {
            target: { value },
        } = event;
        setApplicationsSelected(value);
    }
    const callUpdatedCustomerList = () => {
        handleClose();
        props.callUpdatedCustomerList();
    }
    const submitDetails = () => {
        let status = activeStatus ? 1 : 0;
        let applications = [...applicationsSelectedId];
        if (props.showModalType === 'Add') {
            props.setLoading(true);
            props.addCustomer(customerName, customerShortName, status, applications, callUpdatedCustomerList);
        } else if (props.showModalType === 'Edit') {
            props.setLoading(true);
            props.editCustomer(customerId, customerName, customerShortName, status, applications, callUpdatedCustomerList);
        }
    }
    const getStyles = (name, personName, theme) => {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250
            },
        },
    };
    let applicationsList = [];
    const setApplicationsList = () => {
        let temp = [];
        for (let i in props.applicationsList) {
            temp.push(props.applicationsList[i].application_long_name);
        }
        applicationsList.push(...temp);
    };
    setApplicationsList();

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '500px', minHeight: "500px" } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {props.errorResponse && <Alert severity="error" sx={{ marginBottom: '20px' }}>{props.errorResponse}</Alert>}
                    <div className="statusModalActiveCheckBox">
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={activeStatus} onChange={handleCheckBoxChange} />} label="Active Status" />
                        </FormGroup>
                    </div>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '40ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <TextField
                            id="outlined-name"
                            label="Customer Name*"
                            value={customerName || ''}
                            onChange={handleCustomerNameChange}
                            error={error1}
                            helperText={error1 ? "Please enter a text" : "Hint: Enter your text here"}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Customer Short Name*"
                            value={customerShortName || ''}
                            onChange={handleCustomerShortNameChange}
                            error={error2}
                            disabled={props.showModalType === 'Edit' ? true : false}
                            helperText={error2 ? "Please enter a text" : "Note: Spaces are not allowed, use(_/-) to separate the words"}
                            style={{ backgroundColor: '#fff' }}
                        />
                        {true && <FormControl sx={{ width: '90%', marginTop: '10px' }}>
                            <InputLabel id="demo-multiple-chip-label">Applications*</InputLabel>
                            <Select
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                multiple
                                label="Applications*"
                                value={applicationsSelected}
                                onChange={handleChangeApplications}
                                error={applicationsTouched && error3}
                                input={<OutlinedInput id="select-multiple-chip" label="Applications*" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}>
                                {applicationsList.map((item) => (
                                    <MenuItem
                                        key={item}
                                        value={item}
                                        style={getStyles(item, applicationsSelected, theme)}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        <div style={{ position: 'absolute', right: '15%', bottom: '10%' }}>
                            <Button variant="contained" disabled={touched}
                                onClick={submitDetails}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.customerManagement.showCustomerModal,
        showModalType: state.customerManagement.showModalType,
        selectedData: state.customerManagement.selectedData,
        errorResponse: state.customerManagement.error
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideCustomerModal: (type, show) => dispatch(Actions.CUSTOMER_ADD_EDIT_MODAL_UPDATE(type, show)),
        addCustomer: (customer_name, short_name, status, applications, callUpdatedCustomerList) => dispatch(Actions.CREATE_CUSTOMER(customer_name, short_name, status, applications, callUpdatedCustomerList)),
        editCustomer: (customer_id, customer_name, short_name, status, applications, callUpdatedCustomerList) => dispatch(Actions.EDIT_CUSTOMER(customer_id, customer_name, short_name, status, applications, callUpdatedCustomerList))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerManagementGridPanel);