import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel, LinearProgress } from '@mui/material';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';

const VORConfigurationPalletAddEditModal = (props) => {
    const [dcId, setDcId] = useState(props.dcId);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [palletSchemaID, setPalletSchemaID] = useState(props.pallet_schema_id);
    const [palletType, setPalletType] = useState(props.pallet_type);
    const [palletTypeId, setPalletTypeId] = useState(props.pallet_type_id);
    const [palletSchema, setPalletSchema] = useState(props.pallet_schema);
    const [palletWeight, setPalletWeight] = useState(props.pallet_weight_in_lbs);
    const [isfullPallet, setIsfullPallet] = useState(true);
    const [touched, setTouched] = useState(false);

    useEffect(() => {
        if (props.selectedData) {
            setPalletType(props.selectedData.pallet_type);
            setPalletSchema(props.selectedData.pallet_schema);
            setPalletWeight(props.selectedData.pallet_weight_in_lbs);
            setPalletSchemaID(props.selectedData.pallet_schema_id);
            setIsfullPallet(props.selectedData.is_full_pallet);
        }
    }, [props.selectedData])

    const handleClose = () => {
        props.hideConfigurationModal(props.showModalType, false);
    }
    const handlePalletSchemaChange = (event) => {
        setPalletSchema(event.target.value)
    }
    const handlePalletWeightChange = (event) => {
        setPalletWeight(event.target.value);
    }
    const handlePalletTypeChange = (event) => {
        setPalletType(event.target.value);
    }
    const handleIsfullPalletChange = (event) => {
        setIsfullPallet(event);
    }

    const callUpdatedPalletConfig = () => {
        handleClose();
        props.callUpdatedPalletConfig();
    }
    const submitDetails = () => {
        if (props.showModalType === 'Add') {
            props.setLoading(true);
            props.addConfiguration(customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, callUpdatedPalletConfig);
        } else if (props.showModalType === 'Edit') {
            props.setLoading(true);
            props.editConfiguration(customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID, callUpdatedPalletConfig);
        }
    }

    return (
        <>
            {props.showModal && <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '450px' } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { marginTop: '20px', width: '45ch', boxShadow: 'none' },
                        }}
                        noValidate
                    >
                        <TextField
                            id="outlined-name"
                            label="Pallet Type"
                            value={palletType || ''}
                            onChange={handlePalletTypeChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Pallet Schema"
                            value={palletSchema || ''}
                            onChange={handlePalletSchemaChange}
                            style={{ backgroundColor: '#fff' }}
                        />
                        <TextField
                            id="outlined-name"
                            label="Pallet weight"
                            value={palletWeight || ''}
                            onChange={handlePalletWeightChange}
                            style={{ backgroundColor: '#fff' }}
                        />

                        <FormControl sx={{ float: 'left', width: 400, marginTop: "20px" }}>
                            <InputLabel id="demo-simple-select-label">Is full pallet</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={isfullPallet}
                                label="Is full pallet"
                                onChange={(event) => handleIsfullPalletChange(event.target.value)}
                            >
                                <MenuItem value={true}>true</MenuItem>
                                <MenuItem value={false}>false</MenuItem>
                            </Select>
                        </FormControl>
                        <div style={{ float: 'right', padding: '10px' }}>
                            <Button variant="contained"
                                onClick={submitDetails}
                                disabled={touched}
                            >Save</Button>
                        </div>
                    </Box>
                </DialogContent>
            </Dialog>}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showConfigurationModal,
        showModalType: state.vorConfiguration.showModalType,
        selectedData: state.vorConfiguration.selectedData,
        errorResponse: state.vorConfiguration.errorModal,
        displayError: state.vorConfiguration.displayErroText,
        showProgress: state.vorConfiguration.showProgress

    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        hideConfigurationModal: (type, show) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        addConfiguration: (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, callUpdatedPalletConfig) => dispatch(Actions.CREATE_PALLET_CONFIGURATION(customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, callUpdatedPalletConfig)),
        editConfiguration: (customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID, callUpdatedPalletConfig) => dispatch(Actions.EDIT_PALLET_CONFIGURATION(customerId, dcId, palletType, palletSchema, palletWeight, isfullPallet, palletSchemaID, callUpdatedPalletConfig))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationPalletAddEditModal);