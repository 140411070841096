import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    error: null,
    errorModal: null,
    customers: null,
    edgePCs: null,
    edgePCserror: null,
    edgePCsConfiguration: null,
    edgePCsConfigurationError: null,
    showProgress: true,
    displayErroText: false,
    applicationList: [],
    applicationListError: null,
    deploymentTypeList: [],
    deploymentTypeError: null,
    edgeDeployError:null,
    edgeDeploySuccess:false,
    edgeDisplayError: false,
    edgedeployment: null,
    submitState:false,
    deploymentDetails: null,
    modeSetIdDetails: null,
}

const cicdUIDeployReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.CREATE_EDGE_DEPLOYMENT_WITH_SUCCESS:
            newState.edgedeployment = action.payload.data.Data;
            newState.edgeDeployError = null;
            newState.edgeDeploySuccess = true;
            newState.edgeDisplayError = false;
            newState.error = null
            newState.submitState = false;
            break;
        case ActionTypes.CREATE_EDGE_DEPLOYMENT_WITH_ERROR:
            newState.edgeDeployError = errorDescription(action.error);
            newState.edgeDeploySuccess = false;
            newState.edgeDisplayError = true;
            break;
        case ActionTypes.RESET_CREATE_EDGE_DEPLOY_FLAGS:
            newState.edgeDeployError = null;
            newState.edgeDeploySuccess = true;
            newState.edgeDisplayError = false;
            newState.submitState = false;
            break;
        case ActionTypes.LOAD_DEPLOYMENT_TYPES_WITH_SUCCESS:
            newState.deploymentTypeList = action.payload.data.Data;
            break;
        case ActionTypes.LOAD_DEPLOYMENT_TYPES_WITH_ERROR:
            newState.deploymentTypeError = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_VOR_APPLICATIONS_FAILURE_TYPE:
            newState.deploymentTypeError = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_VOR_APPLICATIONS_SUCCESS_TYPE:
            newState.applicationList = action.payload.data.Data;
            break;
        case ActionTypes.LOAD_VOR_APPLICATIONS_FAILURE_TYPE:
            newState.applicationListError = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_CUSTOMERS_WITH_DC_SUCCESS:
            newState.customers = action.payload.customers.data.Data;
            break;
        case ActionTypes.LOAD_EDGEPCS_WITH_SUCCESS:
            newState.edgePCs = action.payload.edgePCs.data.Data
            break;
        case ActionTypes.LOAD_EDGEPCS_WITH_ERROR:
            newState.edgePCserror = errorDescription(action.payload.error);;
            break;
        case ActionTypes.LOAD_EDGEPCS_TWO_WITH_SUCCESS:
            newState.edgePCsList = action.payload.edgePCs.data.Data
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_SUCCESS:
            newState.edgePCsConfigurationError = null;
            newState.edgePCsConfiguration = action.payload.edgePCsConfiguration.data.Data;
            break;
        case ActionTypes.LOAD_EDGEPCS_CONFIGURATION_WITH_ERROR:
            newState.edgePCsConfigurationError = errorDescription(action.payload.error);
            newState.edgePCsConfiguration = null;
            break;
        case ActionTypes.CREATE_VOR_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.error = null;
            break;
        case ActionTypes.EDIT_VOR_CONFIGURATION_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.EDIT_VOR_CONFIGURATION_SUCCESS:
            newState.showConfigurationModal = false;
            newState.error = null;
            break;
        case ActionTypes.CREATE_VOR_CONFIGURATION_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_DEPLOYMENT_DETAILS_SUCCESS:
           newState.deploymentDetails = action.payload.data.data;
           break;
        case ActionTypes.LOAD_DEPLOYMENT_DETAILS_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.RESET_ERROR:
            newState.error = null
            break;
        case ActionTypes.GET_MODEL_SKU_DATA_SUCCESS:
            newState.modeSetIdDetails = action.payload.data;
            break;
        case ActionTypes.GET_MODEL_SKU_DATA_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        default:
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default cicdUIDeployReducer;