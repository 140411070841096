import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from '../Header/Header';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import { styled, Paper } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import VORConfigurationGridPanel from '../VORConfigurationGridPanel/VORConfigurationGridPanel';
import VORConfigurationAddEditModal from '../VORConfigurationAddEditModal/VORConfigurationAddEditModal';
import VORConfigurationCopyPropertiesModal from '../VORConfigurationCopyPropertiesModal/VORConfigurationCopyPropertiesModal';
import VORConfigurationLVDoorGridPanel from '../VORConfigurationLVDoorGridPanel/VORConfigurationLVDoorGridPanel';
import VORConfigurationCodeDeployGridPanel from '../VORConfigurationCodeDeployGridPanel/VORConfigurationCodeDeployGridPanel';
import VORConfigurationPalletGridPanel from '../VORConfigurationPalletGridPanel/VORConfigurationPalletGridPanel';
import VORNetworkConfigurationAddEditModal from '../VORConfigurationAddEditModal/VORNetworkConfigurationAddEditModal';
import { Auth } from 'aws-amplify';
import "./style.css";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: '20px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

const VORConfiguration = ({ user, ...props }) => {
    const [customersList, setCustomersList] = useState(props.customers);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [applicationName, setApplicationName] = useState('');
    const [applicationId, setApplicationId] = useState('');
    const [selectedAppId, setSelectedAppId] = useState('');
    const [locationsList, setLocationsList] = useState();
    const [edgePCList, setEdgePCList] = useState(props.edgePCs);
    const [edgePCError, setEdgePCError] = useState(props.edgePCserror)
    const [edgePCsConfiguration, setEdgePCsConfiguration] = useState(props.edgePCsConfiguration);
    const [edgePCsConfigurationErr, setEdgePCsConfigurationErr] = useState(null)
    const [selectedLocation, setSelectedLocation] = useState('');
    const [selectedEdgePC, setSelectedEdgePC] = useState('');
    const [selectedEdgePcId, setSelectedEdgePcId] = useState('');
    const [selectedLocationId, setSelectedLocationId] = useState('');
    const [showModal, setShowModal] = useState();
    const [showCopyConfigModal, setShowCopyConfigModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [networkmodalTitle, setNetworkmodalTitle] = useState('');
    const [selectedTab, setSelectedTab] = useState(0);
    const [networkConfiguration, setNetworkConfiguration] = useState(props.networkConfiguration || undefined);
    const [updatedpropertyNames, setUpdatedpropertyNames] = useState();
    const [dayOfTheWeek, setDayOfTheWeek] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [indicatorOn, setIndicatorOn] = useState(false);
    const [loading, setLoading] = useState(false);


    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#fff"
    });

    useEffect(() => {
        Auth.currentSession().then(res => {
            let accessToken = res.getAccessToken();
            let jwt = accessToken.getJwtToken();
            localStorage.setItem('authToken', jwt);
            props.fetchCustomersWithDC();
        })
    }, []);

    useEffect(() => {
        if (!customersList) {
            setIndicatorOn(true)
        } else { setIndicatorOn(false) }
    }, [customersList]);

    useEffect(() => {
        setCustomersList(props.customers);
        if (props.customers) {
            setSelectedCustomer(props.customers[0]);
            setSelectedCustomerId(props.customers[0].customer_id)
            setEdgePCList(props.edgePCs);

            if (props.customers[0].dcs) {
                setLocationsList(props.customers[0].dcs);
                setSelectedLocationId(props.customers[0].dcs.dc_id);
            } else {
                setLocationsList();
                setSelectedLocationId();
            }
        }
    }, [props.customers]);

    useEffect(() => {
        if (!locationsList) {
            setLocationsList([]);
        }
    }, [locationsList])

    useEffect(() => {
        setEdgePCList(props.edgePCs);
    }, [props.edgePCs]);

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add Configuration');
            setUpdatedpropertyNames(props.propertyNames);
        } else {
            setModalTitle('Edit Configuration');
            setUpdatedpropertyNames(props.propertyNamesEdit);
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType, props.propertyNames, props.propertyNamesEdit]);

    useEffect(() => {
        console.log(props);
        if (props.shownetworkModalType === 'Add Network Config') {
            setNetworkmodalTitle('Add Network Configuration');
        }
        if (props.shownetworkModalType === 'Update Network Config') {
            setNetworkmodalTitle('Update Network Configuration');
        }

    }, [props.shownetworkModal, props.shownetworkModalType])

    useEffect(() => {
        if (props.edgePCsConfiguration) {
            setEdgePCsConfiguration(props.edgePCsConfiguration);
            setIndicatorOn(false);
            props.edgePCsConfigurationErr = null;
            setEdgePCsConfigurationErr(null);
        }
    }, [props.edgePCsConfiguration]);

    useEffect(() => {
        if (props && props.propertyNames) {
            setUpdatedpropertyNames(props.propertyNames);
        } else {
            setUpdatedpropertyNames(props.propertyNamesEdit);
        }
    }, [props.propertyNames, props.propertyNamesEdit])

    useEffect(() => {
        setNetworkConfiguration(props.networkConfiguration);
        if (props.networkConfiguration === 'Resource not found.') {
            setNetworkConfiguration([
                { "network_configuration_id": "", "dns_address": "", "gateway_address": "", "netmask_address": "", "customer_id": "", "dc_id": "" }
            ])
        }
    }, [selectedLocationId, props.networkConfiguration])

    useEffect(() => {
        setEdgePCError(props.edgePCserror);
        if (props.edgePCserror === 'Resource not found.') {
            setEdgePCList([])
            setIndicatorOn(false);
            setLoading(false)
        }
    }, [selectedLocationId, props.edgePCserror])

    useEffect(() => {
        if (props.edgePCsConfigurationError) {
            setEdgePCsConfigurationErr(props.edgePCsConfigurationError)
            setIndicatorOn(false);
            setLoading(false);
        }
    }, [props.edgePCsConfigurationError])

    useEffect(() => {
        if (edgePCsConfigurationErr || edgePCsConfigurationErr === 'Resource not found.') {
            setIndicatorOn(false);
            setLoading(false);
        }
    }, [edgePCsConfigurationErr])

    const handleAddConfigurationTapped = () => {
        props.showaddConfigurationModal("Add", true);
        setShowModal(true);
        setUpdatedpropertyNames(props.propertyNames);
    }
    const handleEditConfigurationTapped = () => {
        props.showaddConfigurationModal("Edit", true);
        setShowModal(true);
        setUpdatedpropertyNames(props.propertyNamesEdit);
    }
    const handleCustomerChange = (event) => {
        setApplicationName();
        setSelectedCustomer(event);
        setEdgePCList();
        setEdgePCError();
        setEdgePCsConfigurationErr(null);
        setSelectedCustomerId(event.customer_id);
        if (event.dcs && event.dcs[0].dc_id) {
            setLocationsList(event.dcs);
            setSelectedLocationId(event.dcs.dc_id);
        } else {
            setLocationsList();
            setSelectedLocationId();
        }
    }
    const handleLocationChange = (event) => {
        setEdgePCsConfiguration();
        setEdgePCsConfigurationErr(null);
        setApplicationName();
        setDayOfTheWeek();
        setStartTime();
        setEndTime();
        setSelectedLocation(event);
        setSelectedLocationId(event.dc_id);
        props.fetchEdgePC(event.dc_id);
        props.fetchCodeDeploy(event.dc_id);
        props.fetchnetworkConfiguration(event.dc_id);
        if (!selectedEdgePcId) {
            setEdgePCsConfigurationErr(null);
        } else {
            setEdgePCsConfigurationErr(props.edgePCsConfigurationError);
        }
    }
    const handleEdgePcChange = (event) => {
        setIndicatorOn(true);
        setEdgePCsConfiguration();
        setSelectedEdgePC(event);
        setApplicationName(event.application_name);
        setApplicationId(event.application_type_id);
        setSelectedAppId(event.application_type_id);
        setSelectedEdgePcId(event.edge_pc_id);
        props.fetchEdgePcConfiguration(event.edge_pc_id);
        props.fetchPropertyNames(event.application_type_id, event.edge_pc_id);
        props.fetchPropertyNamesEdit(event.application_type_id);
        checkIndicator();
    }

    const checkIndicator = () => {
        if (props.edgePCsConfigurationError || props.edgePCError) {
            setIndicatorOn(false);
        }
    }
    const callUpdatedConfigurationList = () => {
        props.fetchEdgePcConfiguration(selectedEdgePcId);
        setEdgePCsConfiguration(props.edgePCsConfiguration);
        props.fetchPropertyNames(applicationId, selectedEdgePcId);
        setLoading(false);
    }

    const handleAddNetworkConfig = () => {
        props.showaddNetworkConfigurationModal("Add Network Config", true);
        setShowModal(true);
    }
    const handleUpdate = () => {
        props.showaddNetworkConfigurationModal("Update Network Config", true);
        setShowModal(true);
    }
    const callUpdatedNetworkConfigurationList = () => {
        props.fetchnetworkConfiguration(selectedLocationId);
        setNetworkConfiguration(props.networkConfiguration);
        setLoading(false);
    }

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    const handleCopyProperties = () => {
        props.showCopyConfigurationModal("Copy", true);
        setShowCopyConfigModal(true);
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
    return (
        <div style={{ width: '100%' }}>
            {indicatorOn ?
                <div>
                    <Backdrop
                        sx={{ color: '#000', backgroundColor: 'rgb(255,255,255,0.7)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={indicatorOn}
                    >
                        <p>Please wait, while data is loading...</p> <CircularProgress color="inherit" />
                    </Backdrop>
                </div> : ''
            }
            <div>
                <h3 style={{ textAlign: 'left' }}>SV/LV Configuration</h3>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            {customersList && <FormControl sx={{ float: 'left', width: 250 }}>
                                <InputLabel id="customer-label">Customer</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={selectedCustomer || ''}
                                    onChange={(event) => handleCustomerChange(event.target.value)}
                                    label="Customer"
                                    sx={{ textAlign: 'left' }}>
                                    {customersList.map((item) => (
                                        <MenuItem key={item.customer_id} value={item}>
                                            {item.customer_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Grid>
                        <Grid item xs={3}>
                            {locationsList && <FormControl sx={{ float: 'left', width: 250 }}>
                                <InputLabel id="dc-label">DC</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    value={selectedLocation || ''}
                                    onChange={(event) => handleLocationChange(event.target.value)}
                                    label="DC"
                                    sx={{ textAlign: 'left' }}>
                                    {locationsList.map((item) => (
                                        <MenuItem key={item.dc_id} value={item}>
                                            {item.dc_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div style={{ padding: '10px', paddingTop: '40px' }}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="PC Configuration" />
                            <Tab label="Pallet Schema" />
                            <Tab label="LV Dock Door Configuration" />
                            <Tab label="Network Configuration" />
                            <Tab label="Code Deployment Window" />
                        </Tabs>
                    </Box>
                    <TabPanel value={selectedTab} index={0}>
                        {edgePCList && <FormControl sx={{ float: 'left', width: 250 }}>
                            <InputLabel id="dc-label">Edge PC</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                value={selectedEdgePC || ''}
                                onChange={(event) => handleEdgePcChange(event.target.value)}
                                label="Edge pc"
                                sx={{ textAlign: 'left' }}>
                                {edgePCList.map((item) => (
                                    <MenuItem key={item.edge_pc_id} value={item}>
                                        {item.pc_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>}
                        {user.canWrite && <Toolbar sx={{ justifyContent: "flex-end" }}>
                            <Button variant="outlined" onClick={handleAddConfigurationTapped} >Add Configuration</Button>
                        </Toolbar>}
                        {applicationName && <p>Application Name:  <strong>{applicationName}</strong></p>}

                        {edgePCsConfigurationErr !== null ? <Alert severity="error" sx={{ width: '50%' }}>{props.edgePCsConfigurationError}</Alert> : ''}
                        {edgePCsConfigurationErr !== null ? <Button sx={{ marginTop: '20px' }} variant="contained" onClick={handleCopyProperties}>Copy Properties</Button> : ''}
                        {selectedLocationId && selectedEdgePcId && edgePCsConfiguration ? <VORConfigurationGridPanel edgePCsConfiguration={edgePCsConfiguration} disableRowSelectionOnClick={true}></VORConfigurationGridPanel> : ""}
                        {selectedEdgePcId && <VORConfigurationAddEditModal showModal={showModal} modalTitle={modalTitle} editModal={handleEditConfigurationTapped} customerId={selectedCustomerId} dcId={selectedLocationId} edgePcId={selectedEdgePcId} setUpdatedpropertyNames={setUpdatedpropertyNames} updatedpropertyNames={updatedpropertyNames} callUpdatedConfigurationList={callUpdatedConfigurationList} loading={loading} setLoading={setLoading}></VORConfigurationAddEditModal>}
                        {showCopyConfigModal && <VORConfigurationCopyPropertiesModal showModal={showCopyConfigModal} customersList={customersList} dcId={selectedLocationId} customerId={selectedCustomerId} selectedEdgePcId={selectedEdgePcId} selectedAppId={selectedAppId} edgePCList={edgePCList} callUpdatedConfigurationList={callUpdatedConfigurationList} loading={loading} setLoading={setLoading}></VORConfigurationCopyPropertiesModal>}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        {selectedLocationId && <VORConfigurationPalletGridPanel customerId={selectedCustomerId} dcId={selectedLocationId}></VORConfigurationPalletGridPanel>}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        {selectedLocationId && <VORConfigurationLVDoorGridPanel customerId={selectedCustomerId} dcId={selectedLocationId}></VORConfigurationLVDoorGridPanel>}
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        {selectedLocationId && <VORNetworkConfigurationAddEditModal showModal={showModal}  networkmodalTitle={networkmodalTitle} networkConfiguration={networkConfiguration} customerId={selectedCustomerId} dcId={selectedLocationId} callUpdatedNetworkConfigurationList={callUpdatedNetworkConfigurationList} loading={loading} setLoading={setLoading}></VORNetworkConfigurationAddEditModal>}
                        <Box>
                            {selectedLocation ?
                                <Grid container spacing={0}>
                                    <div className='button-container'>
                                        {networkConfiguration && (networkConfiguration[0].dns_address !== '' || networkConfiguration[0].gateway_address !== '' || networkConfiguration[0].netmask_address !== '') ? <Button variant="contained" onClick={handleUpdate}>Edit</Button> : <Button variant="contained" onClick={handleAddNetworkConfig}>Add Configuration</Button>}
                                    </div>

                                    <Grid container spacing={0} className="network-container">
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-title-bg'>DNS Address</Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-values-bg'>{networkConfiguration && networkConfiguration[0].dns_address !== '' ? networkConfiguration[0].dns_address : ''}</Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-title-bg'>Gateway Address</Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-values-bg'>{networkConfiguration && networkConfiguration[0].gateway_address !== '' ? networkConfiguration[0].gateway_address : ''}</Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-title-bg'>Netmask Address</Item>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Item className='network-Item color-values-bg'>{networkConfiguration && networkConfiguration[0].netmask_address !== '' ? networkConfiguration[0].netmask_address : ''}</Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={0}>
                                    <Grid item xs={12} className="network-container" sx={{ textAlign: 'center' }}>
                                        <Item className=''>Please Select DC to see the network configuration</Item>
                                    </Grid>
                                </Grid>
                            }


                        </Box>
                    </TabPanel>
                    <TabPanel value={selectedTab} index={4}>
                        {selectedLocationId && <VORConfigurationCodeDeployGridPanel customerId={selectedCustomerId} dcId={selectedLocationId}></VORConfigurationCodeDeployGridPanel>}
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        customers: state.vorConfiguration.customers,
        edgePCs: state.vorConfiguration.edgePCs,
        edgePCserror: state.vorConfiguration.edgePCserror ? state.vorConfiguration.edgePCserror : null,
        networkConfiguration: state.vorConfiguration.networkConfiguration,
        edgePCsConfiguration: state.vorConfiguration.edgePCsConfiguration,
        edgePCsConfigurationError: state.vorConfiguration.edgePCsConfigurationError ? state.vorConfiguration.edgePCsConfigurationError : null,
        showModalType: state.vorConfiguration.showModalType,
        shownetworkModalType: state.vorConfiguration.shownetworkModalType,
        selectedData: state.vorConfiguration.selectedData,
        showModal: state.vorConfiguration.showConfigurationModal,
        propertyNames: state.vorConfiguration.propertyNames,
        propertyNamesEdit: state.vorConfiguration.propertyNamesEdit,
        user: state.application.user
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        fetchCustomersWithDC: () => dispatch(Actions.LOAD_CUSTOMERS_WITH_DC()),
        fetchEdgePC: (distributionId) => dispatch(Actions.LOAD_EDGEPCS(distributionId)),
        fetchEdgePcConfiguration: (edgePcId) => dispatch(Actions.LOAD_EDGEPCS_CONFIGURATION(edgePcId)),
        fetchnetworkConfiguration: (dcId) => dispatch(Actions.LOAD_NETWORK_CONFIGURATION(dcId)),
        showaddConfigurationModal: (type, show) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        showCopyConfigurationModal: (type, show) => dispatch(Actions.COPY_CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        showaddNetworkConfigurationModal: (type, show) => dispatch(Actions.NETWORK_CONFIGURATION_ADD_EDIT_MODAL(type, show)),
        fetchPropertyNames: (applicationId, edgePcId) => dispatch(Actions.LOAD_PROPERTY_NAMES(applicationId, edgePcId)),
        fetchPropertyNamesEdit: (applicationId) => dispatch(Actions.LOAD_PROPERTY_NAMES_EDIT(applicationId)),
        fetchCodeDeploy: (dcId) => dispatch(Actions.LOAD_CODE_DEPLOYMENT(dcId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfiguration);