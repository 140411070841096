import React from "react";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";

export default function CustomPaper({
  margin = 0,
  height = 0,
  padding = 0,
  isPalletReview = false,
  children = null,
  onClick = null,
  ...prop
}) {
  const theme = useTheme();
  // console.log("CustomPaper height", height);
  // console.log("CustomPaper prop", prop);
  return (
    <Paper
      sx={{
        p: padding,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: height,
        margin: margin,
        ...prop,
        "&:hover": {
          cursor: prop.hover ? "pointer" : null,
          //backgroundColor: prop.hover ? "#DE2B40" : null,
        },
        [theme.breakpoints.down("xl")]: {
          height: isPalletReview ? "300px" : height,
        },
        // [theme.breakpoints.down("md")]: {
        //   boxShadow: "none",
        //   backgroundColor: "#FBFBFB",
        // },
        boxShadow: theme?.shadow?.elevation_1,
      }}
      onClick={onClick}
    >
      {children}
    </Paper>
  );
}
