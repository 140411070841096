import * as ActionTypes from "../actions/actiontypes"

export const initialState = {
    error: null,
    errorCustomers: null,
    showModalType: null,
    selectedData: null,
    customersVorList: null,
    brands: null,
    packageType: null,
    skusbydesc: null,
    skubyrefrence: null,
    edgePCsConfiguration: null,
    edgePCsConfigurationError: null,
    propertyNames: null,
    updateweightstatus: null,
    updateweighterror: null,
    mergeweightstatus: null,
    mergeweighterror: null
}

const SkuUpdateReducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case ActionTypes.LOAD_SKUSBY_DESC_WITH_SUCCESS:
            newState.skusbydesc = action.payload.data.data.Data;
            break;
        case ActionTypes.LOAD_SKUSBY_DESC_WITH_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_SKUSBY_REFRENCE_WITH_SUCCESS:
            newState.skubyrefrence = action.payload.data.data.Data;
            break;
        case ActionTypes.LOAD_SKUSBY_REFRENCE_WITH_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_BRANDS_SUCCESS:
            newState.brands = action.payload.data.data.Data;
            break;
        case ActionTypes.LOAD_BRANDS_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.LOAD_PACKAGE_TYPE_SUCCESS:
            newState.packageType = action.payload.data.data.Data;
            break;
        case ActionTypes.LOAD_PACKAGE_TYPE_ERROR:
            newState.error = errorDescription(action.payload.error);
            break;
        case ActionTypes.UPDATE_WEIGHT_SUCCESS:
            newState.updateweightstatus = action.payload.data.data;
            newState.error = null;
            break;
        case ActionTypes.UPDATE_WEIGHT_ERROR:
            newState.error = errorDescription(action.error);
            break;
        case ActionTypes.MERGE_WEIGHT_SUCCESS:
            newState.mergeweightstatus = action.payload.data;
            newState.mergeweighterror = null;
            break;
        case ActionTypes.MERGE_WEIGHT_ERROR:
            newState.mergeweighterror = errorDescription(action.error);
            break;
        case ActionTypes.LOAD_VOR_CUSTOMERS_SUCCESS:
            newState.customersVorList = action.payload.data;
            newState.error = null;
            break;
        case ActionTypes.LOAD_VOR_CUSTOMERS_ERROR:
            newState.error = errorDescription(action.error);
            break;
        default:
            break;
    }

    return newState;
}

const errorDescription = (error) => {
    if (error != undefined && error.response != null) {
        if (error.response.data != null, error.response.data != undefined) {
            if (error.response.data.Error) {
                return error.response.data.Error;
            } else {
                return error.response.data
            }
        }
    } else {
        return error
    }
}
export default SkuUpdateReducer;