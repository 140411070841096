import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Button,
    Grid,
    TextField,
    Autocomplete,
    FormGroup,
    FormControlLabel,
    FormControl,
    Select,
    Checkbox,
    InputLabel,
    MenuItem, LinearProgress
} from '@mui/material'
import * as Actions from '../actions';
const AddEditUserModal = (props) => {
    const [roleId, setRoleId] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState(null);
    const [activeStatus, setActiveStatus] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [locations, setLocations] = useState([]);
    const [applications, setApplications] = useState([]);
    const [showCustomer, setShowCustomer] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [customer, setCustomer] = useState(null);
    const [showApplication, setShowApplication] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleClose = () => {
        props.handleClose();
        setRoleId(null);
    }

    const handleRoleChange = (event) => {
        let roleId = event.target.value;
        setRoleId(roleId);
        showFieldsBasedOnRole(roleId);
        setShowError(false);
    }
    const handleLocationChange = (event) => {
        setLocations(event.target.value)
        setShowError(false);
    }
    const handleApplicationChange = (event) => {
        setApplications(event.target.value)
        setShowError(false);
    }

    const handleCustomerChange = (event, customer) => {
        setCustomer(customer);
        setShowError(false);
    }

    const handleSubmit = () => {
        setShowError(true);
        if (firstName.length <= 0 || lastName.length <= 0 || userName.length <= 0 || roleId.length <= 0 || email.length <= 0) return;
        let role = props.roles.find((role) => role.central_role_id === roleId);
        if (role && ["CustomerUser", "CustomerAdmin", "CustomerReadOnly"].includes(role.role_name)) {
            if (!customer || applications.length <= 0) {
                return;
            }
        } else if (role && ["LocationUser", "LocationAdmin", "LocationReadOnly"].includes(role.role_name)) {
            if (!customer || applications.length <= 0 || locations.length <= 0) {
                return;
            }
        }
        setShowError(false);
        let user = {
            "first_name": firstName,
            "middle_name": middleName,
            "last_name": lastName,
            "username": userName,
            "central_role_id": roleId,
            "central_customer_id": customer ? customer.central_customer_id : undefined,
            "email": email,
            "phone_number": phoneNumber,
            "active": activeStatus ? 1 : 0,
            "locations": locations,
            "applications": applications
        }
        if (props.selectedUser && props.selectedUser.central_user_id) {
            props.setLoading(true);
            user = { ...user, ...props.selectedUser.central_customer_id };
            props.editUer(user, props.selectedUser.central_user_id);
        } else {
            props.setLoading(true);
            props.addUser(user);
        }
        //props.handleClose(true);

    }
    const handleDelete = () => {
        // props.deleteTandem(props.selectedTandem.tandem_details_id);
        props.handleClose(true);

    }
    const showFieldsBasedOnRole = (roleId) => {
        setShowCustomer(false);
        setShowLocation(false);
        setShowApplication(false);
        let role = props.roles.find((role) => role.central_role_id === roleId);
        if (role && ["CustomerUser", "CustomerAdmin", "CustomerReadOnly"].includes(role.role_name)) {
            setShowCustomer(true);
            if (!customer) {
                if (props.selectedCustomer && props.selectedCustomer.central_customer_id) {
                    setCustomer(props.selectedCustomer);
                }
            }
            setShowApplication(true);
            //setApplications([]);
            //setLocations([]);
        } else if (role && ["LocationUser", "LocationAdmin", "LocationReadOnly"].includes(role.role_name)) {
            if (!customer) {
                if (props.selectedCustomer && props.selectedCustomer.central_customer_id) {
                    setCustomer(props.selectedCustomer);
                }
            }
            setShowCustomer(true);
            setShowLocation(true);
            setShowApplication(true);
        } else {
            setApplications([]);
            setCustomer(null);
            setShowCustomer(false);
            setShowLocation(false);
        }
    }
    useEffect(() => {
        if (!roleId) {
            setShowCustomer(false);
            setShowLocation(false);
            setShowApplication(false);
            setCustomer(null);
            setLocations(null);
            setApplications([]);
        }
    }, [roleId])
    useEffect(() => {
        if (props.selectedUser && props.selectedUser.central_user_id) { //Edit PC Edge Scenario
            setFirstName(props.selectedUser.first_name);
            setLastName(props.selectedUser.last_name);
            setMiddleName(props.selectedUser.middle_name);
            setPhoneNumber(props.selectedUser.phone_number);
            setUserName(props.selectedUser.username);
            setEmail(props.selectedUser.email)
            setRoleId(props.selectedUser.central_role_id);
            showFieldsBasedOnRole(props.selectedUser.central_role_id);
            setApplications(props.selectedUser.applications);
            setLocations(props.selectedUser.locations);
            setActiveStatus(props.selectedUser.active === 1 ? true : false);
            let customer = props.customers.find((customer) => customer.central_customer_id === props.selectedUser.central_customer_id);
            setCustomer(customer)


        } else {
            setFirstName("");
            setLastName("");
            setMiddleName("");
            setUserName("");
            setPhoneNumber("");
            setEmail("")
            setRoleId("");
            setApplications([]);
            setLocations([]);
            setActiveStatus(true);
            setCustomer(props.selectedCustomer);
            showFieldsBasedOnRole(-1);
        }
        setShowError(false);
    }, [props.selectedUser]);


    return (
        <>
            <Dialog open={props.showModal} onClose={handleClose}
                PaperProps={{ sx: { width: '700px', minHeight: "500px" } }}>
                {props.loading ? <LinearProgress /> : ""}
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={2} style={{ marginBottom: '32px' }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="username"
                                    label="UserName*"
                                    value={userName || ''}
                                    onChange={(event) => {
                                        setShowError(false);
                                        setUserName(event.target.value)
                                    }}
                                    helperText={"Hint: Enter user name here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="email"
                                    label="Email*"
                                    value={email || ''}
                                    onChange={(event) => {
                                        setShowError(false);
                                        setEmail(event.target.value)
                                    }}
                                    helperText={"Hint: Enter email id here"}
                                    style={{ backgroundColor: '#fff', borderColor: showError && 'red' }}
                                    inputProps={{ borderColor: showError && 'red' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="first-name"
                                    label="First Name*"
                                    value={firstName || ''}
                                    onChange={(event) => {
                                        setFirstName(event.target.value)
                                        setShowError(false)
                                    }}
                                    helperText={"Hint: Enter first name here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="middle-name"
                                    label="Middle Name"
                                    value={middleName || ''}
                                    onChange={(event) => {
                                        setMiddleName(event.target.value)
                                    }
                                    }
                                    helperText={"Hint: Enter middle name here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="last-name"
                                    label="Last Name*"
                                    value={lastName || ''}
                                    onChange={(event) => {
                                        setLastName(event.target.value)
                                        setShowError(false);
                                    }
                                    }
                                    helperText={"Hint: Enter last name here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <TextField
                                    id="phone-number"
                                    label="Phone Number"
                                    value={phoneNumber || ''}
                                    onChange={(event) => {
                                        setPhoneNumber(event.target.value)
                                    }
                                    }
                                    helperText={"Hint: Enter phone number here"}
                                    style={{ backgroundColor: '#fff' }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={activeStatus} onChange={(e) => setActiveStatus(e.target.checked)} />} label="Active Status" />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="role-label">Role*</InputLabel>
                                <Select
                                    labelId="role-label"
                                    id="role"
                                    value={roleId}
                                    label="Role*"
                                    onChange={handleRoleChange}
                                >
                                    {props.roles.map((role) => (
                                        <MenuItem
                                            key={role.central_role_id}
                                            value={role.central_role_id}
                                        >
                                            {role.role_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        {showCustomer && <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="customers"
                                    options={props.customers ? props.customers : []}
                                    getOptionLabel={(option) => option.customer_name}
                                    renderInput={(params) => <TextField {...params} label="Customer*" />}
                                    onChange={handleCustomerChange}
                                    value={customer}
                                // disabled={showCustomer}
                                />
                            </FormControl>

                        </Grid>
                        }
                        {showLocation && <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="locations-label">Locations</InputLabel>
                                <Select
                                    labelId="locations-label"
                                    id="locations"
                                    multiple
                                    value={locations ? locations : []}
                                    label="Locations*"
                                    onChange={handleLocationChange}
                                >
                                    {props.locations.map((location) => (
                                        <MenuItem
                                            key={location.central_location_id}
                                            value={location.central_location_id}
                                        >
                                            {location.location_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </Grid>
                        }
                        {showApplication && <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="applications-label">Applications*</InputLabel>
                                <Select
                                    labelId="applications-label"
                                    id="applications"
                                    multiple
                                    value={applications ? applications : []}
                                    label="Applications*"
                                    onChange={handleApplicationChange}
                                >
                                    {props.applications.map((application) => (
                                        <MenuItem
                                            key={application.central_application_id}
                                            value={application.central_application_id}
                                        >
                                            {application.application_long_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        }
                    </Grid>
                    <Grid>
                        {showError && <label style={{ color: 'red' }}>Plese enter mandatory fields</label>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button autoFocus onClick={handleSubmit} variant="contained"
                     disabled={props.loading ? true : false}
                    disableElevatio>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        users: state.users,
        selectedUser: state.selectedUser,
        locations: state.locations,
        applications: state.applications,
        roles: state.roles,
        selectedCustomer: state.selectedCustomer,
        customers: state.customers
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        addUser: (user) => dispatch(Actions.ADD_USER(user)),
        editUer: (userInfo, userId) => dispatch(Actions.EDIT_USER(userInfo, userId)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddEditUserModal);
