import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

const LocationManagementGridPanel = ({ user, ...props }) => {
    const [locationsList, setLocationsList] = useState(props.locationsList);

    const handleEditLocationTapped = (params) => {
        props.showAddEditLocationModal('Edit', true, params);
    }
    useEffect(() => {
        let filteredLocations = props.locationsList.filter((item) => {
            if (item.central_customer_id == props.centralCustomerId) {
                return item;
            }
        })
        setLocationsList(filteredLocations);

    }, [props.locationsList, props.centralCustomerId]);

    const editLocationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={() => handleEditLocationTapped(params.row)}>Edit Location</Button>
        } else {
            return null
        }
    }
    const customerStatus = (params) => {
        if (params.row.active) {
            return "Active"
        } else {
            return "Inactive"
        }
    }

    const getAddress = (params) => {
        if (params.row.address && params.row.address.full_address) {
            return params.row.address.full_address;
        } else {
            return ""
        }
    }
    const getLatitude = (params) => {
        if (params.row.address && params.row.address.point_lat) {
            return params.row.address.point_lat;
        } else {
            return ""
        }
    }
    const getLongitude = (params) => {
        if (params.row.address && params.row.address.point_long) {
            return params.row.address.point_long;
        } else {
            return ""
        }
    }
    const columns = [
        { field: 'central_location_id', headerName: 'CENTRAL LOCATION ID', width: 180 },
        { field: 'location_name', headerName: 'LOCATION NAME', width: 140 },
        { field: 'active', headerName: 'STATUS', width: 90, renderCell: customerStatus },
        { field: 'address', headerName: 'ADDRESS', width: 270, renderCell: getAddress },
        { field: 'latitude', headerName: 'LATITUDE', width: 100, renderCell: getLatitude },
        { field: 'longitude', headerName: 'LONGITUDE', width: 100, renderCell: getLongitude },
        { field: 'time_zone', headerName: 'TIME ZONE', width: 120 },
        { field: 'warehouse_id', headerName: 'WAREHOUSE ID', width: 140 },
        { field: 'email', headerName: 'EMAIL', width: 170 },
        user.canWrite && { field: 'editLocation', headerName: 'EDIT LOCATION', width: 170, renderCell: editLocationButton }
    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <div className="detail-card-header">
                        <h3 style={{ paddingTop: '10px' }}>Locations</h3>
                    </div>
                    <div className="duolift-device-detail-card-body">
                        <div style={{ height: '650px', width: '100%' }}>
                            <DataGrid
                                rows={locationsList}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.central_location_id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.customerManagement.showCustomerModal,
        showModalType: state.customerManagement.showModalType,
        user: state.application.user
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditLocationModal: (type, show, selectedData) => dispatch(Actions.LOCATION_ADD_EDIT_MODAL(type, show, selectedData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationManagementGridPanel);
