import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import * as Actions from '../../actions/actions';
import Button from '@mui/material/Button';
import { DataGrid } from '@mui/x-data-grid';
import Toolbar from "@mui/material/Toolbar";
import Alert from '@mui/material/Alert';
import VORConfigurationCodeDeployAddEditModal from '../VORConfigurationCodeDeployAddEditModal/VORConfigurationCodeDeployAddEditModal';

const VORConfigurationCodeDeployGridPanel = (props) => {
    const [codeDeployList, setCodeDeployList] = useState(props.codeDeployment);
    const [dayOfTheWeek, setDayOfTheWeek] = useState();
    const [startTime, setStartTime] = useState();
    const [endTime, setEndTime] = useState();
    const [codeDeployWindowId, setCodeDeployWindowId] = useState();
    const [showModal, setShowModal] = useState();
    const [modalTitle, setModalTitle] = useState('');
    const [dcId, setDcId] = useState(props.dcId);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        if (codeDeployWindowId !== undefined) {
            props.deleteCodeDeploy(codeDeployWindowId, callUpdatedCodeDeployList);
        }
    }, [codeDeployWindowId])

    useEffect(() => {
        setCodeDeployList(props.codeDeployment);
        if (props.codeDeployError === 'Resource not found.') {
            setCodeDeployList();
        }
    }, [props.codeDeployment, props.codeDeployError])

    useEffect(() => {
        setDcId(props.dcId);
    }, [props.dcId])

    useEffect(() => {
        props.fetchCodeDeploy(props.dcId);
    }, [props.dcId]);

    useEffect(() => {
        if (props.showModalType === 'Add') {
            setModalTitle('Add code deployment schedule');

        } else {
            setModalTitle('Edit code deployment schedule');
        }
        setShowModal(props.showModal);
    }, [props.showModal, props.showModalType]);

    const callUpdatedCodeDeployList = () => {
        props.fetchCodeDeploy(props.dcId);
        setLoading(false);
    }
    const handleAddConfigurationTapped = () => {
        props.showAddEditConfigurationModal("Add", true);
        setShowModal(true);
    }
    const handleEditConfigurationTapped = (params) => {
        props.showAddEditConfigurationModal('Edit', true, params);
    }

    const handleDeleteConfigurationTapped = (params) => {
        setCodeDeployWindowId(params.software_deployment_window_id);
    }

    const editConfigurationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={() => handleEditConfigurationTapped(params.row)}>Edit</Button>
        } else {
            return null
        }
    }

    const deleteConfigurationButton = (params) => {
        if (params.row) {
            return <Button variant="contained" onClick={() => handleDeleteConfigurationTapped(params.row)}>Delete</Button>
        } else {
            return null
        }
    }

    const getStartTimes = (params) => {
        if (params.row.start_time === 0) {
            return params.row.start_time + 12 + ' AM';
        }
        else if (params.row.start_time < 12) {
            return params.row.start_time + ' AM';
        }
        else if (params.row.start_time === 12) {
            return params.row.start_time + ' PM';
        }
        else {
            return params.row.start_time - 12 + ' PM';
        }
    }
    const getEndTimes = (params) => {
        if (params.row.end_time === null || params.row.end_time == null) {
            return ;
        }
        else if (params.row.end_time < 12) {
            return params.row.end_time + ' AM';
        }
        else if (params.row.end_time === 12) {
            return params.row.end_time + ' PM';
        }
        else if (params.row.end_time > 12) {
            return params.row.end_time - 12 + ' PM';
        }
        else {
           return '';
        }
    }

    const columns = [
        { field: 'day_of_the_week', headerName: 'DAY OF THE WEEK', width: 300 },
        {
            field: 'start_time',
            headerName: 'START TIME',
            width: 200,
            valueGetter: (params) => getStartTimes(params)
        },
        {
            field: 'end_time',
            headerName: 'END TIME',
            width: 200,
            valueGetter: (params) => getEndTimes(params)
        },
        { field: 'editConfiguration', headerName: 'EDIT', width: 110, renderCell: editConfigurationButton },
        { field: 'deleteConfiguration', headerName: 'DELETE', width: 110, renderCell: deleteConfigurationButton }

    ];

    return (
        <>
            <div className="dashboardData-panel">
                <div className="duolift-device-detail-card" style={{ width: "100%" }}>
                    <Toolbar sx={{ justifyContent: "flex-end" }}>
                        <Button variant="outlined" onClick={handleAddConfigurationTapped} >Add Deployment Schedule</Button>
                    </Toolbar>

                    {codeDeployList ? <div className="duolift-device-detail-card-body">
                        <div style={{ height: '500px', width: '100%' }}>
                            <DataGrid
                                rows={codeDeployList}
                                columns={columns}
                                rowsPerPageOptions={[10]}
                                getRowId={(row) => row.software_deployment_window_id}
                            />
                        </div>
                    </div> : <Alert severity="error" sx={{ width: '50%' }}>{props.codeDeployError || ''}</Alert>}

                    <VORConfigurationCodeDeployAddEditModal showModal={showModal} modalTitle={modalTitle}
                        editModal={handleEditConfigurationTapped} customerId={props.customerId} dayOfTheWeek={props.dayOfTheWeek}
                        startTime={props.startTime} endTime={props.endTime} loading={loading} setLoading={setLoading}
                        dcId={props.dcId} callUpdatedCodeDeployList={callUpdatedCodeDeployList}></VORConfigurationCodeDeployAddEditModal>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.vorConfiguration.showCustomerModal,
        showModalType: state.vorConfiguration.showModalType,
        codeDeployment: state.vorConfiguration.codeDeployment,
        selectedData: state.vorConfiguration.selectedData,
        codeDeployError: state.vorConfiguration.codeDeployError
    };
}
const mapDispatchToProps = (dispatch) => {
    return {
        showAddEditConfigurationModal: (type, show, selectedData) => dispatch(Actions.CONFIGURATION_ADD_EDIT_MODAL(type, show, selectedData)),
        fetchCodeDeploy: (dcId) => dispatch(Actions.LOAD_CODE_DEPLOYMENT(dcId)),
        deleteCodeDeploy: (codeDeployWindowId, callUpdatedCodeDeployList) => dispatch(Actions.DELETE_CODE_DEPLOYMENT(codeDeployWindowId, callUpdatedCodeDeployList))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VORConfigurationCodeDeployGridPanel);